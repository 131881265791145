import React from 'react';
import CampoRadioButton from '../../../componentes/CampoRadioButton/index';

interface ICompletarEnderecoPorSituacaoDeMoradiaPropriaProps {
  completarEnderecoPorSituacaoDeMoradiaPropria: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CompletarEnderecoPorSituacaoDeMoradiaPropria = ({ completarEnderecoPorSituacaoDeMoradiaPropria, onChange }:ICompletarEnderecoPorSituacaoDeMoradiaPropriaProps) => (
  
  <div className="grade__coluna">
    <fieldset className="formulario__grupo-de-inputs">
      <legend className='formulario__label'>
        Está vivendo neste imóvel hoje?
        <p className="formulario__descricao">
          Se responder "Sim", os campos de endereço serão automaticamente preenchidos com as informações de seu endereço de residência.
        </p>
      </legend>
      <CampoRadioButton
        mesmaLinha
        name="completarEnderecoPorSituacaoDeMoradiaPropria"
        value="true"
        label="Sim"
        onChange={onChange}
        checked={completarEnderecoPorSituacaoDeMoradiaPropria.toString()} />
      <CampoRadioButton
        mesmaLinha
        name="completarEnderecoPorSituacaoDeMoradiaPropria"
        value="false"
        label="Não"
        onChange={onChange}
        checked={completarEnderecoPorSituacaoDeMoradiaPropria.toString()} />
      <hr />
    </fieldset>
  </div>
);
export default CompletarEnderecoPorSituacaoDeMoradiaPropria;