import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';

export default (state = initialState.ehAcessoAdministrativo, action: IActionType<boolean>) => {
  switch (action.type) {
  case actionTypes.SALVAR_EH_ACESSO_ADMINISTRATIVO:
    return action.state;
  default:
    return state;
  }
};