import React, { useState } from 'react';
import CampoRadioButton from '../../../CampoRadioButton';
import MensagemDeErro from '../../../MensagemDeErro';
//@ts-ignore
import Mensagem from '@bit/digix.digixui.mensagem';
//@ts-ignore
import Dialogo from '@bit/digix.digixui.dialogo';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';

interface ICampoMulherChefeDeFamiliaProps {
    mulherChefeDeFamilia?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    erros?: IPretendenteValidacaoErroCampos,
    disabled: boolean
}

const CampoMulherChefeDeFamilia = ({ mulherChefeDeFamilia, onChange, erros, disabled = false }: ICampoMulherChefeDeFamiliaProps) => {

  const [exibirDialogo, alterarExibicaoDoDialogo] = useState(false);

  const abrirModal = (event: React.ChangeEvent<HTMLInputElement>) => {
    alterarExibicaoDoDialogo(true);
    onChange(event);
  };

  return (
    <>
      <div className="grade__linha">
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria">
            <strong>Mulher chefe de família</strong>
          </h2>
        </div>
      </div>
      <div className="grade__coluna">
        <fieldset className="formulario__grupo-de-inputs">
          <legend className="formulario__label">
            É considerada a chefe da família?
            <p className="formulario__descricao">
              Mulher responsável pela unidade familiar é aquela que se reconhece e é reconhecida pela família como a pessoa de referência desta,
              podendo ou não, ser a provedora econômica. Este reconhecimento deve também estar registrado no CADÚNICO.
            </p>
          </legend>
          {erros?.mulherChefeDeFamilia &&
          <MensagemDeErro />}
          <CampoRadioButton
            mesmaLinha
            name="mulherChefeDeFamilia"
            value="true"
            label="Sim"
            checked={mulherChefeDeFamilia?.toString() ?? ''}
            onChange={abrirModal}
            disabled={disabled} />
          <CampoRadioButton
            mesmaLinha
            name="mulherChefeDeFamilia"
            value="false"
            label="Não"
            checked={mulherChefeDeFamilia?.toString() ?? ''}
            onChange={onChange}
            disabled={disabled} />
        </fieldset>
        {mulherChefeDeFamilia &&
        <Mensagem
          tipo='atencao'
          titulo="Atenção!"
          icone='far fa-exclamation-triangle'>
          <strong>É necessário ter cônjuge e/ou dependente(s) cadastrados</strong>, caso contrário esta informação será <strong>desconsiderada</strong>.
          Estas informações também devem estar registradas no CADÚNICO.
        </Mensagem>
        }
      </div>

      <Dialogo
        tamanho='medio'
        deveSerExibido={exibirDialogo}
        fecharDialogo={() => alterarExibicaoDoDialogo(false)}
        titulo='Atenção'
        corpo={() => {
          return (<p> <strong>É necessário ter cônjuge e/ou dependente(s) cadastrados</strong>, caso contrário esta informação será <strong>desconsiderada</strong>.
            Estas informações também devem estar registradas no CADÚNICO.</p>);
        }}
        rodape={() => {
          return (
            <>
              <button className="botao botao_cor-sucesso" type="button" onClick={() => alterarExibicaoDoDialogo(false)}>Ok</button>
            </>
          );
        }} />
    </>
  );
};

export default CampoMulherChefeDeFamilia;
