const QuantiaDeCaracteresObrigatorios = 11;
const PesosDoAlgoritmoDeVerificacao  = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2, 1];

const validarNis = (caracteresParaValidar) => {
  if (isNullOrWhiteSpace(caracteresParaValidar))
    return false;

  let nisSemMascara = caracteresParaValidar.replace(/[^\d]+/g, '');

  if (!temTamanhoPermitido(nisSemMascara) || todosCaracteresSaoIguais(nisSemMascara))
    return false;

  let numerosDoNis = converterTextoComNumerosParaLista(nisSemMascara);
  let somaDosProdutosDosNumerosDoNisPelosPesos = somarProdutosDosNumerosPelosPesos(numerosDoNis);
  let digitoVerificadorCalculado = calcularDigitoVerificador(somaDosProdutosDosNumerosDoNisPelosPesos);
  // eslint-disable-next-line eqeqeq
  let digitoVerificadorEhValido = digitoVerificadorCalculado == numerosDoNis.slice(-1);

  return digitoVerificadorEhValido ? true : false;
};

const isNullOrWhiteSpace = valor => {
  if (!valor) return true;

  return valor.replace(/\s/g, '').length < 1;
};

const temTamanhoPermitido = caracteresParaValidar => {
  return caracteresParaValidar.length == QuantiaDeCaracteresObrigatorios;
};

const todosCaracteresSaoIguais = palavraParaValidar => {
  return palavraParaValidar.split('').every(caractere => caractere == palavraParaValidar[0]);
};

const converterTextoComNumerosParaLista = textoParaConverter => {
  return textoParaConverter.split('').map(Number);
};

const somarProdutosDosNumerosPelosPesos = numerosDoNis => {
  let somaDosProdutos =  0;

  if (!numerosDoNis) return somaDosProdutos;

  let produtos = numerosDoNis.map((numero, indice) => calcularProdutoDosPesosVerificadores(numero, indice));
  produtos.splice(-1, 1);
  somaDosProdutos = produtos.reduce((a, b) => a + b, 0);

  return somaDosProdutos;
};

const calcularDigitoVerificador = somaDosProdutosDosNumerosDoNisPelosPesos => {
  var digitoVerificadorCalculado = 11 - somaDosProdutosDosNumerosDoNisPelosPesos % 11;
  
  if (digitoVerificadorCalculado == 10 || digitoVerificadorCalculado == 11)
    return 0;
  
  return digitoVerificadorCalculado;
};

const calcularProdutoDosPesosVerificadores = (numero, indiceDoNumero) => {
  var maiorIndicePossivel = QuantiaDeCaracteresObrigatorios - 1;

  if (indiceDoNumero == maiorIndicePossivel) return;
  
  return numero * PesosDoAlgoritmoDeVerificacao[indiceDoNumero];
};

export default { validarNis, isNullOrWhiteSpace, calcularProdutoDosPesosVerificadores, calcularDigitoVerificador,
  somarProdutosDosNumerosPelosPesos, converterTextoComNumerosParaLista, todosCaracteresSaoIguais, temTamanhoPermitido};