import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import '../../estilos/estilos.scss';
import { ReactComponent as LogosDoSistema } from '../../componentes/Logos/logo-governo-e-agehab.svg';
import { logoff } from '../../servicos/autenticacao';
import { verificarSeAceitouOsTermosDeUso, redirecionarParaAceitacaoDeTermosDeUso } from '../../servicos/termoDeUso';
import { endAjaxCall, beginAjaxCall } from '../../actions/requisicoesAjax.actions';
import { connect } from 'react-redux';

class AvisoDocumentos extends Component {
  componentDidMount() {
    if(this.props.location.state) this.verificarAceitacaoDosTermosDeUso(this.props.location.state);
  }

  verificarAceitacaoDosTermosDeUso = async ({cpfDoTitular, dadosDoCadastro}) => {
    if (!cpfDoTitular || !dadosDoCadastro){
      logoff();
      this.props.endAjaxCall();
    }

    this.props.beginAjaxCall();
    const aceitouOsTermosDeUso = await this.props.verificarSeAceitouOsTermosDeUso(cpfDoTitular);
    if (!aceitouOsTermosDeUso) {
      this.props.redirecionarParaAceitacaoDeTermosDeUso(dadosDoCadastro, '/aviso-documentos');
      return;
    }
    this.props.endAjaxCall();
  };

  voltar = () => {
    logoff();
    this.props.history.goBack();
  };

  render() {
    return (
      <div className="aviso-de-documentos">
        <LogosDoSistema className="aviso-de-documentos__logos" />
        <div className="container u-padding-media container_com-fundo container_com-margem">
          <div className="grade grade__linha grade_sem-margem">
            <div className="grade__coluna">
              <h1 className="titulo titulo_medio texto_cor-terciaria">Antes de iniciar o cadastro, fique atento aos documentos necessários</h1>
              <hr />
              <p className="texto_pequeno u-margem-inferior-media">
                <strong className="texto_cor-alerta">Atenção!</strong> Para concluir o cadastro, é necessário que você tenha os seguintes documentos em mãos. Você pode providenciá-los com calma e voltar para fazer o seu cadastro depois, caso não os tenha ainda.
              </p>
              <h2 className="titulo texto_medio texto_cor-terciaria u-margem-inferior-pequena">Providencie os seguintes documentos:</h2>
              <ul className="aviso-de-documentos__lista-documentos">
                <li className="u-margem-inferior-pequena">RG (Registro Geral);</li>
                <li className="u-margem-inferior-pequena">CPF (Cadastro de Pessoa Física);</li>
                <li className="u-margem-inferior-pequena">Carteira de trabalho;</li>
                <li className="u-margem-inferior-pequena">Holerite, caso tenha vínculo empregatício;</li>
                <li className="u-margem-inferior-pequena">CIE (Cédula de Identidade de Estrangeiro), caso seja estrangeiro.</li>
                <li>Código de CID, encontrado no atestado médico, para casos de deficiências e/ou doenças crônicas incapacitantes permanentes.</li>
              </ul>
            </div>
          </div>
          <div className="aviso-de-documentos__rodape grade grade__linha">
            <div className="grade__coluna">
              <button onClick={() => this.voltar()} className="botao botao_medio botao_contorno botao_cor-secundaria botao_largura-total">Voltar</button>
            </div>
            <div className="grade__coluna">
              <Link to='/dados-titular' className="botao botao_medio botao_cor-secundaria botao_largura-total botao_texto-branco">Avançar</Link>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    verificarSeAceitouOsTermosDeUso: (cpfDoTitular) => verificarSeAceitouOsTermosDeUso(cpfDoTitular, dispatch),
    redirecionarParaAceitacaoDeTermosDeUso: (dadosDoCadastro, pathAdicionalDeRetorno) =>
      redirecionarParaAceitacaoDeTermosDeUso(dadosDoCadastro, dispatch, pathAdicionalDeRetorno),
    beginAjaxCall: () => dispatch(beginAjaxCall()),
    endAjaxCall: () => dispatch(endAjaxCall())
  };
};

export default withRouter(connect(null, mapDispatchToProps)(AvisoDocumentos));