import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Loading = ({ carregando }) =>
  (<div className={`carregando ${carregando ? 'carregando_ativo' : ''}`}></div>);

const mapStateToProps = ({ requisicoesAjax }) =>
  ({ carregando: requisicoesAjax > 0 });

export default connect(mapStateToProps)(Loading);

Loading.propTypes = {
  carregando:PropTypes.bool
};