import React from 'react';
//@ts-ignore
import Select from 'react-select';
import MensagemDeErro from '../MensagemDeErro';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';

interface IEventOnChange {
  target: { name: string, value?: number }
}

interface ICampoAnoMoradiaProps {
  nomeCidade: string,
  value: number,
  erros?: IPretendenteValidacaoErroCampos,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  anoDeNascimentoDoPretendente: number,
  colunas?: number,
  disabled: boolean
}

const CampoAnoMoradia = ({ nomeCidade, value, erros, onChange, anoDeNascimentoDoPretendente, colunas, disabled = false }: ICampoAnoMoradiaProps) => {

  const listaAnoMoradiaCidade = (anoDeNascimentoDoPretendente: number) => {
    const anoAtual = new Date().getFullYear();
    const anoInicio = anoDeNascimentoDoPretendente;
    let listaAno = [];

    for (let i = anoInicio; i <= anoAtual; i++) {
      listaAno.push(i);
    }

    return listaAno.map(a => { return { value: a, label: a }; });
  };

  const handleOnChange = (selected: { value: number }) => {
    const event: IEventOnChange = {
      target: {
        name: 'resideNoMunicipioDesde',
        value: !!selected ? selected.value : undefined
      }
    };
    //@ts-ignore
    onChange(event);
  };

  return (<div className={`grade__coluna ${colunas ? `grade__coluna_${colunas}` : ''}`}>
    <div className={`formulario__input-react-select ${erros?.resideNoMunicipioDesde && 'formulario__input-react-select_erro'}`}>
      <label
        className="formulario__label">
        Desde que ano mora em {nomeCidade}?
      </label>
      {erros?.resideNoMunicipioDesde && <MensagemDeErro />}
      <Select
        disabled={disabled}
        placeholder={'Selecione'}
        value={value}
        options={listaAnoMoradiaCidade(anoDeNascimentoDoPretendente)}
        onChange={handleOnChange} />
    </div>
  </div>);
};
export default CampoAnoMoradia;



