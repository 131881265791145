import React, { useEffect, useState } from 'react';
//@ts-ignore
import Select from 'react-select';
import CampoRadioButton from '../../../CampoRadioButton';
import MensagemDeErro from '../../../MensagemDeErro';
import { mapearEtniaParaSelect } from '../../../../selectors/etnias';
import { mapearGrupoEtnicoParaSelect } from '../../../../selectors/gruposEtnicos';
import IGrupoEtnico from '../../../../interfaces/IGrupoEtnico';
import IEtnia from '../../../../interfaces/IEtnia';

interface ICampoGrupoEtnicoProps {
  grupoEtnico?: IGrupoEtnico,
  etnia?: IEtnia,
  etnias: IEtnia[],
  moraNaAldeia?: boolean
  erroGrupoEtnico?: string,
  erroEtnia?: string,
  erroMoraNaAldeia?: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeEtnia: (selected?: IEtnia) => void,
  onChangeGrupoEtnico: (selected?: IGrupoEtnico) => void,
  disabled: boolean
}

const CampoGrupoEtnico = ({ grupoEtnico, etnia, etnias, moraNaAldeia, erroGrupoEtnico, erroEtnia, erroMoraNaAldeia, onChange, onChangeEtnia, onChangeGrupoEtnico, disabled }: ICampoGrupoEtnicoProps) => {

  const [gruposEtnicos, setGruposEtnicos] = useState<IGrupoEtnico[]>([]);
  const handleOnChangeEtnia = (selected: { label: string, value: string, ehIndigena: boolean }) => {
    if (selected) {
      const etnia: IEtnia = { id: parseInt(selected.value), nome: selected.label, ehIndigena: selected.ehIndigena, gruposEtnicos: [] };
      onChangeEtnia(etnia);
    } else
      onChangeEtnia(selected);
  };

  const handleOnChangeGrupoEtnico = (selected: { label: string, value: string }) => {
    if (selected) {
      const grupoEtnico: IGrupoEtnico = { id: parseInt(selected.value), nome: selected.label };
      onChangeGrupoEtnico(grupoEtnico);
    } else
      onChangeGrupoEtnico(selected);
  };

  const obterGrupos = async (etnia?: IEtnia) => {
    if (etnia) {
      const etniasFiltradas = etnias.filter(etnias => etnias.id == etnia.id);
      setGruposEtnicos(etniasFiltradas[0].gruposEtnicos);
    }
  };

  useEffect(() => {
    obterGrupos(etnia);
  }, [etnia]);

  return (
    <>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <div className="grade__coluna grade__coluna_12">
          <div className={`formulario__input-react-select ${erroEtnia && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Etnia
            </label>
            {erroEtnia && <MensagemDeErro campo='etnia' />}
            <Select
              name="etnia"
              id="etnia"
              placeholder="Selecione uma opção"
              value={etnia?.id}
              options={mapearEtniaParaSelect(etnias)}
              onChange={handleOnChangeEtnia}
              disabled={disabled} />
          </div>
        </div>
      </div>

      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erroGrupoEtnico && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Grupo étnico
            </label>
            {erroGrupoEtnico && <MensagemDeErro campo='grupoEtnico' />}
            <Select
              name="grupoEtnico"
              id="grupoEtnico"
              placeholder="Selecione uma opção"
              value={grupoEtnico?.id}
              options={mapearGrupoEtnicoParaSelect(gruposEtnicos)}
              onChange={handleOnChangeGrupoEtnico}
              disabled={!etnia?.ehIndigena || disabled} />
          </div>
        </div>
        <div className="grade__coluna grade__coluna_6">
          <fieldset className="formulario__grupo-de-inputs">
            <legend className="formulario__label">
              Mora na aldeia
            </legend>
            {erroMoraNaAldeia && <MensagemDeErro campo='moraNaAldeia' />}
            <CampoRadioButton
              mesmaLinha
              name="moraNaAldeia"
              value="true"
              label="Sim"
              checked={moraNaAldeia != null ? moraNaAldeia.toString() : ''}
              onChange={onChange}
              disabled={!etnia?.ehIndigena || disabled} />
            <CampoRadioButton
              mesmaLinha
              name="moraNaAldeia"
              value="false"
              label="Não"
              checked={moraNaAldeia != null ? moraNaAldeia.toString() : ''}
              onChange={onChange}
              disabled={!etnia?.ehIndigena || disabled} />
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default CampoGrupoEtnico;