import React, { useEffect, useState } from 'react';
import { CampoRg, CampoCtps, CampoCie } from './componentes';
import MensagemDeErro from '../../../MensagemDeErro';
// @ts-ignore
import Mensagem from '@bit/digix.digixui.mensagem';
import IPretendente from '../../../../interfaces/IPretendente';
import { IDocumento } from '../../../../interfaces/IDocumento';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';
import IInputEventsProps from '../../../../interfaces/IInputEventsProps';

interface ICampoDocumentosProps {
    dadosPretendente: IPretendente,
    erros?: IPretendenteValidacaoErroCampos,
    inputEvents: IInputEventsProps,
    disabled: boolean,
    onChangeDocumento: (documentos: IDocumento) => void
}

const CampoDocumentos = ({ dadosPretendente, erros, inputEvents, disabled, onChangeDocumento }: ICampoDocumentosProps) => {
  const ehEstrangeiro = dadosPretendente.ehEstrangeiro;
  const documentos = dadosPretendente.documentos;
  
  const possuiRg = !!documentos?.rg;  
  const [mostrarRG, setMostrarRG] = useState(possuiRg);
  const preencheuRg = !!(documentos?.rg?.numeroIdentidade && documentos?.rg?.dataDeExpedicao &&
    documentos?.rg?.orgaoExpedidor && documentos?.rg?.estado?.uf);

  const possuiCtps = !!documentos?.carteiraDeTrabalho;
  const [mostrarCtps, setMostrarCtps] = useState(possuiCtps);
  const preencheuCtps = !!(documentos?.carteiraDeTrabalho?.numero && documentos?.carteiraDeTrabalho?.dataDeExpedicao && 
  documentos?.carteiraDeTrabalho?.serie && documentos?.carteiraDeTrabalho?.estado?.uf); 

  const possuiCie = !!documentos?.cedulaDeIdentidadeDoEstrangeiro;
  const [mostrarCie, setMostrarCie] = useState(possuiCie);
  const preencheuCie = !!(documentos?.cedulaDeIdentidadeDoEstrangeiro?.numero && 
    documentos?.cedulaDeIdentidadeDoEstrangeiro?.dataDeExpedicao);

  const onChangeRg = (mostrarRG: boolean, possuiRgNosDadosDoPretendente: boolean) => {
    if (mostrarRG == possuiRgNosDadosDoPretendente)
      return inputEvents.onChange;
    
    if (possuiRgNosDadosDoPretendente) {
      setMostrarRG(false);
      onChangeDocumento({
        carteiraDeTrabalho: documentos?.carteiraDeTrabalho,
        cedulaDeIdentidadeDoEstrangeiro: documentos?.cedulaDeIdentidadeDoEstrangeiro
      });
    } else {
      setMostrarRG(true);
      onChangeDocumento({
        rg: {
          numeroIdentidade: '',
          orgaoExpedidor: '',
          estado: { id: 0, nome: '', uf: '' }
        },
        carteiraDeTrabalho: documentos?.carteiraDeTrabalho,
        cedulaDeIdentidadeDoEstrangeiro: documentos?.cedulaDeIdentidadeDoEstrangeiro
      });
    }
    return inputEvents.onChange;
  };

  const onChangeCtps = (mostrarCtps: boolean, possuiCtpsNosDadosDoPretendente: boolean) => {
    if (mostrarCtps == possuiCtpsNosDadosDoPretendente)
      return inputEvents.onChange;

    if (possuiCtpsNosDadosDoPretendente) {
      setMostrarCtps(false);
      onChangeDocumento({
        rg: documentos?.rg,
        cedulaDeIdentidadeDoEstrangeiro: documentos?.cedulaDeIdentidadeDoEstrangeiro
      });
    } else {
      setMostrarCtps(true);
      onChangeDocumento({
        rg: documentos?.rg,
        carteiraDeTrabalho: {
          numero: '',
          serie: '',
          estado: { id: 0, nome: '', uf: '' }
        },
        cedulaDeIdentidadeDoEstrangeiro: documentos?.cedulaDeIdentidadeDoEstrangeiro
      });
    }
    return inputEvents.onChange;
  };

  const onChangeCie = (mostrarCie: boolean, possuiCieNosDadosDoPretendente: boolean) => {
    if (mostrarCie == possuiCieNosDadosDoPretendente)
      return inputEvents.onChange;

    if (possuiCieNosDadosDoPretendente) {
      setMostrarCie(false);
      onChangeDocumento({
        rg: documentos?.rg,
        carteiraDeTrabalho: documentos?.carteiraDeTrabalho
      });
    } else {
      setMostrarCie(true);
      onChangeDocumento({
        rg: documentos?.rg,
        carteiraDeTrabalho: documentos?.carteiraDeTrabalho,
        cedulaDeIdentidadeDoEstrangeiro: {
          numero: '',
          orgaoExpedidor: 'CGPI/DIREX/DPF'
        },
      });
    }
    return inputEvents.onChange;
  };

  const determinarIcone = (possuiODocumento: boolean, preencheuODocumento: boolean) => {
    const iconeCheck = 'far fa-check';
    const iconePlus = 'far fa-plus';
    return possuiODocumento && preencheuODocumento ? iconeCheck : iconePlus;
  };

  const determinarBotao = (possuiODocumento: boolean) => {
    const botaoDocumentoAdicionado = 'botao botao_pequeno botao_com-icone-para-esquerda u-margem-direita-pequena u-botao-arredondado botao_cor-secundaria';
    const botaoAdicionarDocumento = 'botao botao_pequeno botao_com-icone-para-esquerda u-margem-direita-pequena u-botao-arredondado u-cor-cinza-sombreada';
    return possuiODocumento ? botaoDocumentoAdicionado : botaoAdicionarDocumento;
  };

  useEffect(() => {
    setMostrarCie(possuiCie);
    setMostrarRG(possuiRg);
    setMostrarCtps(possuiCtps);
    
  }, [ehEstrangeiro, possuiRg, possuiCie, possuiCtps]);

  return (<div>
    <div className="grade__linha">
      <div className="grade__coluna">
        <h2 className="titulo titulo_medio texto_cor-secundaria">
          <strong>Documentos</strong>
        </h2>
        <hr />
        <Mensagem 
          tipo='atencao' 
          titulo="Fique atento:" 
          icone='far fa-exclamation-triangle'>
          <span className="u-margem-inferior-pequena">
            {`${ehEstrangeiro ? 'O preenchimento dos dados da Cédula de Identidade de Estrangeiro (CIE) é obrigatório.' : 'É preciso adicionar pelo menos um documento. Por favor, preencha todos os campos do(s) documento(s) escolhido(s).'}`}
          </span>
        </Mensagem>
        <div className="formulario__descricao">
        </div>
        {erros?.documentos &&
        <MensagemDeErro mensagem="Insira um dos documentos solicitados abaixo" />
        }
      </div>
    </div>
    <div className="grade__linha">
      <div className="grade__coluna">
        <h4 className="titulo titulo_pequeno texto_cor-secundaria">
          <strong>Documento</strong>
        </h4>
      </div>
    </div>

    {ehEstrangeiro ?
      <div className="grade__linha">
        <div className="grade__coluna">
          <button onClick={() => onChangeCie(!mostrarCie, possuiCie)} className={determinarBotao(possuiCie)} type="button" disabled={disabled}>
            <i className={determinarIcone(possuiCie, preencheuCie)} aria-hidden="true"></i>CIE
          </button>
          <button onClick={() => onChangeCtps(!mostrarCtps, possuiCtps)} className={determinarBotao(possuiCtps)} type="button" disabled={disabled}>
            <i className={determinarIcone(possuiCtps, preencheuCtps)} aria-hidden="true"></i>CTPS
          </button>
          {mostrarCie &&
          <div>
            <br />
            <div className="formulario__label grade__coluna">
              <strong>Insira os dados do CIE</strong>
            </div>
            <CampoCie
              documentos={documentos}
              erros={erros}
              inputEvents={inputEvents}
              disabled={disabled}
              onChangeDocumento={onChangeDocumento} 
            />
            <hr />
          </div>
          }
        </div>
      </div> :
      <div className="grade__linha">
        <div className="grade__coluna">
          <button onClick={() => onChangeRg(!mostrarRG, possuiRg)} className={determinarBotao(possuiRg)} type="button" disabled={disabled}>
            <i className={determinarIcone(possuiRg, preencheuRg)} aria-hidden="true"></i>RG
          </button>
          <button onClick={() => onChangeCtps(!mostrarCtps, possuiCtps)} className={determinarBotao(possuiCtps)} type="button" disabled={disabled}>
            <i className={determinarIcone(possuiCtps, preencheuCtps)} aria-hidden="true"></i>CTPS
          </button>
          {mostrarRG &&
          <div>
            <br />
            <div className="formulario__label grade__coluna">
              <strong>Insira os dados do RG</strong>
            </div>
            <CampoRg
              documentos={dadosPretendente.documentos}
              erros={erros}
              inputEvents={inputEvents}
              disabled={disabled}
              onChangeDocumento={onChangeDocumento}
            />
            <hr />
          </div>
          }
        </div>
      </div>
    }
    <div className="grade__linha">
      <div className="grade__coluna">
        {mostrarCtps &&
        <div>
          <div className="formulario__label grade__coluna">
            <strong>Insira os dados da Carteira de Trabalho</strong>
          </div>
          <CampoCtps
            documentos={documentos}
            erros={erros}
            disabled={disabled}
            inputEvents={inputEvents}
            onChangeDocumento={onChangeDocumento} 
          />
        </div>
        }
      </div>
    </div>
  </div>);
};

export default CampoDocumentos;