//@ts-ignore
import { CPF } from 'cpf_cnpj';
import { maiorDeIdade, dataValida, idadeParaEmancipar } from '../../helpers/verificadorDeData';
import validadorDeNis from '../../helpers/validadorDeNis';
import { manterSomenteNumeros } from '../../helpers/mascaras';
import IPretendente from '../../interfaces/IPretendente';
import IPretendenteValidacaoCampos, { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import IDeficiencia from '../../interfaces/IDeficiencia';
import ICidade from '../../interfaces/ICidade';

let _dadosPretendente: IPretendente;
let _possuiDeficiencia: boolean | undefined;
let _possuiDoencaCronica: boolean | undefined;
let _checkboxDoNomeSocialEstaMarcado: boolean | undefined;
let _exibiuCampoCidadeDeResidencia: boolean | undefined;
let _cidadeDeResidencia: ICidade | undefined;
let _exibiuCamposDeEmancipacao: boolean | undefined;
let _ehAcessoAdministrativo: boolean | undefined;

const CAMPO_OBRIGATORIO = 'Campo obrigatório';
const ehEstrangeiro = () => _dadosPretendente.ehEstrangeiro;
const ehIndigena = () => _dadosPretendente.etnia?.ehIndigena;

const obrigatorio = (valor: string | boolean) => {
  if (valor === undefined || valor === '')
    return CAMPO_OBRIGATORIO;
};

const nome = (nome: string) => {
  const regex = /^[a-záàâãéèêíïóôõöúçñ ]+$/i;
  if (!!nome && !regex.test(nome))
    return 'Não é permitido a entrada de números ou caracteres especiais';
};

const estadoCivilEhCasado = (estadoCivil: string) => {
  return estadoCivil.toUpperCase() === 'CASADO';
};

const tipoDeEmancipacaoEhEscrituraPublica = (tipoDeEmancipacao?: string) => {
  return tipoDeEmancipacao === 'EscrituraPublica';
};

function obrigatorioSeSexoFeminino(valor?: boolean) {
  if (_dadosPretendente.sexo.toLowerCase() === 'feminino' && valor === undefined)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeEstrangeiro(valor: string) {
  if (ehEstrangeiro() && (!valor))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeNaoEstrangeiro(valor: string) {
  if (!ehEstrangeiro() && (!valor))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeNaoEstrangeiroEIndigena(valor?: number | boolean) {
  if (!ehEstrangeiro() && ehIndigena() && (valor == undefined))
    return CAMPO_OBRIGATORIO;
}

function documentos() {
  const possuiCie: boolean = !!_dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro;
  const possuiCtps: boolean = !!_dadosPretendente.documentos?.carteiraDeTrabalho;
  const possuiRg: boolean = !!_dadosPretendente.documentos?.rg;
  const valido = ehEstrangeiro() ? possuiCie : possuiRg || possuiCtps;
  if (!valido)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiCieNumeroDaCie() {
  if (ehEstrangeiro() && _dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro &&
    !_dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro.numero)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiCieDataDeExpedicaoCie() {
  if (ehEstrangeiro() && _dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro)
    return dataValida(_dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro.dataDeExpedicao);
}

function obrigatorioSePossuiRgNumeroIdentidade() {
  if (_dadosPretendente.documentos?.rg && !_dadosPretendente.documentos?.rg.numeroIdentidade)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiRgOrgaoExpedidor() {
  if (_dadosPretendente.documentos?.rg && !_dadosPretendente.documentos?.rg.orgaoExpedidor)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiRgEstado() {
  if (_dadosPretendente.documentos?.rg && !_dadosPretendente.documentos?.rg.estado.uf)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiCtpsNumero() {
  if (_dadosPretendente.documentos?.carteiraDeTrabalho && !_dadosPretendente.documentos?.carteiraDeTrabalho.numero)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiCtpsSerie() {
  if (_dadosPretendente.documentos?.carteiraDeTrabalho
    && !_dadosPretendente.documentos?.carteiraDeTrabalho.serie)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiCtpsEstado() {
  if (_dadosPretendente.documentos?.carteiraDeTrabalho
    && !_dadosPretendente.documentos?.carteiraDeTrabalho.estado?.uf)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeNaoCasado(valor: boolean) {
  if (_dadosPretendente.estadoCivil.toUpperCase() !== 'CASADO' && valor === undefined)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeCasadoDataDeCasamento(valor?: Date) {
  if (estadoCivilEhCasado(_dadosPretendente.estadoCivil) && !valor)
    return CAMPO_OBRIGATORIO;

  if (valor != undefined)
    return dataValida(valor);
}

function verificarDataDeEmancipacao() {
  const data = _dadosPretendente.emancipacao?.data;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !data)
    return CAMPO_OBRIGATORIO;
  if (data)
    return dataValida(data);
}

function verificarEscritura() {
  const escritura = _dadosPretendente.emancipacao?.escritura;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !escritura)
    return CAMPO_OBRIGATORIO;
}

function verificarCartorio() {
  const cartorio = _dadosPretendente.emancipacao?.cartorio;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !cartorio)
    return CAMPO_OBRIGATORIO;
}

function verificarLivro() {
  const livro = _dadosPretendente.emancipacao?.livro;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !livro)
    return CAMPO_OBRIGATORIO;
}

function verificarFolha() {
  const folha = _dadosPretendente.emancipacao?.folha;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !folha)
    return CAMPO_OBRIGATORIO;
}

function verificarEstado() {
  const estado = _dadosPretendente.emancipacao?.ufDoEstadoDaCidadeDoCartorioDeRegistro;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && !estado)
    return CAMPO_OBRIGATORIO;
}

function verificarCidadeDoCartorioDeRegistro() {
  const selecionouEstado = _dadosPretendente.emancipacao?.ufDoEstadoDaCidadeDoCartorioDeRegistro;
  const cidade = _dadosPretendente.emancipacao?.cidadeDoCartorioDeRegistro;
  const selecionouCidade = cidade && Object.keys(cidade).length > 0;
  if (tipoDeEmancipacaoEhEscrituraPublica(_dadosPretendente.emancipacao?.tipo) && selecionouEstado && !selecionouCidade)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeCasado(valor?: string) {
  if (estadoCivilEhCasado(_dadosPretendente.estadoCivil) && (valor === undefined || valor === ''))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSeInformouPossuirAlgumaDeficiencia() {
  if (_possuiDeficiencia && (!_dadosPretendente.deficiencias?.length || _dadosPretendente.deficiencias?.some((d: IDeficiencia) => !d.cid) ))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioSePossuiDoencaCronica() {
  if (_possuiDoencaCronica && !_dadosPretendente.cidDaDoencaCronica)
    return CAMPO_OBRIGATORIO;
}

const cpf = (cpf: string) => {
  if (!CPF.isValid(cpf))
    return 'CPF inválido';
};

function obrigatorioSeMarcouCheckboxConfirmandoSerTransexualOuTravesti() {
  const { nomeSocial } = _dadosPretendente;
  if (_checkboxDoNomeSocialEstaMarcado && (!nomeSocial))
    return CAMPO_OBRIGATORIO;
}

function obrigatorioQuandoExibirCampoCidadeDeResidencia() {
  if (_exibiuCampoCidadeDeResidencia && !_cidadeDeResidencia)
    return CAMPO_OBRIGATORIO;
}

function obrigatorioNomeSocialPossuirCaracter() {
  const { nomeSocial } = _dadosPretendente;

  if (_checkboxDoNomeSocialEstaMarcado && (!nomeSocial))
    return 'É necessário informar ao menos um nome.';
}

function verificarDataDeExpedicaoDaCie() {
  if (ehEstrangeiro() && _dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro)
    return dataValida(_dadosPretendente.documentos?.cedulaDeIdentidadeDoEstrangeiro.dataDeExpedicao);
}

function verificarDataDeExpedicaoDaCtps() {
  if (_dadosPretendente.documentos?.carteiraDeTrabalho)
    return dataValida(_dadosPretendente.documentos?.carteiraDeTrabalho.dataDeExpedicao);
}

function verificarDataDeExpedicaoDoRg() {
  if (!ehEstrangeiro() && _dadosPretendente.documentos?.rg)
    return dataValida(_dadosPretendente.documentos?.rg.dataDeExpedicao);
}

function obrigatorioSeNaoDesempregado(valor: string) {
  if (_dadosPretendente.situacaoNoMercadoDeTrabalho !== 'Desempregado' &&
    (!valor || valor === 'R$ 0,00'))
    return CAMPO_OBRIGATORIO;
}

function telefone() {
  if (_dadosPretendente.contato == undefined)
    return 'É obrigatório o preenchimento de ao menos um telefone';

  const { telefoneCelular, telefoneParaRecado } = _dadosPretendente.contato;
  if (!telefoneCelular && !telefoneParaRecado)
    return 'É obrigatório o preenchimento de ao menos um telefone';
}

function quantidadeMinimaDeCaracteres() {
  const { nome } = _dadosPretendente;
  if (!!nome && nome.replace(/\s+/, '').length < 4)
    return 'O nome deve conter pelo menos 4 caracteres';
}

function telefoneCelular() {
  const telefoneCelular = _dadosPretendente.contato?.telefoneCelular;
  if (!!telefoneCelular && manterSomenteNumeros(telefoneCelular).length != 11)
    return 'O telefone celular deve conter 11 dígitos';
}

function nomeDoResponsavel() {
  if (_dadosPretendente.contato != undefined) {
    const { nomeDoResponsavel, telefoneParaRecado } = _dadosPretendente.contato;
    if (telefoneParaRecado && !nomeDoResponsavel)
      return 'O nome do responsável é obrigatório';
  }
}

function telefoneParaRecado() {
  const telefoneParaRecado = _dadosPretendente.contato?.telefoneParaRecado;
  if (!!telefoneParaRecado && manterSomenteNumeros(telefoneParaRecado).length < 10)
    return 'O telefone para recado deve conter pelo menos 10 dígitos';
}

const email = (email: string) => {
  const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
  if (email && !regex.test(email))
    return 'Email inválido';
};

function obrigatorioSerValidoSePossuirNis() {
  const { nis } = _dadosPretendente;
  if (!!nis && !validadorDeNis.validarNis(nis))
    return 'Nis inválido';
}

function obrigatorioQuandoExibirCamposDeEmancipacao() {
  if (_exibiuCamposDeEmancipacao && !_dadosPretendente?.emancipacao?.tipo)
    return CAMPO_OBRIGATORIO;
}

const verificarDataDeNascimento = () => {
  const dataDeNascimento = _dadosPretendente?.dataDeNascimento;
  if (_ehAcessoAdministrativo)
    return idadeParaEmancipar(dataDeNascimento);
  
  const estaEmancipado = !!_dadosPretendente?.emancipacao?.tipo;
  if(estaEmancipado)
    return idadeParaEmancipar(dataDeNascimento);

  return maiorDeIdade(dataDeNascimento);
};

const validacoes: IPretendenteValidacaoCampos = {
  nome: [obrigatorio, nome, quantidadeMinimaDeCaracteres],
  nomeSocial: [obrigatorioSeMarcouCheckboxConfirmandoSerTransexualOuTravesti, nome, obrigatorioNomeSocialPossuirCaracter],
  nomeDaMae: [obrigatorio, nome],
  nomeDoPai: [nome],
  sexo: [obrigatorio],
  mulherChefeDeFamilia: [obrigatorioSeSexoFeminino],
  ehMulherVitimaDeViolenciaDomestica: [obrigatorioSeSexoFeminino],
  ehEstrangeiro: [obrigatorio],
  pais: [obrigatorioSeEstrangeiro],
  documentos: [documentos],
  numeroDaCie: [obrigatorioSePossuiCieNumeroDaCie],
  dataDeExpedicaoDaCie: [obrigatorioSePossuiCieDataDeExpedicaoCie, verificarDataDeExpedicaoDaCie],
  numeroIdentidadeRg: [obrigatorioSePossuiRgNumeroIdentidade],
  orgaoExpedidorRg: [obrigatorioSePossuiRgOrgaoExpedidor],
  ufRg: [obrigatorioSePossuiRgEstado],
  dataDeExpedicaoRg: [verificarDataDeExpedicaoDoRg],
  numeroCtps: [obrigatorioSePossuiCtpsNumero],
  serieCtps: [obrigatorioSePossuiCtpsSerie],
  dataDeExpedicaoDaCtps: [verificarDataDeExpedicaoDaCtps],
  ufCtps: [obrigatorioSePossuiCtpsEstado],
  nis: [obrigatorioSerValidoSePossuirNis],
  estadoCivil: [obrigatorio],
  uniaoEstavel: [obrigatorioSeNaoCasado],
  deficiencias: [obrigatorioSeInformouPossuirAlgumaDeficiencia],
  doencaCronica: [obrigatorioSePossuiDoencaCronica],
  cpf: [obrigatorio, cpf],
  dataDeNascimento: [obrigatorio, verificarDataDeNascimento],
  situacaoNoMercadoDeTrabalho: [obrigatorio],
  valorDaRenda: [obrigatorioSeNaoDesempregado],
  profissao: [obrigatorio],
  telefone: [telefone, telefoneCelular, telefoneParaRecado],
  email: [email],
  resideNoMunicipioDesde: [obrigatorio],
  dataDoCasamento: [obrigatorioSeCasadoDataDeCasamento],
  regimeDeCasamento: [obrigatorioSeCasado],
  etnia: [obrigatorioSeNaoEstrangeiro],
  grupoEtnico: [obrigatorioSeNaoEstrangeiroEIndigena],
  moraNaAldeia: [obrigatorioSeNaoEstrangeiroEIndigena],
  nomeDoResponsavel: [nome, nomeDoResponsavel],
  grauDeInstrucao: [obrigatorio],
  cidadeDeResidencia: [obrigatorioQuandoExibirCampoCidadeDeResidencia],
  tipoDeEmancipacao: [obrigatorioQuandoExibirCamposDeEmancipacao],
  dataDeEmancipacao: [verificarDataDeEmancipacao],
  numeroDaEscritura: [verificarEscritura],
  cartorio: [verificarCartorio],
  livro: [verificarLivro],
  folha: [verificarFolha],
  estado: [verificarEstado],
  cidadeDoCartorioDeRegistro: [verificarCidadeDoCartorioDeRegistro]
};

const aplicarFuncoesDeValidacao = (contexto: IPretendente, campo: string, valor: string,
  ehAcessoAdministrativo: boolean,
  possuiDeficiencia?: boolean,
  possuiDoencaCronica?: boolean,
  checkboxDoNomeSocialEstaMarcado?: boolean,  
  cidadeDeResidencia?: ICidade,
  exibiuCampoCidadeDeResidencia?: boolean,
  exibiuCamposDeEmancipacao?: boolean
) => {
  //@ts-ignore
  const funcoes = validacoes[campo] || [];

  _dadosPretendente = contexto;
  _possuiDeficiencia = possuiDeficiencia;
  _possuiDoencaCronica = possuiDoencaCronica;
  _checkboxDoNomeSocialEstaMarcado = checkboxDoNomeSocialEstaMarcado;
  _exibiuCampoCidadeDeResidencia = exibiuCampoCidadeDeResidencia;
  _cidadeDeResidencia = cidadeDeResidencia;
  _exibiuCamposDeEmancipacao = exibiuCamposDeEmancipacao;
  _ehAcessoAdministrativo = ehAcessoAdministrativo;

  const retornosComErro = funcoes.map((f: Function) => f.call(contexto, valor)).filter((r: any) => !!r);

  return retornosComErro[0];
};

const validarFormulario = (dadosPretendente: IPretendente, ehAcessoAdministrativo: boolean,
  possuiDeficiencia?: boolean, possuiDoencaCronica?: boolean, 
  checkboxDoNomeSocialEstaMarcado?: boolean, exibiuCamposDeEmancipacao?: boolean, 
  cidadeDeResidencia?: ICidade, exibiuCampoCidadeDeResidencia?: boolean,
): IPretendenteValidacaoErroCampos => {

  const errosValidacao: any = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(dadosPretendente, campo, dadosPretendente[campo],
      ehAcessoAdministrativo,
      possuiDeficiencia,
      possuiDoencaCronica,
      checkboxDoNomeSocialEstaMarcado,
      cidadeDeResidencia,
      exibiuCampoCidadeDeResidencia,
      exibiuCamposDeEmancipacao,
    );
    if (erro)
      return { ...erros, [campo]: erro };
    return erros;
  }, {});
  return errosValidacao;
};
export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };