import React from 'react';
import CampoRadioButton from '../CampoRadioButton';
import tipoDeImovel from './tipoDeImovel';
import MensagemDeErro from '../MensagemDeErro';

interface TipoDoImovelProps {
    tipo?: string,
    erro?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const TipoDoImovel = ({ tipo, erro, onChange }: TipoDoImovelProps) => {
  return (
    <div className="grade__coluna">
      <fieldset className="formulario__grupo-de-inputs">
        <legend className='formulario__label'>Tipo do imóvel</legend>
        {erro && <MensagemDeErro campo='tipo' />}
        <CampoRadioButton
          mesmaLinha
          name='tipo'
          value={tipoDeImovel.CASAOUAPARTAMENTO}
          label="Casa / Apartamento"
          onChange={onChange}
          checked={tipo != undefined ? tipo : ''} />
        <CampoRadioButton
          mesmaLinha
          name='tipo'
          value={tipoDeImovel.TERRENO}
          label="Terreno"
          onChange={onChange}
          checked={tipo != undefined ? tipo : ''} />
      </fieldset>
    </div>
  );
};
export default TipoDoImovel;