import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IPretendente from '../interfaces/IPretendente';
import IActionType from '../interfaces/IActionType';

export default function dadosTitular(state = initialState.dadosTitular, action: IActionType<IPretendente>) {
  switch (action.type) {
  case actionTypes.SALVAR_TITULAR_SUCESSO:
    return {
      ...action.state
    };
  case actionTypes.OBTEVE_INFORMACOES_DO_TITULAR_NO_SERVICO_DE_CONSULTA_DE_CPF:
    return {
      ...state,
      ...action.state
    };
  case actionTypes.CARREGAR_TITULAR:
    return {
      ...action.state
    };
  default:
    return state;
  }
}