import React from 'react';
import { CampoDeTexto } from '../../..';
import MensagemDeErro from '../../../MensagemDeErro';
import IPretendente from '../../../../interfaces/IPretendente';
import IContato from '../../../../interfaces/IContato';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../CampoDeTextoComMascara';

interface IInputEventsProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICampoContatoProps {
  dadosPretendente: IPretendente,
  nomeDoResponsavel: string,
  telefoneCelular: string,
  telefoneParaRecado: string,
  email: string,
  erros?: IPretendenteValidacaoErroCampos,
  inputEvents: IInputEventsProps,
  disabled: boolean,
  onChangeContato: (contato: IContato) => void,
}

const CampoContato = ({ dadosPretendente, nomeDoResponsavel = '', telefoneCelular = '', telefoneParaRecado = '', email = '', erros, inputEvents, disabled, onChangeContato }: ICampoContatoProps) => {
  const mascaraDeCelular = '(99) 99999-9999';
  const mascaraDeTelefoneFixo = '(99) 9999-9999';
  const mascaraDeTelefoneFixoParaRecado = mascaraDeTelefoneFixo + '9';
  const tamanhoDoTelefoneCelularComMascara = 15;
  const mascaraDeTelefoneParaRecado = telefoneParaRecado.length < tamanhoDoTelefoneCelularComMascara ? mascaraDeTelefoneFixoParaRecado : mascaraDeCelular;

  return (<div>
    <div className="grade__coluna">
      <h2 className="titulo titulo_medio texto_cor-secundaria">
        <strong>Informações de contato</strong></h2>
      <hr /> 
      <p className="formulario__descricao">
        Informe pelo menos um número de telefone.</p>
      {erros?.telefone && <MensagemDeErro mensagem={erros?.telefone} />}
    </div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTexto
        colunas='12'
        label="E-mail"
        name="email"
        value={email}
        erro={erros?.email}
        tipo="email"
        required={false}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({
          ...dadosPretendente.contato, email: event.target.value
        })}
      />
    </div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTextoComMascara
        colunas='6'
        label="Telefone celular"
        name="telefoneCelular"
        mascara={mascaraDeCelular}
        tipo="tel"
        value={telefoneCelular}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({
          ...dadosPretendente.contato, telefoneCelular: event.target.value
        })}>
        <div>Este número de telefone será utilizado para recuperação da senha de acesso à sua inscrição.</div>
      </CampoDeTextoComMascara>
    </div>
    <div className="grade__linha">
      <CampoDeTextoComMascara
        label="Telefone para recado"
        name="telefoneParaRecado"
        mascara={mascaraDeTelefoneParaRecado}
        tipo="tel"
        required={false}
        value={telefoneParaRecado}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({
          ...dadosPretendente.contato, telefoneParaRecado: event.target.value
        })}
      />
      <CampoDeTexto
        label="Nome do responsável"
        name="nomeDoResponsavel"
        tipo="text"
        required={!!telefoneParaRecado}
        value={nomeDoResponsavel}
        disabled={disabled || !telefoneParaRecado}
        erro={erros?.nomeDoResponsavel}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeContato({
          ...dadosPretendente.contato, nomeDoResponsavel: event.target.value
        })} />
    </div>
  </div >);
};

export default CampoContato;