import React, { ReactNode } from 'react';
import '../../estilos/estilos.scss';

interface ContainerProps {
  children: ReactNode
  tamanhoDoContainer: string,
  comFundo?: boolean,
  semPadding?: boolean,
  comCabecalhoFixo?: boolean
}

const Container = ({ children, tamanhoDoContainer, comFundo = true, semPadding = false, comCabecalhoFixo = true }: ContainerProps) => (
  <section className={`
  container ${tamanhoDoContainer ? `container_${tamanhoDoContainer}` : 'container_pequeno'}
  ${comFundo ? 'container_com-fundo' : ''}
  ${semPadding ? 'u-sem-padding' : ''} container_com-margem ${comCabecalhoFixo?'container_com-cabecalho-fixo':'' }`}>
    {children}
  </section>
);

export default Container;