import React from 'react';
import CampoEstado from '../../../CampoEstado';
//@ts-ignore
import Select from 'react-select';
import MensagemDeErro from '../../../../../MensagemDeErro';
import { IDocumento } from '../../../../../../interfaces/IDocumento';
import IRg from '../../../../../../interfaces/IRg';
import { IPretendenteValidacaoErroCampos } from '../../../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../../../CampoDeTextoComMascara';

const orgaoEmissores = ['CNT', 'DIC', 'DRT', 'FUNAI', 'GDF/PF',
  'IFP', 'IPF', 'MAER', 'MD', 'MEEX', 'MJ/PF', 'MM', 'MMA',
  'MME', 'MTE', 'PC', 'PM', 'POF', 'SEJUSP', 'SES', 'SJS',
  'SJTS', 'SPMAF/SR', 'SRE/DPMAF/DPF', 'SSP', 'COREN'];

interface IInputEventsProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICampoCieProps {
    documentos: IDocumento,
    erros?: IPretendenteValidacaoErroCampos,
    inputEvents: IInputEventsProps,
    disabled: boolean,
    onChangeDocumento: (documentos: IDocumento) => void
}

const CampoRg = ({ documentos, erros, inputEvents, disabled, onChangeDocumento }: ICampoCieProps) => {

  const handleOnChangeDocumento = (documentos: IDocumento, event: React.ChangeEvent<HTMLInputElement>, nomePropriedade: string) => {
    if (documentos.rg) {
      const rg: IRg = documentos.rg;
      onChangeDocumento({ ...documentos, rg: { ...rg, [nomePropriedade]: event.target.value } });
    }
  };

  const handleOnChangeEstado = (selected: { label: string, value: string }) => {
    if (selected && documentos.rg) {
      const rg: IRg = documentos.rg;
      onChangeDocumento({ ...documentos, rg: { ...rg, estado: { ...documentos.rg.estado, uf: selected.value } } });
    }
  };

  const handleOnChangeOrgaoExpedidor = (selected: { label: string, value: string }) => {

    if (selected && documentos.rg) {
      const rg: IRg = documentos.rg;
      onChangeDocumento({ ...documentos, rg: { ...rg, 'orgaoExpedidor': selected.value } });
    }
  };

  return (<div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTextoComMascara
        label="Número"
        name="numeroIdentidadeRg"
        mascara="********************"
        value={documentos.rg?.numeroIdentidade || ''}
        tipo="text"
        erro={erros?.numeroIdentidadeRg}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'numeroIdentidade'); }} />

      <CampoDeTextoComMascara
        label="Data de expedição"
        name="dataDeExpedicaoRg"
        mascara="99/99/9999"
        value={documentos.rg?.dataDeExpedicao?.toString() || ''}
        tipo="text"
        erro={erros?.dataDeExpedicaoRg}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'dataDeExpedicao'); }} />

    </div>
    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <div className="grade__coluna u-largura-total">
        <div className={`formulario__input-react-select ${erros?.orgaoExpedidorRg && 'formulario__input-react-select_erro'}`}>
          <label className="formulario__label">
            Órgão emissor
          </label>
          {erros?.orgaoExpedidorRg &&
          <MensagemDeErro />
          }
          <Select
            value={documentos.rg?.orgaoExpedidor}
            placeholder="Selecione"
            onChange={handleOnChangeOrgaoExpedidor}
            options={
              orgaoEmissores.map(o => {
                return { label: o, value: o };
              })}
            disabled={disabled} />
        </div>
      </div>
      <div className="grade__coluna u-largura-total">
        <label className="formulario__label">
          Estado
        </label>
        {erros?.ufRg && <MensagemDeErro />}
        <CampoEstado
          value={documentos.rg?.estado.uf || ''}
          onChange={handleOnChangeEstado}
          disabled={disabled}
          erro={erros?.ufRg} />
      </div>
    </div>
  </div>);
};

export default CampoRg;