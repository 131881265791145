import React, { ReactNode } from 'react';
import '../../estilos/estilos.scss';

interface ContainerCabecalhoProps {
    children: ReactNode,
    className?: string
}

const ContainerCabecalho = ({ children, className }: ContainerCabecalhoProps) => (
  <header className={`container__cabecalho ${className || ''}`}>
    {children}
  </header>
);

export default ContainerCabecalho;