import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { estaAutenticado } from './autenticacao';
import PropTypes from 'prop-types';

export const RotaPublicaAutenticada = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => {
      return estaAutenticado() ? <Component {...props}/> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
    }}
    />
  );
};

RotaPublicaAutenticada.propTypes = {
  component:PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  location:PropTypes.string
};
