import * as actionTypes from './actionTypes';
import IInformacoesDaFamilia from '../interfaces/IInformacoesDaFamilia';
import IActionType from '../interfaces/IActionType';

export const salvarInformacoesDaFamiliaSucesso = (informacoesDaFamilia: IInformacoesDaFamilia): IActionType<IInformacoesDaFamilia> => ({
  type: actionTypes.SALVAR_INFORMACOES_DA_FAMILIA_SUCESSO,
  state: informacoesDaFamilia
});

export const carregarInformacoesDaFamilia = (informacoesDaFamilia: IInformacoesDaFamilia): IActionType<IInformacoesDaFamilia> => ({
  type: actionTypes.CARREGAR_INFORMACOES_DA_FAMILIA,
  state: informacoesDaFamilia
});