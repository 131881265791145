import ISeparacaoDeInscricao from '../interfaces/ISeparacaoDeInscricao';
import { apiDaInscricao } from './axios';

const consultarInscricaoParaSeparacao = async (inscricaoId: Number) => {
  return await apiDaInscricao().get(`/SeparacaoDeInscricao/${inscricaoId}/obter`);
};

const finalizarSeparacaoDeInscricao = async (payload: ISeparacaoDeInscricao) => {
  await apiDaInscricao().post('/separacaoDeInscricao/separar', payload);
};

export { consultarInscricaoParaSeparacao, finalizarSeparacaoDeInscricao };