import React from 'react';
//@ts-ignore
import Select from 'react-select';
import CampoRadioButton from '../CampoRadioButton';
import MensagemDeErro from '../MensagemDeErro';
import IDistritoParaSelect from '../../interfaces/IDistritoParaSelect';

interface ICampoDistritoProps {
    label: string,
    moraEmDistrito: boolean,
    distrito: number,
    distritos: IDistritoParaSelect[],
    erroMoraEmDistrito?: boolean,
    erroDistrito?: boolean,
    atualizarMoraEmDistrito: (moraEmDistrito: boolean) => void,
    atualizarDistrito: (idDoDistrito: number) => void,
    limparDistrito: () => void,
    comSeparadores?: boolean,
    disabled: boolean
}

interface IEventOnChange {
    label: string,
    value: number
}

const CampoDistrito = ({ label, moraEmDistrito, distrito, distritos, erroMoraEmDistrito,
  erroDistrito, atualizarMoraEmDistrito, limparDistrito, atualizarDistrito, comSeparadores = true, disabled }: ICampoDistritoProps) => {

  const handleOnChangeMoraEmDistrito = () => {
    atualizarMoraEmDistrito(true);
  };
  const handleonChangeDistrito = (evento: IEventOnChange) => {
    atualizarDistrito(evento.value);
  };

  return (<div className="grade__coluna">
    <fieldset className="formulario__grupo-de-inputs">
      <legend className="formulario__label">
        {label}
      </legend>
      {erroMoraEmDistrito && <MensagemDeErro />}
      <CampoRadioButton
        mesmaLinha
        name="moraEmDistrito"
        value={'true'}
        label="Sim"
        checked={moraEmDistrito.toString()}
        onChange={handleOnChangeMoraEmDistrito}
        disabled={disabled} />
      <CampoRadioButton
        mesmaLinha
        name="moraEmDistrito"
        value={'false'}
        label="Não"
        checked={moraEmDistrito.toString()}
        onChange={limparDistrito}
        disabled={disabled} />
    </fieldset>

    {comSeparadores && <hr />}

    {moraEmDistrito &&
    <div className="grade__coluna">
      <div className={`formulario__input-react-select ${erroDistrito && 'formulario__input-react-select_erro'}`}>

        <label className="formulario__label">
          Selecione o Distrito
        </label>

        {erroDistrito && <MensagemDeErro campo="distrito" />}

        <Select
          id="distritos"
          name="distritos"
          placeholder="Selecione uma opção"
          value={distrito}
          options={distritos}
          onChange={handleonChangeDistrito}
          disabled={disabled}
        />
        {comSeparadores && <hr />}
      </div>
    </div>
    }
  </div>);
};
export default CampoDistrito;