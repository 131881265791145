import { CARREGAR_REGIME_DE_CASAMENTO_SUCESSO } from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IRegimeDeCasamento from '../interfaces/IRegimeDeCasamento';

export default (state = initialState.regimesDeCasamento, action: IActionType<IRegimeDeCasamento[]>) => {
  switch (action.type) {
  case CARREGAR_REGIME_DE_CASAMENTO_SUCESSO:
    return [...action.state];
  default:
    return state;
  }
};