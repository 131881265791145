import { CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO } from './actionTypes';
import { AnyAction, Dispatch } from 'redux';
import { apiDaInscricao } from '../servicos/axios';
import ITipoDeDeficiencia from '../interfaces/ITipoDeDeficiencia';

export const carregarTiposDeDeficienciaSucesso = (tiposDeDeficiencia: ITipoDeDeficiencia[]) => {
  return {
    type: CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO,
    state: tiposDeDeficiencia
  };
};

export const carregarTiposDeDeficiencia = () =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data: tiposDeDeficiencia } = await apiDaInscricao().get('/tipoDeDeficiencia/ObterTodos');
    dispatch(carregarTiposDeDeficienciaSucesso(tiposDeDeficiencia));
  };