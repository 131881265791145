import { axiosComLoading } from '../../servicos/axios';
import IEnderecoParaApi from'../../interfaces/IEnderecoParaApi';

const obterEnderecoPeloCep = async (cep: string) => {
  if (cep)
    cep = cep.replace('-', '');

  const urlApiDeEndereco = process.env.REACT_APP_URL_API_ENDERECO;
 
  const response = await axiosComLoading.get(`${urlApiDeEndereco}/buscar/enderecoPeloCep/?cep=${cep}`);
  const endereco = {
    Logradouro: `${response.data.Endereco.NomeDoTipoDeLogradouro} ${response.data.Endereco.Logradouro}`,
    Bairro: response.data.Endereco
  } as IEnderecoParaApi;
  return endereco;
};

const obterEnderecoPeloViaCep = async (cep: string) => {
  if (cep)
    cep = cep.replace('-', '');

  const urlApiDeEndereco = 'https://viacep.com.br/ws';
  const response = await axiosComLoading.get(`${urlApiDeEndereco}/${cep}/json`);
  
  if(response.data.erro)
    return {
      Logradouro: '',
      Bairro: '' 
    } as IEnderecoParaApi;

  return {
    Logradouro: response.data.logradouro,
    Bairro: response.data.bairro,
    CidadeId: Number(response.data.ibge.substring(0,6))
  } as IEnderecoParaApi; 
};

export default { obterEnderecoPeloCep, obterEnderecoPeloViaCep };