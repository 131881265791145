const aplicarMascaraDeCpf = (cpf:string) => {
  let cpfSemSimbolos = manterSomenteNumeros(cpf);
  return String(cpfSemSimbolos).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
};

const aplicarMascaraDeNis = (nis?:string) => {
  if (!nis) return '';
  return String(nis).replace(/(\d{3})(\d{5})(\d{2})(\d{1})/g, '\$1.\$2.\$3\-\$4');
};

const aplicarMascaraDeCep = (cep:string) => {
  return String(cep).replace(/(\d{5})(\d{3})/g, '\$1-\$2');
};

const aplicarMascaraDeTelefone = (telefone?:string, ocultarDigitos = false) => {
  if (!telefone) return '';
  telefone = telefone.replace(/[^\d]+/g,'');
  const ehTelefoneFixo = telefone.length === 10;
  let mascaraFinal = ocultarDigitos 
    ? ehTelefoneFixo ? '(\$1) ****-\$3' :  '(\$1) *****-\$3'
    : '(\$1) \$2-\$3';

  const quantidadeDeDigitosIniciais = ehTelefoneFixo ? 4 : 5;
  const regexParaObterTelefone = new RegExp(`(\\d{2})(\\d{${quantidadeDeDigitosIniciais}})(\\d{4})`, 'g');
  return String(telefone).replace(regexParaObterTelefone, mascaraFinal); 
};

const aplicarMascaraDeTelefoneOculto = (telefone:string) => {
  return aplicarMascaraDeTelefone(telefone, true);
};

export const manterSomenteNumeros = (telefone:string) => {
  return String(telefone).replace(/[^0-9]+/g, '');
};

export default { aplicarMascaraDeCep, aplicarMascaraDeNis, aplicarMascaraDeCpf,
  manterSomenteNumeros, aplicarMascaraDeTelefone, aplicarMascaraDeTelefoneOculto };