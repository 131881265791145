import * as actionTypes from './actionTypes';
import IPretendente from '../interfaces/IPretendente';
import IActionType from '../interfaces/IActionType';
import IInformacoesDoServicoDeConsultaDeCpf from '../interfaces/IInformacoesDoServicoDeConsultaDeCpf';

export const salvarTitularSucesso = (dadosTitular: IPretendente): IActionType<IPretendente> =>
  ({ type: actionTypes.SALVAR_TITULAR_SUCESSO, state: dadosTitular });

export const carregarTitular = (dadosTitular: IPretendente): IActionType<IPretendente> =>
  ({ type: actionTypes.CARREGAR_TITULAR, state: dadosTitular });

export const obteveInformacoesDoTitular = (informacoesDoServicoDeConsultaDeCpf: IInformacoesDoServicoDeConsultaDeCpf): IActionType<IInformacoesDoServicoDeConsultaDeCpf> =>
  ({ type: actionTypes.OBTEVE_INFORMACOES_DO_TITULAR_NO_SERVICO_DE_CONSULTA_DE_CPF, state: informacoesDoServicoDeConsultaDeCpf });