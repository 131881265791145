import React, { ReactNode } from 'react';
import '../../estilos/estilos.scss';

interface ContainerConteudoProps {
    children: ReactNode,
    className?: string
}

const ContainerConteudo = ({ children, className }: ContainerConteudoProps) => (
  <div className={`container__conteudo ${className || ''}`}>
    {children}
  </div>
);

export default ContainerConteudo;