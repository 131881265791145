import * as types from '../actions/actionTypes';
import initialState from './initialState.reducer';

const actionTypeEndsInSuccess = type => type.substring(type.length - 8) === '_SUCESSO';

export default (state = initialState.requisicoesAjax, action) => {
  if (action.type === types.BEGIN_AJAX_CALL)
    return state + 1;
  else if (action.type === types.AJAX_CALL_ERROR || actionTypeEndsInSuccess(action.type) || action.type === types.END_AJAX_CALL)
    return state === 0 ? 0 : state - 1;

  return state;
};
