import React, { ReactNode } from 'react';
import { converterData } from '../../helpers/verificadorDeData';
import {
  CampoMulherChefeDeFamilia, CampoEhMulherVitimaDeViolenciaDomestica, CampoEstadoCivil,
  CampoDocumentos, CampoGrupoEtnico,
  CampoEstrangeiro, CampoMercadoDeTrabalho,
  CampoContato
} from './componentes';
import CampoSexo from '../CampoSexo';
import CampoDeficiencia from '../CampoDeficiencia';
import CampoDoencaCronica from '../CampoDoencaCronica';
import CampoDeTexto from '../CampoDeTexto';
import CampoCheckbox from '../CampoCheckbox';
import CampoAnoMoradia from '../CampoAnoMoradia';
import { verificarSeEhAmbienteDeProducao } from '../../helpers/verificadorDeAmbientes';
import Section from '../Section';
import IPretendente from '../../interfaces/IPretendente';
import IProfissao from '../../interfaces/IProfissao';
import { IDocumento } from '../../interfaces/IDocumento';
import IContato from '../../interfaces/IContato';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import ICid from '../../interfaces/ICid';
import CampoDeTextoComMascara from '../CampoDeTextoComMascara';
//@ts-ignore
import Mensagem from '@bit/digix.digixui.mensagem';
import IRegimeDeCasamento from '../../interfaces/IRegimeDeCasamento';
import IGrupoEtnico from '../../interfaces/IGrupoEtnico';
import IEtnia from '../../interfaces/IEtnia';
import IGrauDeInstrucao from '../../interfaces/IGrauDeInstrucao';
import CampoCidade from '../CampoCidade';
import ICidadeParaSelect from '../../interfaces/ICidadeParaSelect';
//@ts-ignore
import Dialogo from '@bit/digix.digixui.dialogo';
import './styles.scss';
import IInformacaoJuncaoDeInscricao from '../../interfaces/IInformacaoJuncaoDeInscricao';
import mascaras from '../../helpers/mascaras';
import CamposDeEmancipacao from './componentes/CamposDeEmancipacao';
import IEmancipacao from '../../interfaces/IEmancipacao';
import IDeficiencia from '../../interfaces/IDeficiencia';
import ITipoDeDeficiencia from '../../interfaces/ITipoDeDeficiencia';
interface IEventosDeficienciaProps {
  buscarCid: (cid: string) => void,
  adicionarDeficiencia: (deficiencia:IDeficiencia) => void,
  removerDeficiencia: (cid: ICid) => void,
}
interface IEventosDoencaCronica {
  buscarCid: (cid: string) => void,
  atualizarDoencaCronica: (doencaCronica: number, nomeDaDoencaCronica: string) => void,
  removerDoencaCronica: () => void,
}

interface IFormularioPretendenteProps {
  dadosPretendente: IPretendente,
  camposDesabilitados: string[],
  profissoes: IProfissao[],
  grauDeInstrucoes: IGrauDeInstrucao[],
  erros?: IPretendenteValidacaoErroCampos,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void,
  possuiDeficiencia: boolean,
  onChangeDeficiencia: (event: React.ChangeEvent<HTMLInputElement>) => void,
  eventosDeficiencia: IEventosDeficienciaProps,
  possuiDoencaCronica: boolean,
  onChangeDoencaCronica: (event: React.ChangeEvent<HTMLInputElement>) => void,
  eventosDoencaCronica: IEventosDoencaCronica,
  nomeDaCidade: string,
  onChangeDocumento: (documento: IDocumento) => void,
  onChangeNacionalidade: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeCpf?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeSexo: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeNomeSocial: () => void,
  checkboxDoNomeSocialEstaMarcado: boolean,
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  indicadorPasso: ReactNode,
  ehFormularioDeConjuge: boolean,
  onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: () => void,
  onChangeProfissao: (profissaoSelecionada?: IProfissao) => void,
  onChangeGrauDeInstrucao: (grauDeInstrucaoSelecionado?: IGrauDeInstrucao) => void,
  onChangeContato: (contato: IContato) => void,
  onChangePais: (sigla?: string, nome?: string) => void,
  regimesDeCasamento: IRegimeDeCasamento[],
  etnias: IEtnia[],
  onChangeEtnia: (selected?: IEtnia) => void,
  onChangeGrupoEtnico: (selected?: IGrupoEtnico) => void,
  onChangeRegimeDeCasamento: (selected?: IRegimeDeCasamento) => void,
  onChangeEstadoCivil: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onChangeCidadeDeResidencia?: (idDaCidade?: number | null) => void,
  exibirCampoCidadeDeResidencia: boolean,
  cidades?: ICidadeParaSelect[],
  habilitarCampoCpf: boolean,
  formularioPermiteEdicao: boolean,
  ehAcessoAdministrativo: boolean,
  exibirDialogoDeJuncaoDeInscricao?: boolean,
  cancelarJuncaoDaInscricao?: () => void,
  confirmarJuncaoDaInscricao?: () => void,
  informacaoParaJuncaoDeInscricao?: IInformacaoJuncaoDeInscricao,
  realizandoJuncaoDeInscricao?: boolean,
  juntarInscricao?: () => void,
  exibirCamposDeEmancipacao: boolean,
  onChangeEmancipacao: (emancipacao: IEmancipacao, camposParaRemoverErro?: string[]) => void,
  tiposDeDeficiencia: ITipoDeDeficiencia[]
}

const FormularioPretendente = ({ dadosPretendente, camposDesabilitados = [], profissoes, grauDeInstrucoes, nomeDaCidade, erros, onChange, onBlur, onFocus,
  eventosDeficiencia, eventosDoencaCronica, onChangeDocumento, onChangeNacionalidade, onChangeCpf, onChangeSexo, onChangeNomeSocial,
  checkboxDoNomeSocialEstaMarcado, onClick, indicadorPasso, ehFormularioDeConjuge = false, formularioPermiteEdicao = false,
  onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica, onChangeProfissao, onChangeGrauDeInstrucao, possuiDeficiencia, possuiDoencaCronica, onChangeDeficiencia,
  onChangeContato, onChangeDoencaCronica, onChangePais, regimesDeCasamento, etnias, onChangeEtnia, onChangeGrupoEtnico, onChangeRegimeDeCasamento,
  onChangeEstadoCivil, onChangeCidadeDeResidencia, exibirCampoCidadeDeResidencia = false, cidades, habilitarCampoCpf, ehAcessoAdministrativo,
  exibirDialogoDeJuncaoDeInscricao = false, cancelarJuncaoDaInscricao, confirmarJuncaoDaInscricao, informacaoParaJuncaoDeInscricao,
  realizandoJuncaoDeInscricao = false, juntarInscricao, exibirCamposDeEmancipacao, onChangeEmancipacao, tiposDeDeficiencia }: IFormularioPretendenteProps) => {

  const onChangeParaCpf = onChangeCpf || onChange;
  const inputEvents = { onChange, onBlur, onFocus };
  const inputEventsCpf = { ...inputEvents, onChange: onChangeParaCpf };
  const ehEstrangeiro = dadosPretendente.ehEstrangeiro;
  const dataDeNascimento = converterData(dadosPretendente.dataDeNascimento);
  const anoDeNascimentoDoPretendente = dataDeNascimento.year();
  const dataEhValida = dataDeNascimento.isValid();
  const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();
  const habilitarCampoNome = (ehAcessoAdministrativo || !ehAmbienteDeProducao) && formularioPermiteEdicao;
  const ehMulher = dadosPretendente.sexo?.toLowerCase() === 'feminino';
  const afirmacaoPossuiDeficiencia = `Sim, o ${ehFormularioDeConjuge ? 'cônjuge' : 'titular'} possui deficiência e tem atestado`;
  const afirmacaoPossuiDoencaCronica = `Sim, o ${ehFormularioDeConjuge ? 'cônjuge' : 'titular'} possui doença crônica e tem atestado`;

  return (
    <form className="formulario formulario_medio">
      <Section comMargemSuperiorMedia={false}>
        {indicadorPasso}
        <hr />
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Dados Pessoais</strong>
            </h2>
            <div className="formulario__descricao">
              Informe seu nome e CPF
            </div>
          </div>
        </div>

        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoDeTextoComMascara
            colunas="3"
            label="CPF"
            mascara="999.999.999-99"
            name="cpf"
            pattern="\d*"
            value={dadosPretendente.cpf || ''}
            erro={erros?.cpf}
            {...inputEventsCpf}
            disabled={!habilitarCampoCpf} />
          <CampoDeTexto
            label="Nome completo"
            name="nome"
            value={dadosPretendente.nome || ''}
            maxLength={150}
            erro={erros?.nome}
            disabled={!habilitarCampoNome}
            {...inputEvents}
          />
        </div>

        <CampoSexo
          sexo={dadosPretendente.sexo.toLowerCase()}
          error={erros?.sexo == undefined ? false : true}
          onChangeSexo={onChangeSexo}
          disabled={!formularioPermiteEdicao}
        />

        <hr />

        <div className="grade__linha">
          <div className="grade__coluna">
            <label htmlFor="nomeSocial">
              Nome social (opcional)
            </label>
            <p className="formulario__descricao">
              Para <strong>transexuais</strong> e <strong>travestis</strong>.
            </p>
            <p className="formulario__descricao u-margem-inferior-media">
              Baseado no Decreto nº 13.684, de 12.07.13, que determina a identificação pelo nome social de transexuais e travestis em órgãos públicos.
            </p>
            <CampoCheckbox
              name={'exibirNomeSocial'}
              label={'Sim, sou transexual ou travesti e desejo informar meu nome social.'}
              checked={checkboxDoNomeSocialEstaMarcado}
              onChange={onChangeNomeSocial}
              disabled={!formularioPermiteEdicao} />
            {checkboxDoNomeSocialEstaMarcado && (<>
              <div className="formulario__input_nome-social">
                <CampoDeTexto
                  colunas="12"
                  name="nomeSocial"
                  value={dadosPretendente.nomeSocial || ''}
                  maxLength={150}
                  erro={erros?.nomeSocial}
                  disabled={!formularioPermiteEdicao}
                  {...inputEvents} />
              </div>

              <Mensagem
                tipo='atencao'
                titulo="Atenção!"
                icone='far fa-exclamation-circle'>
                <strong>Este Campo é exclusivo para nome social!</strong> Favor <strong>não inserir</strong> apelido, sobrenome, nome do atendente ou qualquer outra informação além do nome social
              </Mensagem>
            </>
            )
            }
          </div>
        </div>

        <hr />

        {exibirCampoCidadeDeResidencia &&
          <div className='grade__linha grade__coluna_6'>
            <CampoCidade
              label="Cidade de residência"
              name="cidade"
              placeholder="Selecione a cidade"
              erro={erros?.cidadeDeResidencia}
              onChange={onChangeCidadeDeResidencia}
              cidades={cidades}
              disabled={!formularioPermiteEdicao}
            />
          </div>
        }

        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoDeTextoComMascara
            label="Data de Nascimento"
            name="dataDeNascimento"
            mascara="99/99/9999"
            value={dadosPretendente.dataDeNascimento?.toString() || ''}
            pattern="\d*"
            erro={erros?.dataDeNascimento}
            {...inputEvents}
            disabled={camposDesabilitados.includes('dataDeNascimento') || !habilitarCampoNome} />
          <CampoAnoMoradia
            nomeCidade={nomeDaCidade}
            value={dadosPretendente.resideNoMunicipioDesde}
            anoDeNascimentoDoPretendente={anoDeNascimentoDoPretendente}
            erros={erros}
            onChange={onChange}
            disabled={!dataEhValida || !formularioPermiteEdicao} />
        </div>

        <CampoDeTexto
          label="Nome completo da mãe"
          name="nomeDaMae"
          value={dadosPretendente.nomeDaMae || ''}
          erro={erros?.nomeDaMae}
          disabled={!formularioPermiteEdicao}
          {...inputEvents} />

        <CampoDeTexto
          label="Nome do pai (opcional)"
          name="nomeDoPai"
          value={dadosPretendente.nomeDoPai || ''}
          erro={erros?.nomeDoPai}
          disabled={!formularioPermiteEdicao}
          {...inputEvents} />

        <hr />

        <CampoEstadoCivil
          estadoCivil={dadosPretendente.estadoCivil?.toLowerCase()}
          uniaoEstavel={dadosPretendente.uniaoEstavel}
          dataDoCasamento={dadosPretendente.dataDoCasamento}
          regimeDeCasamento={dadosPretendente.regimeDeCasamento}
          erroEstadoCivil={erros?.estadoCivil ? true : false}
          erroUniaoEstavel={erros?.uniaoEstavel ? true : false}
          erroDataDoCasamento={erros?.dataDoCasamento}
          erroRegimeDeCasamento={erros?.regimeDeCasamento}
          ehEstrangeiro={dadosPretendente.ehEstrangeiro}
          disabled={!formularioPermiteEdicao}
          regimesDeCasamento={regimesDeCasamento}
          {...inputEvents}
          onChangeRegimeDeCasamento={onChangeRegimeDeCasamento}
          onChangeEstadoCivil={onChangeEstadoCivil} />
        <hr />

        <CampoDeTextoComMascara
          label="NIS - Número de Identificação Social"
          name="nis"
          mascara="999.99999.99-9"
          value={dadosPretendente.nis || ''}
          pattern="\d*"
          required={false}
          erro={erros?.nis}
          disabled={!formularioPermiteEdicao}
          {...inputEvents} >
          <div className="u-margem-inferior-pequena">
            O NIS pode ser encontrado no Cartão Cidadão ou no cartão do Bolsa Família. <strong>Para determinados programas habitacionais,
              como o Minha Casa Minha Vida - Faixa 1, o NIS é obrigatório.</strong>
          </div>
          <div>
            Caso não possua, procure a Secretaria Municipal de Assistência Social ou o Centro de Referência de Assistência Social do seu município.
          </div>
        </CampoDeTextoComMascara>

        {exibirCamposDeEmancipacao &&
          <>
            <hr />
            <CamposDeEmancipacao
              dadosPretendente={dadosPretendente}
              erros={erros}
              inputEvents={inputEvents}
              onChangeEmancipacao={onChangeEmancipacao}
              disabled={!formularioPermiteEdicao}
            />
          </>
        }

        <hr />
        <CampoEstrangeiro
          ehEstrangeiro={dadosPretendente.ehEstrangeiro}
          pais={dadosPretendente.pais?.sigla || ''}
          erros={erros}
          onChange={onChangeNacionalidade}
          atualizarPais={onChangePais}
          disabled={!formularioPermiteEdicao} />
        {!ehEstrangeiro &&
          <CampoGrupoEtnico
            grupoEtnico={dadosPretendente.grupoEtnico}
            etnia={dadosPretendente.etnia}
            moraNaAldeia={dadosPretendente.moraNaAldeia}
            erroGrupoEtnico={erros?.grupoEtnico}
            erroEtnia={erros?.etnia}
            erroMoraNaAldeia={erros?.moraNaAldeia}
            onChange={onChange}
            disabled={!formularioPermiteEdicao}
            onChangeEtnia={onChangeEtnia}
            onChangeGrupoEtnico={onChangeGrupoEtnico}
            etnias={etnias} />
        }

        <Dialogo
          tamanho='pequeno'
          deveSerExibido={exibirDialogoDeJuncaoDeInscricao}
          fecharDialogo={cancelarJuncaoDaInscricao}
          titulo='Junção de inscrição'
          corpo={() => {
            return (<>
              <Mensagem
                tipo='atencao'
                titulo="Fique atento:"
                icone='far fa-exclamation-circle'>
                A inscrição {informacaoParaJuncaoDeInscricao?.numeroInscricao} a qual esse CPF está vinculado será inativado.
              </Mensagem>

              <span className='titulo-juncao-inscricao'><strong>Cônjuge</strong></span>
              <span className='item-juncao-inscricao'>Nome: {informacaoParaJuncaoDeInscricao?.nome}</span>
              <span className='item-juncao-inscricao'>CPF: {mascaras.aplicarMascaraDeCpf(informacaoParaJuncaoDeInscricao?.cpf || '')}</span>
              <span className='item-juncao-inscricao margen-inferior'>Data de nascimento: {informacaoParaJuncaoDeInscricao?.dataDeNascimento?.toString()}</span>

              <span className='pergunta-juncao-inscricao'><strong>Tem certeza que deseja realizar a junção de inscrição?</strong></span>
            </>);
          }}
          rodape={() => {
            return (
              <>
                <button className="botao botao_cor-secundaria" type="button" onClick={confirmarJuncaoDaInscricao}>Confirmar</button>
                <button className="botao botao_contorno botao_cor-secundaria" type="button" onClick={cancelarJuncaoDaInscricao}>Cancelar</button>
              </>
            );
          }} />

      </Section>

      {ehMulher &&
        <Section >
          <CampoMulherChefeDeFamilia
            mulherChefeDeFamilia={dadosPretendente.mulherChefeDeFamilia}
            erros={erros}
            onChange={onChange}
            disabled={!formularioPermiteEdicao}
          />
        </Section>
      }

      {ehMulher &&
        <Section>
          <CampoEhMulherVitimaDeViolenciaDomestica
            ehMulherVitimaDeViolenciaDomestica={dadosPretendente.ehMulherVitimaDeViolenciaDomestica}
            erros={erros}
            onChange={onChange}
            disabled={!formularioPermiteEdicao}
          />
        </Section>
      }

      <Section>
        <CampoDocumentos
          dadosPretendente={dadosPretendente}
          erros={erros}
          inputEvents={inputEvents}
          onChangeDocumento={onChangeDocumento}
          disabled={!formularioPermiteEdicao} />
      </Section>
      <Section>
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Emprego e renda</strong>
            </h2>
          </div>
        </div>
        <hr />
        <CampoMercadoDeTrabalho
          dadosPretendente={dadosPretendente}
          profissoes={profissoes}
          grauDeInstrucoes={grauDeInstrucoes}
          erros={erros}
          //@ts-ignore
          inputEvents={inputEvents}
          onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica={onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica}
          onChangeProfissao={onChangeProfissao}
          onChangeGrauDeInstrucao={onChangeGrauDeInstrucao}
          ehFormularioDeConjuge={ehFormularioDeConjuge}
          disabled={!formularioPermiteEdicao} />
      </Section>
      <Section>
        <div className="grade__linha">
          <div className="grade__coluna">
            <h2 className="titulo titulo_medio texto_cor-secundaria">
              <strong>Saúde</strong>
            </h2>
          </div>
        </div>
        <hr />
        <CampoDeficiencia
          possuiDeficiencia={possuiDeficiencia}
          deficiencias={dadosPretendente.deficiencias}
          erroDeficiencias={!!erros?.deficiencias}
          onChangePossuiDeficiencia={onChangeDeficiencia}
          eventosDeficiencia={eventosDeficiencia}
          perguntaPossuiDeficiencia='Possui alguma deficiência?'
          afirmacaoPossuiDeficiencia={afirmacaoPossuiDeficiencia}
          disabled={!formularioPermiteEdicao}
          tiposDeDeficiencia={tiposDeDeficiencia} />
        <CampoDoencaCronica
          possuiDoencaCronica={possuiDoencaCronica}
          doencaCronica={dadosPretendente.cidDaDoencaCronica?.codigo || ''}
          nomeDaDoencaCronica={dadosPretendente.cidDaDoencaCronica ? dadosPretendente.cidDaDoencaCronica.codigo + ' - ' + dadosPretendente.cidDaDoencaCronica.descricao : ''}
          erroDoencaCronica={!!erros?.doencaCronica}
          onChangePossuiDoencaCronica={onChangeDoencaCronica}
          eventosDoencaCronica={eventosDoencaCronica}
          perguntaPossuiDoencaCronica='Possui alguma doença crônica incapacitante para o trabalho?'
          afirmacaoPossuiDoencaCronica={afirmacaoPossuiDoencaCronica}
          disabled={!formularioPermiteEdicao} />
      </Section>
      <Section>
        <CampoContato
          dadosPretendente={dadosPretendente}
          nomeDoResponsavel={dadosPretendente.contato?.nomeDoResponsavel}
          telefoneCelular={dadosPretendente.contato?.telefoneCelular}
          telefoneParaRecado={dadosPretendente.contato?.telefoneParaRecado}
          email={dadosPretendente.contato?.email}
          erros={erros}
          inputEvents={inputEvents}
          disabled={!formularioPermiteEdicao}
          onChangeContato={onChangeContato} />
        <hr />
        <div className="grade__coluna">
          {realizandoJuncaoDeInscricao &&
            <button
              className="botao botao_medio botao_cor-secundaria botao_largura-total"
              onClick={juntarInscricao}>
              Confirmar junção
            </button>}
          {!realizandoJuncaoDeInscricao && <button
            className="botao botao_medio botao_cor-secundaria botao_largura-total"
            onClick={onClick} disabled={!formularioPermiteEdicao}>
            Salvar
          </button>
          }
        </div>
      </Section>
    </form>);
};

export default FormularioPretendente;