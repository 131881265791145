import React, { Component } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

export default class CampoDeSelecaoAssincrono extends Component {
  render() {
    const { value, placeholder, onChange, disabled, textoDeBusca, obterOpcoes, onFocus, id, name } = this.props;
    return (
      <div>
        <Select.Async
          id={id}
          name={name}
          placeholder={placeholder}
          searchPromptText={textoDeBusca}
          loadingPlaceholder="Carregando..."
          value={value}
          loadOptions={obterOpcoes}
          autoload={true}
          clearable={true}
          onChange={onChange}
          autoBlur={true}
          onFocus={onFocus}
          disabled={disabled} />
      </div>
    );
  }
}

CampoDeSelecaoAssincrono.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  textoDeBusca: PropTypes.string,
  obterOpcoes: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string
};
