import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import dadosTitular from './dadosTitular.reducer';
import dadosConjuge from './dadosConjuge.reducer';
import login from './login.reducer';
import inscricao from './inscricao.reducer';
import profissoes from './profissoes.reducer';
import empreendimentos from './empreendimentos.reducer';
import distritos from './distritos.reducer';
import dependentes from './dependentes.reducer';
import moradia from './moradia.reducer';
import imoveis from './imoveis.reducer';
import cidades from './cidades.reducer';
import cidadesDoBrasil from './cidadesDoBrasil.reducer';
import programasSociais from './programasSociais.reducer';
import requisicoesAjax from './requisicoesAjax.reducer';
import informacoesDaFamilia from './informacoesDaFamilia.reducer';
import ehNovaInscricao from './ehNovaInscricao.reducer';
import informacoesDeLocalDeAtendimento from './informacoesDeLocalDeAtendimento.reducer';
import usuario from './usuario.reducer';
import ehAcessoAdministrativo from './acessoAdministrativo.reducer';
import menu from './menu.reducer';
import { LOG_OUT } from '../actions/actionTypes';
import grauDeInstrucoes from './grauDeInstrucoes.reducer';
import regimesDeCasamento from './regimeDeCasamento.reducer';
import questoesDoFormulario from './questoesDoFormulario.reducer';
import etnias from './etnias.reducer';
import ehFormularioSomenteLeitura from './ehFormularioSomenteLeitura.reducer';
import deveCarregarInscricaoNaMemoria  from './deveCarregarInscricaoNaMemoria.reducer';
import demandasEspecificas from './demandasEspecificas.reducer';
import tiposDeDeficiencia from './tiposDeDeficiencia.reducer';

const appReducer = history => combineReducers({
  inscricao,
  login,
  dadosTitular,
  dadosConjuge,
  profissoes,
  distritos,
  dependentes,
  moradia,
  imoveis,
  requisicoesAjax,
  cidades,
  menu,
  informacoesDaFamilia,
  programasSociais,
  ehNovaInscricao,
  empreendimentos,
  informacoesDeLocalDeAtendimento,
  usuario,
  ehAcessoAdministrativo,
  grauDeInstrucoes,
  regimesDeCasamento,
  etnias,
  questoesDoFormulario,
  ehFormularioSomenteLeitura,
  deveCarregarInscricaoNaMemoria,
  cidadesDoBrasil,
  demandasEspecificas,
  tiposDeDeficiencia,
  router: connectRouter(history)
});

const rootReducer = (state, action, history) => {
  if(action.type === LOG_OUT) state = undefined;

  return appReducer(history)(state, action);
};

export default rootReducer;