import IEstadoCivil from '../../../../interfaces/IEstadoCivil';

const estadoCivis: IEstadoCivil[] =[
  { value: 'casado', label: 'Casado' },
  { value: 'divorciado', label: 'Divorciado' },
  { value: 'solteiro', label: 'Solteiro' },
  { value: 'separadojudicialmente', label: 'Separado Judicialmente' },
  { value: 'viuvo', label: 'Viúvo' }
];

export default estadoCivis;