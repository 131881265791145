import { CARREGAR_PROFISSOES_SUCESSO } from './actionTypes';
import { apiDaInscricao } from '../servicos/axios';

export const carregarProfissoesSucesso = profissoes => {
  return {
    type: CARREGAR_PROFISSOES_SUCESSO,
    profissoes
  };
};

export const carregarProfissoes = () =>
  async dispatch => {
    const { data: profissoes } = await apiDaInscricao().get('/profissao/ObterTodas');

    dispatch(carregarProfissoesSucesso(profissoes));
  };