import { CPF } from 'cpf_cnpj';

const CAMPO_OBRIGATORIO = 'Campo obrigatório';

const obrigatorio = valor => {
  if (!valor)
    return CAMPO_OBRIGATORIO;
};

const validarCpf = cpf => {
  if (!CPF.isValid(cpf))
    return 'CPF inválido';
};

const validarRequisitosDaSenha = (senha) => {
  const quantidadeMinimaDeCaracteres = 6;
  const quantidadeMaximaDeCaracteres = 16;

  if (senha.length > quantidadeMaximaDeCaracteres)
    return 'A senha não pode conter mais de 16 caracteres';

  if (senha.length < quantidadeMinimaDeCaracteres || !contemNumero(senha) || !contemCaractere(senha))
    return 'A senha não atende as exigências mínimas';
};

function validarConfirmacaoDeSenha() {
  const { senha, confirmacaoDeSenha } = this;

  if (senha !== confirmacaoDeSenha)
    return 'As senhas não são iguais';
}

const contemNumero = (palavra) => {
  return /\d/.test(palavra);
};

const contemCaractere = (palavra) => {
  return /[a-z]/i.test(palavra);
};

const validacoes = {
  cpf: [obrigatorio, validarCpf],
  cidade: [obrigatorio],
  senha: [obrigatorio, validarRequisitosDaSenha],
  confirmacaoDeSenha: [obrigatorio, validarConfirmacaoDeSenha],
};

const aplicarFuncoesDeValidacao = (contexto, campo, valor) => {
  const funcoes = validacoes[campo];
  const retornosComErro = funcoes.map(f => f.call(contexto, valor)).filter(r => !!r);

  return retornosComErro[0];
};

const validarCampo = (dadosDoCadastro, campo, valor) =>
  aplicarFuncoesDeValidacao(dadosDoCadastro, campo, valor);

const validarFormulario = dadosDoCadastro => {
  const erros = {};

  Object.keys(validacoes).map(campo => {
    const erro = aplicarFuncoesDeValidacao(dadosDoCadastro, campo, dadosDoCadastro[campo]);

    if (erro)
      erros[campo] = erro;
  });

  return erros;
};

export default { validarCampo, validarFormulario };