import IActionType from '../interfaces/IActionType';
import initialState from './initialState.reducer';
import * as actionTypes from '../actions/actionTypes';

export default (state = initialState.ehFormularioSomenteLeitura, action: IActionType<boolean>) => {
  switch (action.type) {
  case actionTypes.ALTERAR_EH_FORMULARIO_SOMENTE_LEITURA:
    return action.state;
  default:
    return state;
  }
};