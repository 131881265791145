
export const detectarLetrasMaiusculas = (event, notificarCapsLock) => {
  event = event ? event : window.event;

  const { keyCode, which, shiftKey } = event;

  if(/\d/.test(event.key)) return;

  var codigoDoCaracter = keyCode ? keyCode : which;
  var ehCaracterMaiusculo = codigoDoCaracter >= 65 && codigoDoCaracter <= 90;
  var ehCaracterMinusculo = codigoDoCaracter >= 97 && codigoDoCaracter <= 122;
  var shiftEstaPressionado = shiftKey ? shiftKey : codigoDoCaracter == 16;

  if ((ehCaracterMaiusculo && !shiftEstaPressionado) || (ehCaracterMinusculo && shiftEstaPressionado)) {
    notificarCapsLock(true);
  }else{
    notificarCapsLock(false);
  }
};

export const detectarCapsLock = (event, notificarCapsLock) => {
  event = event ? event : window.event;

  var capsLockEstaAtivo = event.getModifierState && event.getModifierState('CapsLock');

  notificarCapsLock(capsLockEstaAtivo);
};