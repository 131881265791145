import React from 'react';
import { CampoCheckbox } from '..';
import opcoesDaAreasDeRisco from './opcoesDaAreasDeRisco';

interface IAreasDeRiscoProps {
  areasDeRisco: string[],
  atualizarAreasDeRisco: (areasDeRisco: string[]) => void,
  disabled: boolean
}

interface IEventOnChange {
  target: { name: string; value: string };
}

const AreasDeRisco = ({ areasDeRisco, atualizarAreasDeRisco, disabled }: IAreasDeRiscoProps) => {

  const onChangeCheckbox = (event: IEventOnChange) => {
    const { name } = event.target;
    const estaSelecionado = event.target.value === 'true';
    let areas: string[];
    
    if (estaSelecionado)
      areas = areasDeRisco.concat(name);
    else
      areas = areasDeRisco.filter((areaDeRisco) => areaDeRisco != name);

    atualizarAreasDeRisco(areas);
  };

  return (
    <div>
      <div className="grade__coluna">
        <label className='formulario__label'>
          Informe o(s) tipo(s) de área de risco que se aplica(m) à moradia:
        </label>
      </div>
      <div>
        {opcoesDaAreasDeRisco.map((opcao) =>
          (<div className="grade__coluna" key={opcao.name}>
            <CampoCheckbox
              name={opcao.name}
              label={opcao.label}
              checked={areasDeRisco.includes(opcao.name)}
              onChange={onChangeCheckbox}
              disabled={disabled}/>
          </div>))}
      </div>
    </div>
  );
};

export default AreasDeRisco;