import { CARREGAR_ETNIA_SUCESSO } from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IEtnia from '../interfaces/IEtnia';

export default (state = initialState.etnias, action: IActionType<IEtnia[]>) => {
  switch (action.type) {
  case CARREGAR_ETNIA_SUCESSO:
    return [...action.state];
  default:
    return state;
  }
};