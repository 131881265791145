import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RotaPublicaAutenticada } from './rotaPublicaAutenticada';
import { RotaAdministrativaAutenticada } from './rotaAdministrativaAutenticada';
import {
  DadosTitular, DadosConjuge, Endereco,  Imoveis, CadastroDeImovel, Dependentes,
  CadastroDeDependente, InformacoesDaFamilia, ProgramasSociais,  MenuDeInscricao,
  ResumoDaInscricao, Comprovante
} from '../telas';
import {validarAcessoAdministrativo} from './autenticacao';

export const RotaDinamica = ({ component: Component, ...rest }) => {
  return (
    <Route {...rest} render={(props) => {
      if (props.location.pathname == '/menu-inscricao')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/menu-inscricao' component={MenuDeInscricao} />: <RotaPublicaAutenticada path='/menu-inscricao' component={MenuDeInscricao} />; 
      
      if (props.location.pathname == '/dados-titular')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/dados-titular' component={DadosTitular} /> : <RotaPublicaAutenticada path='/dados-titular' component={DadosTitular} />; 

      if (props.location.pathname == '/dados-conjuge')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/dados-conjuge' component={DadosConjuge} />: <RotaPublicaAutenticada path='/dados-conjuge' component={DadosConjuge} />; 

      if (props.location.pathname == '/endereco')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/endereco' component={Endereco} />: <RotaPublicaAutenticada path='/endereco' component={Endereco} />; 

      if (props.location.pathname == '/imoveis')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/imoveis' component={Imoveis} />: <RotaPublicaAutenticada path='/imoveis' component={Imoveis} />; 

      if (props.location.pathname == '/novo-imovel')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/novo-imovel' component={CadastroDeImovel} />: <RotaPublicaAutenticada path='/novo-imovel' component={CadastroDeImovel} />; 

      if (props.location.pathname.includes('/editar-imovel/'))
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/editar-imovel/:id' component={CadastroDeImovel} />: <RotaPublicaAutenticada path='/editar-imovel/:id' component={CadastroDeImovel} />; 

      if (props.location.pathname == '/dependentes')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/dependentes' component={Dependentes} />: <RotaPublicaAutenticada path='/dependentes' component={Dependentes} />; 

      if (props.location.pathname == '/novo-dependente')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/novo-dependente' component={CadastroDeDependente} />: <RotaPublicaAutenticada path='/novo-dependente' component={CadastroDeDependente} />; 

      if (props.location.pathname.includes('/editar-dependente/'))
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/editar-dependente/:id' component={CadastroDeDependente} />: <RotaPublicaAutenticada path='/editar-dependente/:id' component={CadastroDeDependente} />; 

      if (props.location.pathname == '/programas-sociais')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/programas-sociais' component={ProgramasSociais} />: <RotaPublicaAutenticada path='/programas-sociais' component={ProgramasSociais} />; 

      if (props.location.pathname == '/programas-habitacionais')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/programas-habitacionais' component={InformacoesDaFamilia} />: <RotaPublicaAutenticada path='/programas-habitacionais' component={InformacoesDaFamilia} />; 

      if (props.location.pathname == '/resumo-da-inscricao')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/resumo-da-inscricao' component={ResumoDaInscricao} />: <RotaPublicaAutenticada path='/resumo-da-inscricao' component={ResumoDaInscricao} />; 

      if (props.location.pathname == '/comprovante')
        return validarAcessoAdministrativo()  ? <RotaAdministrativaAutenticada path='/comprovante' component={Comprovante} />: <RotaPublicaAutenticada path='/comprovante' component={Comprovante} />; 
    }}
    />
  );
};

RotaDinamica.propTypes = {
  component:PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  location:PropTypes.string
};
