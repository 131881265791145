interface IOpcaoDaInfraestrutura {
  name: string,
  label: string
}

const opcoesDaInfraestrutura: IOpcaoDaInfraestrutura[] = [
  { name: 'EnergiaEletrica', label: 'Tem luz elétrica na moradia' },
  { name: 'RuaAsfaltada', label: 'A rua da casa é asfaltada' },
  { name: 'IluminacaoPublica', label: 'Tem iluminação pública na rua' },
  { name: 'AguaEncanada', label: 'A água da casa é encanada' },
  { name: 'ColetaDeLixo', label: 'O serviço de coleta de lixo passa na rua' }
];

export default opcoesDaInfraestrutura;