import React from 'react';

interface BarraDeNavegacaoProps {
    titulo: string,
    subTitulo?: string,
    comBotaoVoltar?: boolean,
    acaoBotaoVoltar?: () => void,
    comBotaoADireita?: boolean,
    textoBotaoADireita?: string,
    iconeBotaoADireita?: boolean
    acaoBotaoADireita?: () => void
}

const BarraDeNavegacao = ({titulo, subTitulo, comBotaoVoltar, acaoBotaoVoltar, comBotaoADireita, textoBotaoADireita, iconeBotaoADireita, acaoBotaoADireita} : BarraDeNavegacaoProps) => {
  return (
    <div className="barra-navegacao barra-navegacao_fixa">
      <div className="barra-navegacao__container">
        {comBotaoVoltar &&
        <a
          href="#"
          data-test-id="botao-voltar"
          className="botao botao_icone botao_sem-preenchimento botao_medio botao_cor-terciaria"
          onClick={acaoBotaoVoltar}
          aria-label="Voltar para página anterior">
          <i className="fal fa-arrow-left" aria-hidden="true"></i>
        </a>
        }
        <div className="barra-navegacao__conteudo-principal">
          <div className="barra-navegacao__conteudo-primario">
            <div className="barra-navegacao__container-titulos">
              <h1 className="barra-navegacao__titulo">{titulo}</h1>
              <p className="barra-navegacao__subtitulo">{subTitulo}</p>
            </div>
          </div>
          {comBotaoADireita && (
            <div className="barra-navegacao__conteudo-secundario">
              <a href="#" 
                data-test-id="botao-barra-de-navegacao-a-direita"
                className="botao botao_com-icone-para-esquerda botao_cor-terciaria" 
                onClick={acaoBotaoADireita}>
                {iconeBotaoADireita && (<i className="far fa-regular fa-plus" aria-hidden="true"></i>)} {textoBotaoADireita}
              </a>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default BarraDeNavegacao;