import IFormularioDeEnderecoValidacaoCampos, { IFormularioDeEnderecoValidacaoErroCampos } from '../../interfaces/IFormularioDeEnderecoValidacao';

let _cep: string | undefined;
let _logradouro: string | undefined;
let _bairro: string | undefined;
let _numero: string | undefined;
let _cidade: number | undefined;
let _moraEmDistrito: boolean | undefined;
let _distrito: number | undefined;

const cepObrigatorio = () => {
  if (_cep === undefined || _cep == '')
    return true;
};

const logradouroObrigatorio = () => {
  if (_logradouro == undefined || _logradouro == '')
    return true;
};

const numeroLograuroObrigatorio = () => {
  if (_numero == undefined || _numero == '')
    return true;
};

const bairroObrigatorio = () => {
  if (_bairro == undefined || _bairro == '')
    return true;
};

const cidadeObrigatorio = () => {
  if (_cidade == undefined)
    return true;
};

const moraEmDistritoObrigatorio = () => {
  if (_moraEmDistrito == undefined)
    return true;
};

const distritoObrigatorio = () => {
  if (_moraEmDistrito && _distrito == undefined)
    return true;
};

const validacoes: IFormularioDeEnderecoValidacaoCampos = {
  cep: [cepObrigatorio],
  logradouro: [logradouroObrigatorio],
  numero: [numeroLograuroObrigatorio],
  bairro: [bairroObrigatorio],
  cidade: [cidadeObrigatorio],
  moraEmDistrito: [moraEmDistritoObrigatorio],
  distrito: [distritoObrigatorio]
};

const aplicarFuncoesDeValidacao = (campo: string, cep: string, logradouro: string, numero: string, bairro: string, cidade: number, moraEmDistrito: boolean, distrito: number) => {

  //@ts-ignore
  const funcoes = validacoes[campo] || [];
  _cep = cep;
  _logradouro = logradouro;
  _numero = numero;
  _bairro = bairro;
  _cidade = cidade;
  _moraEmDistrito = moraEmDistrito;
  _distrito = distrito;

  const retornosComErro = funcoes.map((f: Function) => f.call(campo)).filter((r: any) => !!r);

  return retornosComErro[0];
};

const validarFormulario = (cep: string, logradouro: string, numero: string, bairro: string, cidade: number, moraEmDistrito: boolean, distrito: number): IFormularioDeEnderecoValidacaoErroCampos => {

  const errosValidacao: IFormularioDeEnderecoValidacaoErroCampos = Object.keys(validacoes).reduce((erros, campo) => {
    //@ts-ignore
    const erro = aplicarFuncoesDeValidacao(campo, cep, logradouro, numero, bairro, cidade, moraEmDistrito, distrito);
    if (erro)
      return { ...erros, [campo]: erro };

    return erros;
  }, {});

  return errosValidacao;
};

export default { validarCampo: aplicarFuncoesDeValidacao, validarFormulario };