import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoff } from '../../../servicos/autenticacao';
import { BotTrap, CampoDeTexto, CampoCidade, LayoutDaPaginaDeEntrada } from '../../../componentes';
import { efetuarLogin, logOut } from '../../../actions/login.actions';
import validacao from './validacao';
import '../../../estilos/estilos.scss';
import { BotaoComTexto } from '@bit/digix.digixui.botao';
import { LinkComIcone } from '@bit/digix.digixui.link';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { verificarSeAceitouOsTermosDeUso, redirecionarParaAceitacaoDeTermosDeUso } from '../../../servicos/termoDeUso';
import PropTypes from 'prop-types';
import CampoDeTextoComMascara from '../../../componentes/CampoDeTextoComMascara';
import {mapearCidadesParaSelect} from '../../../selectors';
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      erros: {},
      dadosLogin: {}
    };
  }

  componentDidMount = () => {
    logoff();
    document.querySelector('#foco-inicial-do-teclado').focus();
  };

  onBotTrapRef = input => {
    this.botTrapInput = input;
  };

  onBlur = event => {
    const { name, value } = event.target;
    const { erros, dadosLogin } = this.state;
    const erro = validacao.validarCampo(dadosLogin, name, value);
    if (erro)
      this.setState({
        erros: { ...erros, [name]: erro }
      });
  };

  onFocus = event => this.removerErro(event.target.name);

  removerErro = campo => {
    const erros = Object.assign({}, this.state.erros);
    delete erros[campo];
    this.setState({ erros });
  };

  onChange = event => {
    const { name, value } = event.target;
    this.setState({
      dadosLogin: {
        ...this.state.dadosLogin,
        [name]: value
      }
    });
    this.removerErro(name);
  };

  atualizarCidade = codigoDaCidade => {
    this.setState({
      dadosLogin: {
        ...this.state.dadosLogin,
        cidade: codigoDaCidade
      }
    });
    this.removerErro('cidade');
  };

  validarFormulario = () => {
    const dadosLogin = this.state.dadosLogin;
    const erros = validacao.validarFormulario(dadosLogin);

    this.setState({ erros });

    return !Object.keys(erros).length;
  };

  efetuarLogin = async (event) => {
    event.preventDefault();
    
    const { dadosLogin } = this.state;

    if (this.botTrapInput.value)
      document.location.replace('https://www.google.com.br/');

    if (!this.validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor informe os campos obrigatórios.'
      });
      return;
    }

    try {    
      const aceitouOsTermosDeUso = await this.props.verificarSeAceitouOsTermosDeUso(dadosLogin.cpf);
      if(aceitouOsTermosDeUso) {
        await this.props.efetuarLogin(dadosLogin);
        return;
      }
        
      await this.props.redirecionarParaAceitacaoDeTermosDeUso(dadosLogin);   
    } catch (e) {
      if(!e.response || !e.response.data){
        const mensagemErro = 'Não foi possível prosseguir com sua incrição. Tente novamente. Caso o problema persista, entre em contato com o suporte.';
        
        this.setState({ mensagemErro });
        return;
      }
    }
  };

  limparMensagemDeErro = () => {
    this.setState({
      mensagemErro: false
    });
  };

  render() {
    const inputEvents = {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };
    const {
      dadosLogin: { 
        cpf = '' 
      },
      erros, mensagemErro 
    } = this.state;

    const { history } = this.props;
    return (
      <LayoutDaPaginaDeEntrada 
        mensagemDeErro={mensagemErro}
        limparMensagemDeErro={this.limparMensagemDeErro}>
        <div className="grade">
          <div className="grade__coluna">
            <h2 className="titulo titulo_grande texto_cor-terciaria u-margem-inferior-pequena">Faça sua atualização cadastral aqui!</h2>
            <p id="foco-inicial-do-teclado" tabIndex="-1" className="texto">Informe os dados abaixo para acessar sua inscrição:</p>
          </div>

          <form onSubmit={this.efetuarLogin}
            className="formulario formulario_medio u-margem-inferior-media">
            <CampoCidade
              label="Cidade"
              name="cidade"
              placeholder="Selecione sua cidade"
              erro={erros.cidade}
              onChange={this.atualizarCidade}
              cidades={this.props.cidades} />
            <CampoDeTextoComMascara
              label="Seu CPF"
              mascara="999.999.999-99"
              name="cpf"
              value={cpf}
              tipo="text"
              erro={erros.cpf}
              {...inputEvents} />
            <CampoDeTexto
              label="Senha de acesso"
              name="senha"
              tipo="password" 
              erro={erros.senha}
              {...inputEvents}/>
            <LinkComIcone
              href="/redefinir-senha"
              onClick={() => history.push('/redefinir-senha', {esqueceuASenha: true})}
              cor="info"
              icone="far fa-long-arrow-right"
              posicaoDoIcone="direita"
              className="u-margem-esquerda-pequena u-texto-negrito">Esqueci minha senha </LinkComIcone>
            <BotTrap
              onRef={this.onBotTrapRef} />
            <div className="grade__linha u-margem-superior-media">
              <div className="grade__coluna">
                <BotaoComTexto
                  cor="terciaria"
                  classesAuxiliares="botao_largura-total">
                  Entrar
                </BotaoComTexto>
              </div>
            </div>
          </form>
          
          <div className="grade__linha">
            <div className="grade__coluna">
              <BotaoComTexto
                onClick={() => history.push('/redefinir-senha')}
                cor='terciaria'
                visual='contorno'
                classesAuxiliares="botao_largura-total">
                Não tenho senha
              </BotaoComTexto>
            </div>
          </div>
          
          <div className="pagina-de-entrada__links-auxiliares">
            <LinkComIcone
              href="/cadastro"
              onClick={() => history.push('cadastro')}
              cor="info"
              icone="far fa-long-arrow-left"
              posicaoDoIcone="esquerda"
              className="u-texto-negrito"> Não tem inscrição? Criar</LinkComIcone>
          </div>
        </div>
      </LayoutDaPaginaDeEntrada>
    );
  }
}

Login.propTypes = {
  cidades: PropTypes.array,
  efetuarLogin: PropTypes.func
};

const mapStateToProps = ({cidades}) => ({
  cidades: mapearCidadesParaSelect(cidades)
});

const mapDispatchToProps = dispatch => ({
  efetuarLogin: dadosLogin => dispatch(efetuarLogin(dadosLogin)),
  logOut: () => dispatch(logOut()),
  verificarSeAceitouOsTermosDeUso: (cpfDoTitular) => verificarSeAceitouOsTermosDeUso(cpfDoTitular, dispatch),
  redirecionarParaAceitacaoDeTermosDeUso: (dadosDeLogin) => redirecionarParaAceitacaoDeTermosDeUso(dadosDeLogin, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);