import React from 'react';
import CampoDeTexto from '../../../../../CampoDeTexto';
import { IDocumento } from '../../../../../../interfaces/IDocumento';
import ICedulaDeIdentidadeDoEstrangeiro from '../../../../../../interfaces/ICedulaDeIdentidadeDoEstrangeiro';
import { IPretendenteValidacaoErroCampos } from '../../../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../../../CampoDeTextoComMascara';

interface IInputEventsProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICampoCieProps {
  documentos: IDocumento,
  erros?: IPretendenteValidacaoErroCampos,
  inputEvents: IInputEventsProps,
  disabled: boolean,
  onChangeDocumento: (documentos: IDocumento) => void
}

const CampoCie = ({ documentos, erros, inputEvents, disabled, onChangeDocumento }: ICampoCieProps) => {

  const handleOnChangeDocumento = (documentos: IDocumento, event: React.ChangeEvent<HTMLInputElement>, nomePropriedade: string) => {
    if (documentos.cedulaDeIdentidadeDoEstrangeiro) {
      const cedula: ICedulaDeIdentidadeDoEstrangeiro = documentos.cedulaDeIdentidadeDoEstrangeiro;
      onChangeDocumento({ ...documentos, cedulaDeIdentidadeDoEstrangeiro: { ...cedula, [nomePropriedade]: event.target.value } });
    }
  };

  return (<div>
    <div className="grade__linha">
      <CampoDeTextoComMascara
        label="Número da cédula de identidade de estrangeiro (CIE)"
        name="numeroDaCie"
        value={documentos?.cedulaDeIdentidadeDoEstrangeiro?.numero || ''}
        tipo="text"
        mascara="********************"
        erro={erros?.numeroDaCie}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'numero'); }} />
    </div>

    <div className="grade__linha grade__linha_alinhar-vertical-a-base">
      <CampoDeTextoComMascara
        label="Data de expedição"
        name="dataDeExpedicaoDaCie"
        mascara="99/99/9999"
        value={documentos.cedulaDeIdentidadeDoEstrangeiro?.dataDeExpedicao?.toString() || ''}
        tipo="text"
        erro={erros?.dataDeExpedicaoDaCie}
        disabled={disabled}
        {...inputEvents}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'dataDeExpedicao'); }} />

      <CampoDeTexto
        label="Órgão emissor"
        value="CGPI/DIREX/DPF"
        disabled
        name="orgaoEmissor" />
    </div>
  </div>);
};

export default CampoCie;