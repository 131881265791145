import { CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO}  from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import ITipoDeDeficiencia from '../interfaces/ITipoDeDeficiencia';

export default (state = initialState.tiposDeDeficiencia, action: IActionType<ITipoDeDeficiencia[]>) => {
  switch (action.type) {
  case CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO:
    return [...action.state];
  default:
    return state;
  }
};