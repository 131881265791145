import * as actionTypes from './actionTypes';
import IProgramasSociais from '../interfaces/IProgramasSociais';

export const salvarProgramasSociaisSucesso = (programasSociais: IProgramasSociais) => ({
  type: actionTypes.SALVAR_PROGRAMAS_SOCIAIS_SUCESSO,
  state: programasSociais
});

export const carregarProgramasSociais = (programasSociais: IProgramasSociais) => ({
  type: actionTypes.CARREGAR_PROGRAMAS_SOCIAIS,
  state: programasSociais
});