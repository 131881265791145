import { CARREGAR_ID_INSCRICAO, CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO } from '../actions/actionTypes';
import initialState from './initialState.reducer';

export default (state = initialState.inscricao, action) => {
  switch (action.type) {
  case CARREGAR_ID_INSCRICAO:
    return { id: action.id };
  case CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO:
    return {
      id: action.inscricao.id,
      numero: action.inscricao.numero,
    };
  default:
    return state;
  }
};