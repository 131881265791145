import IDependente from '../interfaces/IDependente';
import * as actionTypes from './actionTypes';

export const carregarDependentesSucesso = (dependentes: IDependente[]) => ({
  type: actionTypes.CARREGAR_DEPENDENTES_SUCESSO,
  state: dependentes
});

export const carregarDependentes = (dependentes: IDependente[]) => ({
  type: actionTypes.CARREGAR_DEPENDENTES,
  state: dependentes
});

export const excluirSucesso = (id: number) => ({
  type: actionTypes.EXCLUIR_DEPENDENTE_SUCESSO,
  state: id
});