import { apiDaInscricao } from './axios';

const consultarInscricao = async (inscricaoId: Number) => {
  return await apiDaInscricao().get(`/menudeinscricao/${inscricaoId}`);
};

const consultarInformacoesDoMenu = async (inscricaoId: Number) => {
  return await apiDaInscricao().get(`/menu/${inscricaoId}/informacoes`);
};

const obterDadosGeraisDaInscricao = async (cpfDoTitular: string, ehAcessoAdministrativo: boolean ) => {

  let dadosGerais;
  if (ehAcessoAdministrativo)
    dadosGerais = await apiDaInscricao().get(`/menudeinscricao/${cpfDoTitular}/dadosGerais`);
  else
    dadosGerais = await apiDaInscricao().get(`/inscricao/${cpfDoTitular}/dadosGerais`);

  return dadosGerais;
};

export { consultarInscricao, consultarInformacoesDoMenu, obterDadosGeraisDaInscricao };