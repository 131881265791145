import React from 'react';
import logosDoSistemaPrimario from '../Logos/logo-governo-e-agehab.svg';
import logoDaDigix from '../Logos/logo-digix-laranja.svg';
import LogosDoSistemaMobile from './svg';

interface LayoutDaPaginaDeEntradaProps {
  children: React.ReactNode,
  mensagemDeErro: string,
  limparMensagemDeErro: () => void
}

const LayoutDaPaginaDeEntrada = ({ children, mensagemDeErro, limparMensagemDeErro }: LayoutDaPaginaDeEntradaProps) => (
  <div className="pagina-de-entrada">
    {mensagemDeErro &&
    <div onClick={limparMensagemDeErro} className="pagina-de-entrada__mensagem-erro">
      {mensagemDeErro}
    </div>
    }

    <div className="pagina-de-entrada__container pagina-de-entrada__container_mobile">
      <div className="pagina-de-entrada__conteudo-do-container">
        <LogosDoSistemaMobile classescss="logos-do-sistema u-margem-inferior-media" />
        <h1 className="titulo titulo_grande"><strong>Cadastro Compartilhado da AGEHAB/MS</strong></h1>
      </div>
    </div>

    <section className="pagina-de-entrada__container pagina-de-entrada__container_primario">
      <div className="pagina-de-entrada__conteudo-do-container">
        {/* TODO: Verificar logo */}
        <div className="logos-do-sistema u-margem-inferior-grande u-escondido-menor-que-desktop-pequeno">
          {/* <LogosDoSistemaPrimario  /> */}
          <img src={logosDoSistemaPrimario} className="App-logo" alt="logo" />
        </div>
        <div>
          <h1 className="titulo titulo_gigante u-margem-inferior-grande u-escondido-menor-que-desktop-pequeno">
            <strong>Cadastro Compartilhado da AGEHAB/MS</strong>
          </h1>
          <h2 className="u-margem-inferior-pequena">Dúvidas?</h2>
          <p className="texto_medio u-margem-inferior-grande">Serviço de Atendimento ao Público - SAP, pelo telefone <span className="u-texto-nao-quebrar"><strong>0800-647-3120</strong></span> <span className="u-texto-nao-quebrar">das <strong>8 às 13 horas</strong>.</span></p>
          <h2 className="u-margem-inferior-pequena u-margem-superior-media">Locais de Atendimento</h2>
          <p className="texto_medio"><strong>Campo Grande:</strong> Fácil Guaicurus, Fácil General Osório e Fácil Aero Rancho</p>
          <p className="texto_medio">Atendimento presencial das <strong>8:00 às 17:00 horas</strong>.</p>
          <p className="texto_medio u-margem-superior-media u-margem-inferior-media">
            <strong>Dourados: </strong>
            Atendimento pelo telefone
            <span className="u-texto-nao-quebrar">
              <a className="botao_sem-preenchimento botao_cor-branca" href="tel:6734117125">
                <strong> (67) 3411-7125 </strong>
              </a>
              das <strong>7:30 às 13:30 horas</strong>.
            </span>
          </p>
          <p className="texto_medio"><strong>Demais municípios:</strong> Atendimento na Prefeitura Municipal.</p>
        </div>

        <a href="http://www.agehab.ms.gov.br/agehab/atendimento/" target="_blank" rel="noopener noreferrer"
          className="botao botao_sem-altura botao_medio botao_cor-terciaria botao_invertido u-margem-superior-grande u-texto-alinhado-ao-centro">
          Encontre o seu Local de Atendimento</a>
      </div>

      <a href="https://www.digix.com.br/" target="_blank" rel="noopener noreferrer" className="pagina-de-entrada__logo-digix">
        <img src={logoDaDigix} className="App-logo" alt="logo" />
        {/* <logoDaDigix /> */}
      </a>
    </section>

    <div className="pagina-de-entrada__container pagina-de-entrada__container_secundario">
      <div className="pagina-de-entrada__conteudo-do-container">
        {children}
      </div>
    </div>
  </div>
);

export default LayoutDaPaginaDeEntrada;