import IPretendente from '../interfaces/IPretendente';
import { apiDaInscricao } from './axios';

export const salvarTitular = async (titular: IPretendente, idCidade: number, cpf: string) => {
  const body = { idCidade: idCidade, titular };
  return await apiDaInscricao().post(`/titulares/${cpf}`, body);
};

export const salvarTitularAcessoAdministrativo = async (titular: IPretendente, idCidade: number) => {
  const body = { idCidade: idCidade, titular };
  return await apiDaInscricao().post('/titulares/criarParaAcessoAdministrativo', body);
};

export const editarTitular = async (idDaInscricao: number, titular: IPretendente) => {
  await apiDaInscricao().put(`/titulares/${idDaInscricao}`, { titular });
};