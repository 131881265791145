import React, { useEffect, useState } from 'react';
import MensagemDeErro from '../../../MensagemDeErro';
//@ts-ignore
import Select from 'react-select';
import ITipoDeEmancipacao from '../../../../interfaces/ITipoDeEmancipacao';
import { mapearTipoDeEmancipacaoParaSelect } from '../../../../selectors/tiposDeEmancipacao';
import IEmancipacao from '../../../../interfaces/IEmancipacao';
import CampoDeTextoComMascara from '../../../CampoDeTextoComMascara';
import IPretendente from '../../../../interfaces/IPretendente';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';
import IInputEventsProps from '../../../../interfaces/IInputEventsProps';
import CampoDeTexto from '../../../CampoDeTexto';
import SelecaoDeEstado from '../../../SelecaoDeEstado';
import { carregarCidadesDoEstado } from '../../../../servicos/cidades';
import { useDispatch, useSelector } from 'react-redux';
import ICidade from '../../../../interfaces/ICidade';
import { mapearCidadesParaSelect } from '../../../../selectors';
import initialState from '../../../../reducers/initialState.reducer';
import { adicionarCidades } from '../../../../actions/cidadesDoBrasil.actions';
interface ICamposDeEmancipacaoProps {
  dadosPretendente: IPretendente,
  erros?: IPretendenteValidacaoErroCampos,
  inputEvents: IInputEventsProps,
  disabled: boolean,
  onChangeEmancipacao: (emancipacao: IEmancipacao, camposParaRemoverErro?: string[]) => void
}

const CamposDeEmancipacao = ({ dadosPretendente, inputEvents, onChangeEmancipacao, erros, disabled }: ICamposDeEmancipacaoProps) => {
  const tiposDeEmancipacao = [
    { id: 'EscrituraPublica', descricao: 'Escritura Pública' },
    { id: 'EmpregoPublicoEfetivo', descricao: 'Emprego Público Efetivo' },
    { id: 'ColacaoDeGrauDeEnsinoSuperior', descricao: 'Colação de Grau de Ensino Superior' },
    { id: 'EconomiaPropria', descricao: 'Economia Própria' },
    { id: 'Casamento', descricao: 'Casamento' }] as ITipoDeEmancipacao[];

  const TIPO_DE_EMANCIPACAO_ESCRITURA_PUBLICA = 'EscrituraPublica';
  const dispatch = useDispatch();
  const emancipacao = dadosPretendente.emancipacao;
  const exibirCamposDeEscrituraPublica = emancipacao?.tipo == TIPO_DE_EMANCIPACAO_ESCRITURA_PUBLICA;
  const habilitarCampoDeCidade = exibirCamposDeEscrituraPublica && emancipacao.ufDoEstadoDaCidadeDoCartorioDeRegistro;
  const cidadesDoBrasil = useSelector((state: typeof initialState) => state.cidadesDoBrasil) as ICidade[];
  const [cidadesDoEstado, setCidadesDoEstado] = useState<ICidade[]>([]);

  useEffect(() => {
    if (emancipacao?.ufDoEstadoDaCidadeDoCartorioDeRegistro)
      atualizarCidadesDoEstado(emancipacao.ufDoEstadoDaCidadeDoCartorioDeRegistro);
  }, []);

  const handleOnChangeTipoDeEmancipacao = (selected: { label: string, value: string }) => {
    if (selected) {
      const tipoDeEmancipacao = selected.value;
      const ehEscrituraPublica = tipoDeEmancipacao == TIPO_DE_EMANCIPACAO_ESCRITURA_PUBLICA;

      const emancipacaoAtual: IEmancipacao = ehEscrituraPublica ? { ...emancipacao, tipo: tipoDeEmancipacao } : { tipo: tipoDeEmancipacao };
      onChangeEmancipacao(emancipacaoAtual, ['tipoDeEmancipacao']);
    } else {
      const camposParaRemoverErros = ['dataDeEmancipacao', 'numeroDaEscritura', 'cartorio', 'livro', 'folha', 'estado', 'cidadeDoCartorioDeRegistro'];
      onChangeEmancipacao({} as IEmancipacao, camposParaRemoverErros);
    }
  };

  const handleOnChangeEstado = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value) {
      onChangeEmancipacao({ ...emancipacao, ufDoEstadoDaCidadeDoCartorioDeRegistro: value, cidadeDoCartorioDeRegistro: {} } as IEmancipacao, ['estado']);
      atualizarCidadesDoEstado(value);
      return;
    }
    onChangeEmancipacao({ ...emancipacao, ufDoEstadoDaCidadeDoCartorioDeRegistro: '', cidadeDoCartorioDeRegistro: {} } as IEmancipacao);// limpar campo de cidade
  };

  const atualizarCidadesDoEstado = async (uf: string) => {
    const cidadesDoEstadoSelecionado = cidadesDoBrasil?.filter(item => item?.estado?.uf === uf);
    if (cidadesDoEstadoSelecionado.length === 0) {
      const cidades = await buscarCidadesDoEstado(uf);
      setCidadesDoEstado(cidades);
      return;
    }
    setCidadesDoEstado(cidadesDoEstadoSelecionado);
  };

  const buscarCidadesDoEstado = async (uf: string): Promise<ICidade[]> => {
    const cidades = await carregarCidadesDoEstado(uf);
    dispatch(adicionarCidades(cidades));
    return cidades;
  };

  const handleOnChangeEmancipacao = (event: React.ChangeEvent<HTMLInputElement>, nomePropriedade: string) => {
    onChangeEmancipacao({ ...(emancipacao ?? {} as IEmancipacao), [nomePropriedade]: event.target.value });
  };

  const onChangeCidadeDoCartorioDeRegistro = (selected: { label: string, value: number }) => {
    const idDaCidade = selected?.value;
    const cidade = idDaCidade ? cidadesDoBrasil.find(cidade => cidade?.id == idDaCidade) : {};
    onChangeEmancipacao({ ...emancipacao, cidadeDoCartorioDeRegistro: cidade } as IEmancipacao, ['cidadeDoCartorioDeRegistro']);
  };

  return (
    <>
      <div className="grade__linha">
        <div className="grade__coluna">
          <h4 className="titulo titulo_pequeno texto_cor-secundaria">
            <strong>Emancipação</strong>
          </h4>
        </div>
      </div>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erros?.tipoDeEmancipacao && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Tipo de emancipação
            </label>
            {erros?.tipoDeEmancipacao && <MensagemDeErro campo='tipoDeEmancipacao' />}
            <Select
              name="tipoDeEmancipacao"
              id="tipoDeEmancipacao"
              placeholder="Selecione uma opção"
              value={emancipacao?.tipo}
              options={mapearTipoDeEmancipacaoParaSelect(tiposDeEmancipacao)}
              onChange={handleOnChangeTipoDeEmancipacao}
              disabled={disabled} />
          </div>
        </div>
        {exibirCamposDeEscrituraPublica &&
          <CampoDeTextoComMascara
            label="Data de emancipação"
            name="dataDeEmancipacao"
            mascara="99/99/9999"
            value={emancipacao?.data?.toString() || ''}
            pattern="\d*"
            erro={erros?.dataDeEmancipacao}
            disabled={disabled}
            {...inputEvents}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmancipacao(event, 'data')} />
        }
      </div>
      {exibirCamposDeEscrituraPublica && <>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoDeTexto
            label="Nº da escritura"
            name="numeroDaEscritura"
            tipo="text"
            value={emancipacao?.escritura || ''}
            maxLength={150}
            disabled={disabled}
            erro={erros?.numeroDaEscritura}
            {...inputEvents}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmancipacao(event, 'escritura')} />

          <CampoDeTexto
            label="Cartório"
            name="cartorio"
            tipo="text"
            value={emancipacao?.cartorio || ''}
            maxLength={150}
            disabled={disabled}
            erro={erros?.cartorio}
            {...inputEvents}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmancipacao(event, 'cartorio')} />
        </div>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <CampoDeTexto
            label="Livro"
            name="livro"
            tipo="text"
            value={emancipacao?.livro || ''}
            maxLength={150}
            disabled={disabled}
            erro={erros?.livro}
            {...inputEvents}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmancipacao(event, 'livro')} />

          <CampoDeTexto
            label="Folha"
            name="folha"
            tipo="text"
            value={emancipacao?.folha || ''}
            maxLength={150}
            disabled={disabled}
            erro={erros?.folha}
            {...inputEvents}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleOnChangeEmancipacao(event, 'folha')} />
        </div>
        <div className="grade__linha grade__linha_alinhar-vertical-a-base">
          <SelecaoDeEstado
            uf={emancipacao?.ufDoEstadoDaCidadeDoCartorioDeRegistro || ''}
            label='Estado'
            clearable={true}
            onChange={handleOnChangeEstado}
            erro={!!erros?.estado}
            disabled={disabled}
          />
          <div className="grade__coluna grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.cidadeDoCartorioDeRegistro && 'formulario__input-react-select_erro'}`}>
              <label className="formulario__label">
                Cidade
              </label>
              {erros?.cidadeDoCartorioDeRegistro && <MensagemDeErro campo='cidadeDoCartorioDeRegistro' />}
              <Select
                name="cidadeDoCartorioDeRegistro"
                id="cidadeDoCartorioDeRegistro"
                placeholder="Selecione a cidade"
                value={emancipacao?.cidadeDoCartorioDeRegistro?.id || ''}
                options={mapearCidadesParaSelect(cidadesDoEstado)}
                onChange={onChangeCidadeDoCartorioDeRegistro}
                disabled={!habilitarCampoDeCidade || disabled}
                clearable={true} />
            </div>
          </div>
        </div>
      </>
      }
    </>);
};

export default CamposDeEmancipacao;