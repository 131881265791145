import { apiDaInscricao } from './axios';
import { endAjaxCall } from '../actions/requisicoesAjax.actions';
import { manterSomenteNumeros } from '../helpers/mascaras';
 
const verificarSeAceitouOsTermosDeUso = async (cpfDoTitular, dispatch) => {
  const cpfSemMascara = manterSomenteNumeros(cpfDoTitular);
  const resposta = await apiDaInscricao().get(`/titulares/${cpfSemMascara}/aceitouTermosDeUso`);
  dispatch(endAjaxCall());
  return resposta.data;
};

const redirecionarParaAceitacaoDeTermosDeUso = async ({cpf, cidade, senha}, dispatch, pathAdicionalDeRetorno) => {
  const cpfSemMascara = manterSomenteNumeros(cpf);
  const payload = { idDaCidade: cidade, senha };
  const { data } = await apiDaInscricao().post(`/titulares/${cpfSemMascara}/urlParaAceitacaoDeTermosDeUso`, payload);
  dispatch(endAjaxCall());
  		
  let urlDeRedirecionamento = data;
  if(pathAdicionalDeRetorno){
    urlDeRedirecionamento = urlDeRedirecionamento.replace('/login', '');
    urlDeRedirecionamento = urlDeRedirecionamento + pathAdicionalDeRetorno;
  }
  window.location.href = urlDeRedirecionamento;
};

export { verificarSeAceitouOsTermosDeUso, redirecionarParaAceitacaoDeTermosDeUso };