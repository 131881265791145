
import React from 'react';
//@ts-ignore
import Select from 'react-select';

const estados = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE',
  'DF', 'ES', 'GO', 'MA', 'MG', 'MS',
  'MT', 'PA', 'PB', 'PE', 'PI', 'PR',
  'RJ', 'RN', 'RO', 'RR', 'RS', 'SC',
  'SE', 'SP', 'TO'];

interface ICampoEstadoProps {
  value: string,
  onChange: (selected: { label: string, value: string }) => void,
  erro?: string,
  disabled: boolean
}

const CampoEstado = ({ value, onChange, erro, disabled }: ICampoEstadoProps) => {

  return (
    <div className={`formulario__input-react-select ${erro && 'formulario__input-react-select_erro'}`}>
      <Select
        value={value}
        options={estados.map(e => ({ label: e, value: e }))}
        onChange={onChange}
        placeholder="Selecione"
        disabled={disabled} />
    </div>
  );
};

export default CampoEstado;