import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { checarAutenticacaoNoSeguranca, validarAcessoAdministrativo } from './autenticacao';
import PropTypes from 'prop-types';
import CabecalhoAdministrativo from '../componentes/CabecalhoAdministrativo';
import { salvarEhAcessoAdministrativo } from '../actions/acessoAdministrativo.actions';
import { carregarUsuarioLogadoSucesso } from '../actions/usuario.actions';

const urlSeguranca = process.env.REACT_APP_URL_SEGURANCA;
const returnUrl = process.env.REACT_APP_RETURN_URL;

const urlDeAutenticacao = `${urlSeguranca}/Seguranca/LogOffExterno`;

const redirecionarParaAutenticacao = () => {
  window.location.href = urlDeAutenticacao + returnUrl;
};

const localDeAtendimentoUsuario = (localAtendimentoRedux) => {  
  let localAtendimentoStorage = localStorage.getItem('localDeAtendimento');
  if (Object.keys(localAtendimentoRedux).length === 0 && localAtendimentoStorage != null) {
    return JSON.parse(localAtendimentoStorage);
  }
  return localAtendimentoRedux;
};

export const RotaAdministrativaAutenticada = ({ component: Component, ...rest }) => {
  const localAtendimentoRedux = useSelector(state => state.informacoesDeLocalDeAtendimento.localDeAtendimento);
  const usuarioLogado = useSelector(state => state.usuario);
  const localDeAtendimento = localDeAtendimentoUsuario(localAtendimentoRedux);
  const dispatch = useDispatch();

  const estaAcessandoLocalDeAtendimento = props => props.location.pathname.includes('locais-de-atendimento');

  const possuiLocalDeAtendimento = () => localDeAtendimento !== undefined && Object.keys(localDeAtendimento).length > 0;

  const existeUsuarioLogado = () => usuarioLogado !== undefined && Object.keys(usuarioLogado).length > 0;
  
  const obterLoginDoUsuario = () => { 
    return {login: localStorage.getItem('loginDoUsuario')};
  };

  const redirecionarParaLocalDeAtendimento = props => {
    let usuarioPossuiLocalDeAtendimento = possuiLocalDeAtendimento();
    let estaAcessandoPaginaDeLocalDeAtendimento = estaAcessandoLocalDeAtendimento(props);
    let deveRedirecionarParaLocalDeAtendimento = !usuarioPossuiLocalDeAtendimento && !estaAcessandoPaginaDeLocalDeAtendimento;
    return deveRedirecionarParaLocalDeAtendimento;
  };

  return (
    <Route {...rest} render={ (props) => {
      checarAutenticacaoNoSeguranca().then(() => {
        const estaAutenticado = validarAcessoAdministrativo();
        if (!estaAutenticado) {
          redirecionarParaAutenticacao();
          return;
        } else {
          dispatch(salvarEhAcessoAdministrativo(true));
          const usuarioEstaLogado = existeUsuarioLogado();
          if (!usuarioEstaLogado) {
            dispatch(carregarUsuarioLogadoSucesso(obterLoginDoUsuario()));
          }
        }

        const deveRedirecionarParaLocalDeAtendimento = redirecionarParaLocalDeAtendimento(props);
        if (deveRedirecionarParaLocalDeAtendimento) {
          dispatch(push('locais-de-atendimento'));
          return;
        }
      }).catch(error => {
        console.log(error);
      });
      return (
        <CabecalhoAdministrativo> <Component {...props} /></CabecalhoAdministrativo>
      );
    }}
    />
  );
};

RotaAdministrativaAutenticada.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ]),
  location: PropTypes.string
};