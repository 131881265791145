import React, { ReactNode } from 'react';
import '../../estilos/estilos.scss';

interface ISectionProps {
    children?: ReactNode,
    comMargemSuperiorMedia?: boolean
}

const Section = ({ children, comMargemSuperiorMedia = true }: ISectionProps) => (
  <section className={`section container 
        container_com-fundo container_sem-margem
        u-padding-superior-media u-padding-inferior-media
        ${comMargemSuperiorMedia ? 'u-margem-superior-media' : ''}`}>
    {children}
  </section>
);

export default Section;