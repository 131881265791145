import React from 'react';
//@ts-ignore
import InputMask from 'react-input-mask';
import MensagemDeErro from '../MensagemDeErro';

interface CampoDeTextoComMascaraProps {
    name: string,
    mascara: string,
    value: string,
    label?: string,
    disabled?: boolean,
    placeholder?:string,
    required?: boolean,
    colunas?: string,
    link?: string,
    descricaoDoLink?: string,
    descricao?: string,
    children?: React.ReactNode,
    erro?: string,
    tipo?: string,
    autoFocus?: boolean,
    pattern?:string,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const CampoDeTextoComMascara = ({ name, colunas = '', label, link, descricaoDoLink,
  descricao, children, erro, placeholder, required = true, disabled = false,
  mascara, value, tipo = 'text', autoFocus = false, pattern, 
  onChange, onBlur, onFocus,onKeyDown, onKeyPress }: CampoDeTextoComMascaraProps) => {
  return (
    <div className={`grade__coluna ${colunas ? `grade__coluna_${colunas}` : 'u-largura-total'}`}>
      <label className="formulario__label" htmlFor={name}>
        {label}
      </label>

      {link &&
      <a target="_blank" rel="noopener noreferrer" className="link link_cor-info link_medio" href={link}>{descricaoDoLink}</a>
      }
      {(descricao || children) &&
      <div className="formulario__descricao">
        {descricao || children}
      </div>}
      {erro &&
      <MensagemDeErro campo={name} mensagem={erro ? erro : 'Campo obrigatório'} />
      }
      <div>
        <InputMask
          id={name}
          mask={mascara}
          maskChar=""
          name={name}
          value={value}
          type={tipo}
          pattern={pattern}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          disabled={disabled}
          className={`formulario__input ${erro ? 'formulario__input_erro' : ''}`}
          aria-required={required}
          aria-invalid={!!erro} />
      </div>
    </div>
  );
};
export default CampoDeTextoComMascara;