import {
  SELECIONOU_LOCAL_DE_ATENDIMENTO,
  LIMPOU_LOCAL_DE_ATENDIMENTO,
  PODE_ALTERAR_LOCAL_DE_ATENDIMENTO,
} from './actionTypes';
import {apiDaInscricao} from '../servicos/axios';

export const selecionarLocalDeAtendimento = localDeAtendimento => ({
  type: SELECIONOU_LOCAL_DE_ATENDIMENTO,
  localDeAtendimento,
});

export const limparLocalDeAtendimento = () => ({
  type: LIMPOU_LOCAL_DE_ATENDIMENTO
});

export const permitirAlterarLocalDeAtendimento = podeAlterarLocalDeAtendimento => ({ 
  type: PODE_ALTERAR_LOCAL_DE_ATENDIMENTO,
  podeAlterarLocalDeAtendimento 
});

export const obterLocaisDeAtendimentoDoUsuario = async (login) => {
  const {data: locaisDeAtendimento} = await apiDaInscricao().get(`/usuario/locaisDeAtendimento?login=${login}`);
  return locaisDeAtendimento;
};