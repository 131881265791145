import * as actionTypes from '../actions/actionTypes';
import IActionType from '../interfaces/IActionType';
import IInformacoesDaFamilia from '../interfaces/IInformacoesDaFamilia';
import initialState from './initialState.reducer';

export default function informacoesDaFamilia(state = initialState.informacoesDaFamilia, action: IActionType<IInformacoesDaFamilia>) {
  switch (action.type) {
  case actionTypes.SALVAR_INFORMACOES_DA_FAMILIA_SUCESSO:
    return {
      ...action.state
    };
  case actionTypes.CARREGAR_INFORMACOES_DA_FAMILIA:
    return {
      ...action.state
    };
  default:
    return state;
  }
}