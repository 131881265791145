import React, { Component } from 'react';
import { CampoDeTexto, LayoutDaPaginaDeEntrada } from '../../../componentes';
import { BotaoComTexto } from '@bit/digix.digixui.botao';
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { connect } from 'react-redux';
import { endAjaxCall } from '../../../actions/requisicoesAjax.actions';
import { apiDaInscricao } from '../../../servicos/axios';
import { detectarLetrasMaiusculas, detectarCapsLock } from '../../../helpers/deteccaoDeEventos';

class DefinirSenha extends Component {

  constructor(props) {
    super(props);
    this.state = {
      senha: '',
      confirmacaoDeSenha: '',
      erros: [],
      capsLockEstaAtivo: false
    };
  }

  componentDidMount(){
    document.querySelector('#foco-inicial-do-teclado').focus();
  }

  contemNumero = (palavra) => {
    return /\d/.test(palavra);
  };

  contemCaractere = (palavra) => {
    return /[a-z]/i.test(palavra);
  };

  validarSenha = (senha) => {
    const quantidadeMinimaDeCaracteres = 6;
    const quantidadeMaximaDeCaracteres = 16;

    if (senha.length > quantidadeMaximaDeCaracteres)
      return 'A senha não pode conter mais de 16 caracteres';

    if (senha.length < quantidadeMinimaDeCaracteres || !this.contemNumero(senha) || !this.contemCaractere(senha))
      return 'A senha não atende as exigências mínimas';
  };

  validarConfirmacaoDeSenha(confirmacaoDeSenha) {
    const { senha } = this.state;

    if (senha !== confirmacaoDeSenha)
      return 'As senhas não são iguais';
  }

  onChange = evento => {
    const { name, value } = evento.target;

    this.setState({
      [name]: value
    });

    this.removerErro(name);
  };

  onBlur = evento => {
    const { name, value } = evento.target;
    const { erros } = this.state;
    const erro = name == 'senha' ? this.validarSenha(value) : this.validarConfirmacaoDeSenha(value);

    if (erro)
      this.setState({
        erros: { ...erros, [name]: erro }
      });
  };

  onFocus = evento => this.removerErro(evento.target.name);

  removerErro = campo => {
    const erros = { ...this.state.erros };
    delete erros[campo];
    this.setState({ erros });
  };

  validarFormulario = () => {
    const { senha, confirmacaoDeSenha } = this.state;
    const erroNaSenha = this.validarSenha(senha);
    const erroNaConfirmacaoDeSenha = this.validarConfirmacaoDeSenha(confirmacaoDeSenha);

    this.setState({
      erros: { senha: erroNaSenha, confirmacaoDeSenha: erroNaConfirmacaoDeSenha }
    });

    if (erroNaSenha || erroNaConfirmacaoDeSenha) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos em vermelho'
      });
      return false;
    }

    return true;
  };

  definirSenha = async (event) => {
    event.preventDefault();
    if (!this.validarFormulario()) return true;

    try {
      const { codigo, cpf } = this.props.location.state;
      await apiDaInscricao().post(`/inscricao/${cpf}/senhaDeAcessoDoTitular`,
        { codigoDeConfirmacao: codigo, senha: this.state.senha });
      this.props.endAjaxCall();
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        titulo: 'Senha salva com sucesso!',
        texto: 'Agora você pode acessar sua inscrição.'
      });
      this.props.history.replace('login', {});
    } catch (e) {
      if (!e.response || !e.response.data) {
        GerenciadorDeMensagem.criarMensagem({
          tipo: 'erro',
          icone: 'far fa-times-circle',
          titulo: 'Identificamos um erro:',
          texto: 'Não foi possível definir a senha. Tente novamente ou entre em contato com um posto de atendimento da AGEHAB'
        });
        return true;
      }
      const mensagemDeErroDaResposta = typeof (e.response.data) === 'string'
        ? e.response.data
        : e.response.data.mensagens[0];
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: mensagemDeErroDaResposta
      });
    }
  };

  notificarCapsLockAtivo = estaAtivo => {
    this.setState({
      capsLockEstaAtivo: estaAtivo
    });
  };

  render() {
    const { confirmacaoDeSenha, senha, erros, capsLockEstaAtivo } = this.state;
    const inputEvents = {
      onChange: this.onChange,
      onBlur: this.onBlur,
      onFocus: this.onFocus
    };

    return (
      <LayoutDaPaginaDeEntrada>
        <div className="grade">
          <div className="grade__coluna">
            <h2 id="foco-inicial-do-teclado" tabIndex="-1" className="titulo titulo_grande texto_cor-terciaria u-margem-inferior-pequena">Definir senha</h2>
            <p className="texto u-margem-inferior-pequena">
              Guarde esta senha! Ela será necessária sempre que for acessar sua inscrição.
            </p>
            <p className="texto">
              Deve ter no mínimo 6 caracteres, incluíndo letras e números.
            </p>
          </div>

          <form onSubmit={(event) => this.definirSenha(event)}
            className="formulario formulario_medio u-margem-inferior-media">
            <CampoDeTexto
              label="Senha de acesso"
              name="senha"
              value={senha}
              tipo="password"
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) => detectarLetrasMaiusculas(e, this.notificarCapsLockAtivo)}
              onKeyDown={(e) => detectarCapsLock(e, this.notificarCapsLockAtivo)}
              erro={erros.senha ? erros.senha : !!confirmacaoDeSenha && erros.confirmacaoDeSenha ? ' ' : null}
              {...inputEvents}>
            </CampoDeTexto>
            <CampoDeTexto
              label="Confirme a senha de acesso"
              name="confirmacaoDeSenha"
              value={confirmacaoDeSenha}
              tipo="password"
              capsLockAtivo={capsLockEstaAtivo}
              onKeyPress={(e) => detectarLetrasMaiusculas(e, this.notificarCapsLockAtivo)}
              onKeyDown={(e) => detectarCapsLock(e, this.notificarCapsLockAtivo)}
              erro={erros.confirmacaoDeSenha}
              {...inputEvents} />
            <div className="grade__linha">
              <div className="grade__coluna">
                <BotaoComTexto
                  tamanho="largura-total"
                  cor="terciaria">
                  Salvar
                </BotaoComTexto>
              </div>
            </div>
          </form>
        </div>
      </LayoutDaPaginaDeEntrada>
    );
  }
}

export default connect(null, { endAjaxCall })(DefinirSenha);