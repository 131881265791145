interface ISituacaoMoradia {
    value: string,
    label: string
}

const situacoesDeMoradia: ISituacaoMoradia[] = [
  { value: 'PropriaQuitada', label: 'É um imóvel próprio' },
  { value: 'ConstrucaoNaoConcluida', label: 'É um imóvel próprio com construção não concluída' },
  { value: 'ImovelEmEstadoPrecario', label: 'É um imóvel próprio em estado precário de construção' },
  { value: 'Alugada', label: 'É alugada' },
  { value: 'Coabitada', label: 'É coabitada e moro junto de outra(s) família(s)' },
  { value: 'MoraComOsPais', label: 'É dos meus pais e moro com eles' },
  { value: 'OcupacaoIrregular', label: 'É uma ocupação irregular' },
  { value: 'Cedida', label: 'Foi cedida a mim' }
];

export default situacoesDeMoradia;