import React, { useEffect, useState } from 'react';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import Container from '../../componentes/Container';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import IPretendente from '../../interfaces/IPretendente';
import ContadorDePassos from '../../componentes/ContadorDePassos';
import IPassosDaSeparacaoDeInscricao from '../../interfaces/IPassosDaSeparacaoDaInscricao';
import EtapasDaSeparacaoDeInscricao from '../../componentes/EtapasDaSeparacaoDeInscricao';
import { consultarInscricaoParaSeparacao } from '../../servicos/separacaoDeInscricao';
import IInscricao from '../../interfaces/IInscricao';
import './styles.scss';
import { 
  ETAPA_CONFIRMACAO,
  ETAPA_DEPENDENTES, ETAPA_QUEM_PERMANECE_NA_INSCRICAO, ETAPA_ESTADO_CIVIL,
  ETAPA_IMOVEIS, ETAPA_MOTIVO_DA_SEPARACAO, ETAPA_PROGRAMAS_SOCIAIS, 
} from '../../helpers/etapasDaSeparacaoDeInscricao';
//@ts-ignorets
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';

const SeparacaoDeInscricao = () => {

  const idDaInscricao = localStorage.getItem('idDaInscricao');
  const temPermissaoParaSeparacaoDeInscricao = localStorage.getItem('temPermissaoParaSeparacaoDeInscricao');
  const [inscricao, setInscricao]  = useState<IInscricao>({} as IInscricao);
  const [ehSeparacaoComplexa, setEhSeparacaoComplexa] = useState<boolean>(false);
  const [passosDaSeparacao, setPassosDaSeparacao] = useState<IPassosDaSeparacaoDeInscricao[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (idDaInscricao){
      consultarInscricaoParaSeparacao(parseInt(idDaInscricao)).then(resposta => {
        const inscricao = resposta.data.inscricao;
        setInscricao(inscricao);
        setEhSeparacaoComplexa(resposta.data.ehSeparacaoComplexa);
        setPassosDaSeparacao(calcularEtapas(inscricao));
      });
    }

  }, [idDaInscricao]);

  const validarPermissaoParaSepararInscricao = () =>  {
    if (temPermissaoParaSeparacaoDeInscricao != 'true'){
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Separação de inscrição:',
        texto: 'Permissão negada para executar esta ação.'
      });
      dispatch(push('/listagem-de-inscricoes'));
    }
  };
  validarPermissaoParaSepararInscricao();

  const calcularEtapas = (inscricao: IInscricao) => {

    let numero = 4;
    let etapas = [
      { numeroEtapa: 1, nomeDoPasso: ETAPA_MOTIVO_DA_SEPARACAO, status: 'atual' },
      { numeroEtapa: 2, nomeDoPasso: ETAPA_QUEM_PERMANECE_NA_INSCRICAO, status: 'incompleto' },
      { numeroEtapa: 3, nomeDoPasso: ETAPA_ESTADO_CIVIL, status: 'incompleto' }
    ];

    if (!!inscricao.dependentes && inscricao.dependentes.length > 0) 
      etapas.push( {numeroEtapa: numero++, nomeDoPasso: ETAPA_DEPENDENTES, status: 'incompleto' });
    
    if (!!inscricao.imoveisProprios && inscricao.imoveisProprios.length > 0)
      etapas.push( {numeroEtapa: numero++, nomeDoPasso: ETAPA_IMOVEIS, status: 'incompleto' });
    
    if (!!inscricao.programasSociais && inscricao.programasSociais.length > 0)
      etapas.push( {numeroEtapa: numero++, nomeDoPasso: ETAPA_PROGRAMAS_SOCIAIS, status: 'incompleto' });
    
    etapas.push({numeroEtapa: numero++, nomeDoPasso: ETAPA_CONFIRMACAO, status: 'incompleto' });

    return etapas;
  };

  const reiniciarEtapas = () => {
    setPassosDaSeparacao(calcularEtapas(inscricao)); 
  };

  return (
    <>
      <div className='container-separacao-inscricao'>
        <BarraDeNavegacao
          titulo="Separação de inscrição"
          comBotaoVoltar={true}
          acaoBotaoVoltar={() => dispatch(push('/listagem-de-inscricoes'))}
          comBotaoADireita={false}
          iconeBotaoADireita={false}
          acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
        />

        <Container tamanhoDoContainer="pequeno" comCabecalhoFixo={true}>
          <ContadorDePassos etapas={passosDaSeparacao}/>
          <span className='numero-inscricao'>
            <strong>Inscrição {inscricao.numero}</strong>
          </span>
          <EtapasDaSeparacaoDeInscricao numeroDaInscricao={inscricao.numero} idDaInscricao={inscricao.id} titular={inscricao.titular} conjuge={inscricao.conjuge || {} as IPretendente} dependentes={inscricao.dependentes} 
            imoveis={inscricao.imoveisProprios} programasSociais={inscricao.programasSociais} etapas={passosDaSeparacao} 
            ehSeparacaoComplexa={ehSeparacaoComplexa} setEtapas={setPassosDaSeparacao} reiniciarEtapas={reiniciarEtapas} />
        </Container>
      </div>
    </>
  );
};

export default SeparacaoDeInscricao;