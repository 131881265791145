export default {
  inscricao: { id: 0 },
  login: {},
  dadosTitular: {},
  dadosConjuge: {},
  profissoes: [],
  cidades: [],
  dependentes: [],
  informacoesDaFamilia: {},
  programasSociais: {},
  menu: { inconsistencias: [], podeGerarComprovanteDeInscricao: false },
  moradia: {},
  endereco: {},
  imoveis: [],
  distritos: [],
  empreendimentos: [],
  requisicoesAjax: 0,
  ehNovaInscricao: false,
  informacoesDeLocalDeAtendimento: { localDeAtendimento: {}, podeAlterarLocalDeAtendimento: false },
  usuario: {},
  ehAcessoAdministrativo: false,
  grauDeInstrucoes: [],
  regimesDeCasamento: [],
  etnias: [],
  questoesDoFormulario: [],
  ehFormularioSomenteLeitura: false,
  deveCarregarInscricaoNaMemoria: false,
  demandasEspecificas:[],
  cidadesDoBrasil: [],
  tiposDeDeficiencia: []
};