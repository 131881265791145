interface IMaterialDeConstrucao {
    value: string,
    label: string
}

const materiaisDeConstrucao: IMaterialDeConstrucao[] = [
  { value: 'Alvenaria', label: 'Alvenaria (tijolo)' },
  { value: 'Lona', label: 'Lona' },
  { value: 'Madeira', label: 'Madeira' },
  { value: 'PauAPique', label: 'Pau-a-pique' },
  { value: 'RestosDeMateriaisDiversos', label: 'Restos de materiais diversos' },
  { value: 'SapeOuPalha', label: 'Sapê ou palha' },
  { value: 'NaoSei', label: 'Não sei' }
];

export default materiaisDeConstrucao;