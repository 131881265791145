import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CampoDeSelecao from '../CampoDeSelecao';
import { removerAcentos } from '../../helpers/strings';

class CampoCidade extends Component {

  constructor(props) {
    super(props);
    this.buscarCidade = this.buscarCidade.bind(this);
    this.buscarCidadePorId = this.buscarCidadePorId.bind(this);
  }

  render() {
    const { label, erro, placeholder } = this.props;

    return (
      <div className="grade__coluna u-largura-total">
        <CampoDeSelecao
          name="campoDeSelecaoDeCidade"
          label={label}
          placeholder={placeholder}
          funcaoDeBuscaPorTexto={this.buscarCidade}
          funcaoDeBuscaPorId={this.buscarCidadePorId}
          onChange={this.props.onChange}
          idSelecionado={this.props.cidade}
          labelParaAbrirSelect={'Mostrar opções de cidade'}
          labelParaFecharSelect={'Fechar opções de cidade'}
          disabled={this.props.disabled}
          erro={erro}
          onBlur={this.onBlur} 
        />
      </div>
    );
  }

  onBlur = (evento) => {
    const { value: nomeDaCidadeDigitado } = evento.target;
    const cidades = this.buscarCidade(nomeDaCidadeDigitado);
    const encontrouNenhumaOuMaisDeUmaCidade = cidades.length !== 1;
    
    if (encontrouNenhumaOuMaisDeUmaCidade) {
      this.props.onChange(null);
      return;
    }

    const { value: idDaCidade, label: nomeDaCidade } = cidades[0];
    const digitouONomeCorretoDaCidade = 
      removerAcentos(nomeDaCidadeDigitado.toLowerCase()) === removerAcentos(nomeDaCidade.toLowerCase());

    if (!digitouONomeCorretoDaCidade) {
      this.props.onChange(null);
      return;
    }
    
    this.props.onChange(idDaCidade);
  };

  buscarCidade(textoParaBusca) {
    const cidades = this.props.cidades;
    const textoSemAcentos = removerAcentos(textoParaBusca);

    var resultados = [];

    for (var indice = 0; indice < cidades.length; indice++) {
      var cidade = removerAcentos(cidades[indice].label.toLowerCase());
      if (cidade.indexOf(textoSemAcentos.toLowerCase()) === 0) {
        resultados.push(cidades[indice]);
      }
    }

    if (resultados.length == 0 || textoSemAcentos.length == 0)
      this.props.onChange(undefined);

    return resultados;
  }

  buscarCidadePorId(id) {
    const cidades = this.props.cidades;

    for (var indice = 0; indice < cidades.length; indice++) {
      var cidade = cidades[indice];
      if (cidade.value == id) {
        return cidades[indice];
      }
    }

    return null;
  }
}

CampoCidade.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  cidade: PropTypes.any,
  cidades: PropTypes.array,
  erro: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default CampoCidade;