import React from 'react';
import CampoRadioButton from '../CampoRadioButton/index';
import MensagemDeErro from '../MensagemDeErro';

interface ICampoStatusDaQuitacaoDoImovelProps {
  estaQuitado?: boolean,
  erro?: boolean,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const CampoStatusDaQuitacaoDoImovel = ({ estaQuitado, erro, onChange }: ICampoStatusDaQuitacaoDoImovelProps) => {
  return (
    <div className="grade__coluna">
      <fieldset className="formulario__grupo-de-inputs">
        <legend className='formulario__label'>Este imóvel está quitado?</legend>
        {erro && <MensagemDeErro campo='estaQuitado' />}
        <CampoRadioButton
          mesmaLinha
          name="estaQuitado"
          value="true"
          label="Sim"
          onChange={onChange}
          checked={estaQuitado != undefined ? estaQuitado.toString() : ''} />
        <CampoRadioButton
          mesmaLinha
          name="estaQuitado"
          value="false"
          label="Não"
          onChange={onChange}
          checked={estaQuitado != undefined ? estaQuitado.toString() : ''} />
      </fieldset>
    </div>
  );
};
export default CampoStatusDaQuitacaoDoImovel;