
interface ISituacaoNoMercadoDeTrabalho {
    value: string,
    label: string
}

const situacoesNoMercadoDeTrabalho: ISituacaoNoMercadoDeTrabalho[] =
    [{ label: 'Aposentado(a)', value: 'Aposentado' },
      { label: 'Empregado(a) no setor privado', value: 'EmpregadoNoSetorPrivado' },
      { label: 'Autônomo(a)', value: 'Autonomo' },
      { label: 'Pensionista', value: 'Pensionista' },
      { label: 'Servidor(a) público Federal', value: 'ServidorPublicoFederal' },
      { label: 'Servidor(a) público Estadual', value: 'ServidorPublicoEstadual' },
      { label: 'Servidor(a) público Municipal', value: 'ServidorPublicoMunicipal' },
      { label: 'Desempregado(a)', value: 'Desempregado' }];

export default situacoesNoMercadoDeTrabalho;