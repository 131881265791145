import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IMoradia from '../interfaces/IMoradia';
import IActionType from '../interfaces/IActionType';

export default function moradia(state = initialState.moradia, action: IActionType<IMoradia>) {
  switch (action.type) {
  case actionTypes.SALVAR_MORADIA_SUCESSO:
    return {
      ...action.state
    };
  case actionTypes.CARREGAR_MORADIA:
    return {
      ...action.state
    };
  default:
    return state;
  }
}