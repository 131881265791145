import initialState from './initialState.reducer';
import {
  SELECIONOU_LOCAL_DE_ATENDIMENTO,
  LIMPOU_LOCAL_DE_ATENDIMENTO,
  PODE_ALTERAR_LOCAL_DE_ATENDIMENTO,
} from '../actions/actionTypes';

export default (state = initialState.informacoesDeLocalDeAtendimento, acao) => {
  switch (acao.type) {
  case SELECIONOU_LOCAL_DE_ATENDIMENTO:
    return {...state, localDeAtendimento: acao.localDeAtendimento};
  case LIMPOU_LOCAL_DE_ATENDIMENTO:
    return {...state, localDeAtendimento: {}};
  case PODE_ALTERAR_LOCAL_DE_ATENDIMENTO:
    return {
      ...state,
      podeAlterarLocalDeAtendimento: acao.podeAlterarLocalDeAtendimento,
    };
  default:
    return state;
  }
};