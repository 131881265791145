import React from 'react';
import PropTypes from 'prop-types';

const BotTrap = props =>
  <input name='bot' type='text' style={{ display: 'none' }} ref={input => props.onRef(input)} />;

BotTrap.propTypes = {
  onRef: PropTypes.func,
};

export default BotTrap;