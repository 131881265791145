import axios from 'axios';
import { ajaxCallError, beginAjaxCall, endAjaxCall } from '../actions/requisicoesAjax.actions';
import { efetuarLogOut } from '../actions/login.actions';
import { tratarErro, tratarMensagem } from './tratamentoDeErro';
import { ACESSO_ADMINISTRATIVO } from '../helpers/tiposDeAcesso';

const rotaApi = process.env.REACT_APP_URL_API;

const instanciaAxiosSemLoading = axios.create();
const instanciaAxiosComLoading = axios.create();

let criacaoDaApiDaInscricao = instanciaDoAxios => ({
  axios: instanciaDoAxios,
  get: (rota, dados, header) => {
    return instanciaDoAxios
      .get(`${rotaApi}${rota}`, dados, header);
  },
  post: (rota, dados, header) => {
    return instanciaDoAxios
      .post(`${rotaApi}${rota}`, dados, header);
  },
  put: (rota, dados, header) => {
    return instanciaDoAxios
      .put(`${rotaApi}${rota}`, dados, header);
  },
  delete: (rota, dados, header) => {
    return instanciaDoAxios
      .delete(`${rotaApi}${rota}`, dados, header);
  }
});

export const apiDaInscricao = () => {
  return criacaoDaApiDaInscricao(instanciaAxiosComLoading);
};

export const apiDaInscricaoSemLoading = () => {
  return criacaoDaApiDaInscricao(instanciaAxiosSemLoading);
};
export const axiosComLoading = axios.create();

export const configureAxios = store => {

  const headersComDadosDeAtualizacao = config => {
    const tipoDeAcesso = localStorage.getItem('tipo_acesso');
    config.headers.dados_de_atualizacao_tipo_de_acesso = tipoDeAcesso;
    if (tipoDeAcesso != ACESSO_ADMINISTRATIVO) return;

    config.headers.dados_de_atualizacao_login = localStorage.getItem('loginDoUsuario');
    const localDeAtendimento = localStorage.getItem('localDeAtendimento');
    if (!localDeAtendimento) return;    

    const idLocalDeAtendimento = JSON.parse(localDeAtendimento)?.id;
    config.headers.dados_de_atualizacao_id_local_de_atendimento = idLocalDeAtendimento;
  };

  const headersDeAutenticacao = config => config.headers.Authorization = localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : '';

  const requestInterceptorComLoadingEndereco = instanciaAxios => instanciaAxios.interceptors.request.use(
    config => {
      store.dispatch(beginAjaxCall());
      return config;
    });

  const requestInterceptorComLoading = instanciaAxios => instanciaAxios.interceptors.request.use(
    config => {
      headersDeAutenticacao(config);
      headersComDadosDeAtualizacao(config);
      store.dispatch(beginAjaxCall());
      return config;
    });

  const requestInterceptorSemLoading = instanciaAxios => instanciaAxios.interceptors.request.use(
    config => {
      headersDeAutenticacao(config);
      headersComDadosDeAtualizacao(config);
      return config;
    });

  const responseInterceptor = instanciaAxios => instanciaAxios.interceptors.response.use(
    response => {
      store.dispatch(endAjaxCall());
      return response;
    },
    error => {
      store.dispatch(ajaxCallError());
      
      if (error.response?.status === 401)
        store.dispatch(efetuarLogOut());
      if (error.response?.status === 403)
        tratarMensagem('Você não possui permissão para executar essa ação');
      else if (error.request.status === 400)
        tratarMensagem(error?.response?.data?.mensagem);
      else
        tratarErro(error);

      return Promise.reject(error);
    });

  const responseInterceptorEndereco = instanciaAxios => instanciaAxios.interceptors.response.use(
    response => {
      store.dispatch(endAjaxCall());
      return response;
    },
    error => {
      store.dispatch(ajaxCallError());
      return Promise.reject(error);
    }
  );

  requestInterceptorSemLoading(apiDaInscricaoSemLoading().axios);
  requestInterceptorComLoading(apiDaInscricao().axios);
  requestInterceptorComLoadingEndereco(axiosComLoading);
  responseInterceptorEndereco(axiosComLoading);
  responseInterceptor(apiDaInscricao().axios);
  responseInterceptor(apiDaInscricaoSemLoading().axios);
};