import React from 'react';
import IPassosDaSeparacaoDaInscricao from '../../interfaces/IPassosDaSeparacaoDaInscricao';
import './styles.scss';
import { STATUS_ATUAL, STATUS_COMPLETO, STATUS_INCOMPLETO } from '../../helpers/statusDaEtapaDeSeparacaoDeInscricao';

interface IContadorDePassosProps{
    etapas: IPassosDaSeparacaoDaInscricao[];
} 

const ContadorDePassos = ({etapas}: IContadorDePassosProps) => {
  
  const inicio = etapas.filter((x: IPassosDaSeparacaoDaInscricao) => x.status == STATUS_COMPLETO).length + 1;
  const fim = etapas.length;
  return (
    <>
      <div className='passos'>
        PASSO {inicio} DE {fim} 
      </div>
      <div className='icones'>
        {etapas.map((passo: IPassosDaSeparacaoDaInscricao, index) =>
          <i key={index} className={`${passo.status == STATUS_ATUAL ? 'fa fa-circle' : passo.status == STATUS_INCOMPLETO ? 'far fa-circle': 'fa fa-check-circle preto'}`}></i>
        )}
      </div>
    </>);
};
export default ContadorDePassos;