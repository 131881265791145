import React from 'react';
//@ts-ignore
import Select from 'react-select';
import estados from './Estados.json';
import MensagemDeErro from '../MensagemDeErro';

interface IEventOnChange {
  target: { name: string, value?: number }
}

interface ISelecaoDeEstadoProps {
  uf: string,
  label: string,
  clearable: boolean,
  erro?: Boolean,
  disabled?: boolean, 
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const SelecaoDeEstado = ({ uf, erro, label, clearable, disabled, onChange }: ISelecaoDeEstadoProps) => {

  const handleOnChange = (selected: { value: number }) => {
    const event: IEventOnChange = {
      target: {
        name: 'selecaoDeEstado',
        value: !!selected ? selected.value : undefined
      }
    };
    //@ts-ignore
    onChange(event);
  };

  return (
    <div className="grade__coluna">
      <div className={`formulario__input-react-select ${erro && 'formulario__input-react-select_erro'}`}>
        <label className='formulario__label'>{label}</label>
        {erro && <MensagemDeErro campo='selecaoDeEstado' />}
        <Select
          id='selecaoDeEstado'
          name='selecaoDeEstado'
          value={uf}
          placeholder="Selecione um estado"
          options={estados}
          onChange={handleOnChange}
          clearable={clearable}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SelecaoDeEstado;