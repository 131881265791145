import React from 'react';
import { LayoutDaPaginaDeEntrada } from '../../componentes';
import { BotaoComTexto } from '@bit/digix.digixui.botao';

const PaginaDeEntrada = ({ history }) => (
  <LayoutDaPaginaDeEntrada>
    <div className="grade">
      <section className="grade__coluna u-texto-alinhado-ao-centro">
        <i className="far fa-user-edit fa-2x texto_cor-terciaria u-margem-inferior-pequena"></i>
        <h1 className="titulo titulo_grande texto_cor-terciaria u-margem-inferior-media">Já possui uma inscrição?</h1>
        <BotaoComTexto
          onClick={() => history.push('login')}
          cor='terciaria'
          classesAuxiliares='botao_largura-total botao_sem-altura'>
          Quero atualizar minha inscrição
        </BotaoComTexto>
      </section>

      <section className="grade__coluna u-texto-alinhado-ao-centro u-margem-superior-grande">
        <i className="far fa-user-plus fa-2x texto_cor-terciaria u-margem-inferior-pequena"></i>
        <h1 className="titulo titulo_grande texto_cor-terciaria u-margem-inferior-media">É novo(a) por aqui?</h1>
        <BotaoComTexto
          onClick={() => history.push('cadastro')}
          cor='terciaria'
          classesAuxiliares='botao_largura-total botao_sem-altura'>
          Quero fazer minha inscrição
        </BotaoComTexto>
      </section>
    </div>
  </LayoutDaPaginaDeEntrada>
);

export default PaginaDeEntrada;