import { apiDaInscricao } from './axios';
import { verificarSeEhAmbienteDeProducao } from '../helpers/verificadorDeAmbientes';
import { obterTokenAcessoAdministrativo } from '../servicos/usuario'; 
import { ACESSO_ADMINISTRATIVO, ACESSO_PUBLICO } from '../helpers/tiposDeAcesso';

const keys = {
  cidade: 'cidade', cpf: 'cpf', dataDeNascimento: 'dataDeNascimento',
  numeroDaInscricao: 'numeroDaInscricao', accessToken: 'access_token', nome: 'nome',
  idDaInscricao: 'idDaInscricao', estaAutenticadoNoSeguranca: false, identificadorDoUsuario:'identificadorDoUsuario',
  loginDoUsuario: 'loginDoUsuario', permissoesDeAcessoDoUsuario: 'permissoesDeAcessoDoUsuario'
};

const autenticar = async ({ cpf, cidade, senha, nome, dataDeNascimento }) => {
  const payload = { idCidade: cidade, cpf, senha, nome };
  const { data: { access_token, inscricao } } =
    await apiDaInscricao().post('/login/', payload);

  guardarAutenticacao(access_token, cidade, cpf, nome, dataDeNascimento);

  if (inscricao && inscricao.id)
    guardarInformacoesDaInscricao(inscricao);

  return inscricao;
};

const guardarAutenticacao = (access_token, cidade, cpf, nome, dataDeNascimento) => {
  atualizarAccessToken(access_token);
  localStorage.setItem(keys.cidade, cidade);
  localStorage.setItem(keys.cpf, cpf.replace(/\.|-/g, ''));
  localStorage.setItem(keys.nome, nome);
  localStorage.setItem(keys.dataDeNascimento, dataDeNascimento);
  guardarTipoAcesso(ACESSO_PUBLICO);
};

const atualizarAccessToken = (access_token) => {
  localStorage.setItem(keys.accessToken, access_token);
};

const guardarInformacoesDaInscricao = (inscricao) => {
  localStorage.setItem(keys.idDaInscricao, inscricao.id);
  localStorage.setItem(keys.numeroDaInscricao, inscricao.numero);
};

const obterDadosAutenticacao = () => ({
  cidade: localStorage.getItem(keys.cidade),
  cpf: localStorage.getItem(keys.cpf),
  nome: localStorage.getItem(keys.nome),
  numeroDaInscricao: localStorage.getItem(keys.numeroDaInscricao),
  dataDeNascimento: localStorage.getItem(keys.dataDeNascimento),
  idDaInscricao: localStorage.getItem(keys.idDaInscricao)
});

const estaAutenticado = () =>
  localStorage.getItem(keys.cidade) &&
  localStorage.getItem(keys.cpf) &&
  (!verificarSeEhAmbienteDeProducao() ? true : localStorage.getItem(keys.nome)) &&
  localStorage.getItem(keys.accessToken);

const guardarLoginDoUsuario = (login) => {
  localStorage.setItem(keys.loginDoUsuario, login);
};

const guardarTipoAcesso = (tipo) =>{
  localStorage.setItem('tipo_acesso', tipo);
};

const guardarPermissoesDeAcessoDoUsuario = (permissoes) => {
  localStorage.setItem(keys.permissoesDeAcessoDoUsuario, permissoes);
};

const checarAutenticacaoNoSeguranca = async () => {
  const query = new URLSearchParams(window.location.search);
  const identificadorDoUsuario = query.get('identificadorDoUsuario');
  const estaAutenticado = validarAcessoAdministrativo();
  if (identificadorDoUsuario && !estaAutenticado) {
    await obterTokenAcessoAdministrativo(identificadorDoUsuario).then(
      resposta => {
        if (resposta.data.token){ 
          atualizarAccessToken(resposta.data.token);
          guardarLoginDoUsuario(resposta.data.loginDoUsuario);
          guardarTipoAcesso(ACESSO_ADMINISTRATIVO);
          guardarPermissoesDeAcessoDoUsuario(resposta.data.permissoes);
        }
      }).catch(error => {
      console.log(error);
    });
  }
};

const validarAcessoAdministrativo = () => {
  const token = localStorage.getItem(keys.accessToken);
  return token && tokenEstaValido(token) && ACESSO_ADMINISTRATIVO == localStorage.getItem('tipo_acesso');
};

const validarAcessoPublico = () => {
  const token = localStorage.getItem(keys.accessToken);
  return token && tokenEstaValido(token) && ACESSO_PUBLICO == localStorage.getItem('tipo_acesso');
};

const logoff = () => {
  localStorage.clear();
};

const converterTokenJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};

const tokenEstaValido = (token) => {
  const decodedToken = converterTokenJwt(token);
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime < decodedToken.exp;
};

export {
  autenticar, obterDadosAutenticacao, logoff, estaAutenticado, atualizarAccessToken,
  guardarInformacoesDaInscricao as guardarNumeroDaInscricao, checarAutenticacaoNoSeguranca, 
  validarAcessoAdministrativo, validarAcessoPublico
};