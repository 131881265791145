import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IPretendente from '../interfaces/IPretendente';
import IActionType from '../interfaces/IActionType';

export default function dadosConjuge(state = initialState.dadosConjuge, action: IActionType<IPretendente>) {
  switch (action.type) {
  case actionTypes.SALVAR_CONJUGE_SUCESSO:
    return {
      ...action.state
    };
  case actionTypes.CARREGAR_CONJUGE:
    return {
      ...action.state
    };
  default:
    return state;
  }
}