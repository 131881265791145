import React from 'react';
import CampoRadioButton from '../../../CampoRadioButton';
import MensagemDeErro from '../../../MensagemDeErro';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';

interface ICampoEhMulherVitimaDeViolenciaDomesticaProps {
    ehMulherVitimaDeViolenciaDomestica?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    erros?: IPretendenteValidacaoErroCampos,
    disabled: boolean
}

const CampoEhMulherVitimaDeViolenciaDomestica = ({ ehMulherVitimaDeViolenciaDomestica, onChange,
  erros, disabled = false }: ICampoEhMulherVitimaDeViolenciaDomesticaProps) => {

  return (
    <>
      <div className="grade__linha">
        <div className="grade__coluna">
          <h2 className="titulo titulo_medio texto_cor-secundaria">
            <strong>Vítima de violência doméstica</strong>
          </h2>
        </div>
      </div>
      <div className="grade__coluna">
        <fieldset className="formulario__grupo-de-inputs">
          <legend className="formulario__label">
            É mulher vítima de violência doméstica?
            <p className="formulario__descricao">
              Mulher vítima de violência doméstica e familiar, prevista na Lei nº 11.340, de 07 de agosto de 2006 (Lei Maria da Penha).
            </p>
          </legend>
          {erros?.ehMulherVitimaDeViolenciaDomestica &&
          <MensagemDeErro />}
          <CampoRadioButton
            mesmaLinha
            name="ehMulherVitimaDeViolenciaDomestica"
            value="true"
            label="Sim"
            checked={ehMulherVitimaDeViolenciaDomestica?.toString() ?? ''}
            onChange={onChange}
            disabled={disabled}/>
          <CampoRadioButton
            mesmaLinha
            name="ehMulherVitimaDeViolenciaDomestica"
            value="false"
            label="Não"
            checked={ehMulherVitimaDeViolenciaDomestica?.toString() ?? ''}
            onChange={onChange}
            disabled={disabled} />
        </fieldset>
      </div>
    </>
  );
};

export default CampoEhMulherVitimaDeViolenciaDomestica;