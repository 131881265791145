import { CARREGAR_QUESTOES_DO_FORMULARIO } from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IQuestaoDoFormulario from '../interfaces/IQuestaoDoFormulario';

export default (state = initialState.questoesDoFormulario, action: IActionType<IQuestaoDoFormulario[]>) => {
  switch (action.type) {
  case CARREGAR_QUESTOES_DO_FORMULARIO:
    return [...action.state];
  default:
    return state;
  }
};