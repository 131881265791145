import React, { useEffect, useState } from 'react';
import { CampoDeTexto } from '../../componentes';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import Container from '../../componentes/Container';
import IParametrosDePesquisaDeInscricao from '../../interfaces/IParametrosDePesquisaDeInscricao';
import IPaginacao from '../../interfaces/IPaginacao';
import IInscricaoParaListagem from '../../interfaces/IInscricaoParaListagem';
//@ts-ignorets
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
//@ts-ignorets
import SeletorDePagina from '@bit/digix.digixui.seletor-de-pagina';
//@ts-ignorets
import { BotaoComTextoEIcone } from '@bit/digix.digixui.botao';
import { push } from 'connected-react-router';
import mascaras from '../../helpers/mascaras';
import { consultarInscricoes } from '../../servicos/inscricao';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import initialState from '../../reducers/initialState.reducer';
import IInformacaoDeLocalDeAtendimento from '../../interfaces/IInformacaoDeLocalDeAtendimento';
import { carregarDadosGeraisDaInscricao } from '../../actions/inscricao.actions';
import CampoDeTextoComMascara from '../../componentes/CampoDeTextoComMascara';
import { carregarTitular } from '../../actions/dadosTitular.actions';
import { carregarConjuge } from '../../actions/dadosConjuge.actions';
import { carregarInformacoesDaFamilia } from '../../actions/informacoesDaFamilia.actions';
import { carregarProgramasSociais } from '../../actions/programasSociais.actions';
import { carregarImoveis } from '../../actions/imoveis.actions';
import { carregarDependentes } from '../../actions/dependente.actions';
import IPretendente from '../../interfaces/IPretendente';
import IInformacoesDaFamilia from '../../interfaces/IInformacoesDaFamilia';
import IProgramasSociais from '../../interfaces/IProgramasSociais';
import IImovelProprio from '../../interfaces/IImovelProprio';
import IDependente from '../../interfaces/IDependente';
import { ehNovaInscricao, limparEhNovaInscricao } from '../../actions/ehNovaInscricao.actions';
import { ehFormularioSomenteLeitura } from '../../actions/ehFormularioSomenteLeitura.actions';
import { deveCarregarInscricaoNaMemoria } from '../../actions/deveCarregarInscricaoNaMemoria.actions';
import { SEPARACAO_E_JUNCAO_DE_INSCRICAO, SEPARACAO_E_JUNCAO_AVANCADA_DE_PROTOCOLOS} from '../../helpers/tiposDePermissoes';
import { verificarSePossuiPermissaoDeAcessoDoUsuario } from '../../helpers/checarPermissoesDeAcessoDoUsuario';

interface inputEvents {
  onKeyPress: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void,
}

const ListagemDeInscricoes = (inputEvents: inputEvents) => {

  const [parametrosDePesquisaDeInscricao, setParametrosDePesquisaDeInscricao] = useState<IParametrosDePesquisaDeInscricao>({
    cpf: '',
    nome: '',
    numero: '',
    paginaAtual: 1
  });

  const [inscricoes, setInscricoes] = useState<IPaginacao<IInscricaoParaListagem> | null>(null);
  const cabecalhoTabela = ['Nome', 'CPF', 'Nº da Inscrição', 'Status', ''];
  let [menuDeContextoEmExibicao, setMenuDeContextoEmExibicao] = useState<number>(-1);
  const [resetarPaginacao, setResetarPaginacao] = useState<number>(new Date().getTime());
  const [deveBuscarInscricoesNaApi, setDeveBuscarInscricoesNaApi] = useState<boolean>(false);
  const informacoesDeLocalDeAtendimento = useSelector((state: typeof initialState) => state.informacoesDeLocalDeAtendimento as IInformacaoDeLocalDeAtendimento);
  const dispatch = useDispatch();

  useEffect(() => {
    if (deveBuscarInscricoesNaApi)
      return obterInscricoes(parametrosDePesquisaDeInscricao);

    const pesquisaDeInscricaoAnterior = localStorage.getItem('pesquisaDeInscricao');
    if (pesquisaDeInscricaoAnterior != null) {
      const dadosPesquisaAnterior = JSON.parse(pesquisaDeInscricaoAnterior) as IParametrosDePesquisaDeInscricao;

      const dadosParaPesquisar: IParametrosDePesquisaDeInscricao = {
        cpf: dadosPesquisaAnterior.cpf,
        nome: dadosPesquisaAnterior.nome,
        numero: dadosPesquisaAnterior.numero,
        paginaAtual: dadosPesquisaAnterior.paginaAtual
      };
      setParametrosDePesquisaDeInscricao(dadosParaPesquisar);
      obterInscricoes(dadosParaPesquisar);
      localStorage.removeItem('pesquisaDeInscricao');
    }
  }, [deveBuscarInscricoesNaApi, parametrosDePesquisaDeInscricao]);

  function atualizaDadosParaPesquisar(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setParametrosDePesquisaDeInscricao({ ...parametrosDePesquisaDeInscricao, [name]: value });
  }

  function validarCamposDePesquisa(): boolean {
    if (!parametrosDePesquisaDeInscricao.cpf && !parametrosDePesquisaDeInscricao.nome && !parametrosDePesquisaDeInscricao.numero) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Atenção',
        texto: 'Informe um campo para efetuar a pesquisa!'
      });
      return false;
    }
    return true;
  }

  function pesquisar(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    if (validarCamposDePesquisa()) {
      setParametrosDePesquisaDeInscricao({ ...parametrosDePesquisaDeInscricao, paginaAtual: 1 });
      setResetarPaginacao(new Date().getTime());
      setDeveBuscarInscricoesNaApi(true);
    }
  }

  function obterInscricoes(dadosParaPesquisar: IParametrosDePesquisaDeInscricao) {
    consultarInscricoes(dadosParaPesquisar).then(resposta => {
      setInscricoes(resposta.data);
    }).catch(error => {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: error.response?.data?.ehExcecaoDeDominio ? error.response.data.mensagens[0] : 'Tente novamente.'
      });
      setInscricoes(null);
    });
    setDeveBuscarInscricoesNaApi(false);
  }

  function abrirMenuDeContexto(indexLinha: number, event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    let indexDoMenuQueDeveraSerExibido = 0;

    if (menuDeContextoEmExibicao !== indexLinha)
      indexDoMenuQueDeveraSerExibido = indexLinha;
    else
      indexDoMenuQueDeveraSerExibido = -1;

    setMenuDeContextoEmExibicao(indexDoMenuQueDeveraSerExibido);
  }

  function fecharMenuDeContexto(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    if (menuDeContextoEmExibicao > -1)
      setMenuDeContextoEmExibicao(-1);
  };

  function irPara(pagina: number) {

    if (inscricoes != null && pagina == inscricoes.paginaAtual)
      return false;
    if (inscricoes != null && pagina > inscricoes.totalDePaginas)
      return false;
    if (pagina <= 0)
      return false;

    setParametrosDePesquisaDeInscricao({ ...parametrosDePesquisaDeInscricao, paginaAtual: pagina });
    setDeveBuscarInscricoesNaApi(true);
  }

  function acessarInscricao(inscricaoId: Number) {
    localStorage.setItem('pesquisaDeInscricao', JSON.stringify(parametrosDePesquisaDeInscricao));
    limparInscricaoDaMemoria();
    localStorage.setItem('idDaInscricao', inscricaoId.toString());
    dispatch(deveCarregarInscricaoNaMemoria(true));
    dispatch(push('/menu-inscricao'));
  }

  function limparInscricaoDaMemoria() {
    localStorage.setItem('idDaInscricao', '');
    dispatch(carregarDadosGeraisDaInscricao({}));
    dispatch(carregarTitular({} as IPretendente));
    dispatch(carregarConjuge({} as IPretendente));
    dispatch(carregarInformacoesDaFamilia({} as IInformacoesDaFamilia));
    dispatch(carregarProgramasSociais({} as IProgramasSociais));
    dispatch(carregarImoveis([] as IImovelProprio[]));
    dispatch(carregarDependentes([] as IDependente[]));
    dispatch(limparEhNovaInscricao());
    dispatch(ehFormularioSomenteLeitura(false));
  }

  function criarInscricao(){
    limparInscricaoDaMemoria();
    dispatch(ehNovaInscricao());
    dispatch(push('/nova-inscricao'));
  }  
  const separarInscricao = (inscricaoId: Number, permiteAlteracaoPelaAgencia: boolean) => {
    localStorage.setItem('pesquisaDeInscricao', JSON.stringify(parametrosDePesquisaDeInscricao));
    limparInscricaoDaMemoria();
    localStorage.setItem('idDaInscricao', inscricaoId.toString());
    localStorage.setItem('temPermissaoParaSeparacaoDeInscricao', validarPermissaoParaSeparacao(permiteAlteracaoPelaAgencia).toString());
    dispatch(push('/separacao-de-inscricao'));
  };

  const validarPermissaoParaSeparacao = (permiteAlteracaoPelaAgencia: boolean) => {
    const permissaoDeSeparacao = verificarSePossuiPermissaoDeAcessoDoUsuario(SEPARACAO_E_JUNCAO_DE_INSCRICAO);
    const permissaoDeSeparacaoAvancada = verificarSePossuiPermissaoDeAcessoDoUsuario(SEPARACAO_E_JUNCAO_AVANCADA_DE_PROTOCOLOS);
    if (permissaoDeSeparacaoAvancada)
      return true;
    else if (permiteAlteracaoPelaAgencia && permissaoDeSeparacao)
      return true;
    else
      return false;
  };

  const abrirConsultaDePreSelecionadoNoModuloSelecao = (numeroDaInscricao: string) =>  {
    const urlConsultaDePreselecionadoNoSelecao = process.env.REACT_APP_URL_CONSULTA_DE_PRESELECIONADO_NO_SELECAO + numeroDaInscricao;
    window.open(urlConsultaDePreselecionadoNoSelecao, '_blank', 'noopener');
  };

  const validarPermissaoDetalhesDaFamilia = (estaPreSelecionada: boolean) => {
    return !(estaPreSelecionada);
  };

  return (
    <>
      <div onClick={(event: React.MouseEvent<HTMLElement>) => fecharMenuDeContexto(event)}>
        <BarraDeNavegacao
          titulo="Listagem de Inscrições"
          comBotaoVoltar={false}
          comBotaoADireita={true}
          textoBotaoADireita="Nova Inscrição"
          iconeBotaoADireita={true}
          acaoBotaoADireita={criarInscricao}
        />
        <Container comFundo={true} semPadding={false} tamanhoDoContainer={'grande'} comCabecalhoFixo={true} >
          <div className='formulario' >
            <div>
              <div className='cartao__container-primario cartao_cor-terciaria'>
                <h2 className='cartao__titulo'>Listagem de Inscrições</h2>
              </div>
              <p className='u-margem-superior-pequena'>Processos de seleção {informacoesDeLocalDeAtendimento.localDeAtendimento.cidadeComUf}</p>
            </div>
            <div className='grade__linha grade__linha_distribuir-horizontal-entre-elementos grade__linha_alinhar-vertical-a-base'>
              <CampoDeTextoComMascara
                placeholder="CPF"
                mascara="999.999.999-99"
                name="cpf"
                value={parametrosDePesquisaDeInscricao.cpf}
                tipo="text" colunas="2" onChange={atualizaDadosParaPesquisar} {...inputEvents} />
              <CampoDeTexto
                placeholder="Nome"
                name="nome"
                value={parametrosDePesquisaDeInscricao.nome}
                tipo="text" colunas="6" onChange={atualizaDadosParaPesquisar} {...inputEvents} />
              <CampoDeTextoComMascara
                placeholder="Nº da inscrição"
                mascara="9999999999"
                name="numero"
                value={parametrosDePesquisaDeInscricao.numero}
                tipo="text" colunas="2" onChange={atualizaDadosParaPesquisar} {...inputEvents} />
              <div className='grade__coluna grade__coluna_2'>
                <BotaoComTextoEIcone tamanho='medio' cor='terciaria' habilitado={true} tipo='button' posicaoDoIcone='direita'
                  icone='far fa-search' onClick={(event: React.MouseEvent<HTMLElement>) => pesquisar(event)}>
                  Pesquisar
                </BotaoComTextoEIcone>
              </div>
            </div>
          </div>
          {inscricoes != null && (
            <>
              <div className="grade" >
                <div className="grade__linha" >
                  <div className="grade__coluna" >
                    <table data-test-id="tabela-inscricoes" className="tabela tabela_com-largura-total tabela_listrada tabela_com-borda u-sem-margem">
                      <thead>
                        <tr>
                          {cabecalhoTabela.map((item: string) => (
                            <th key={item} className={`is-cor-cinza-20 u-texto-alinhado-ao-${item == 'Nome' ? '-esquerda' : 'centro'}`} >{item}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {
                          inscricoes.modelo.length > 0 && inscricoes.modelo.map((inscricao: IInscricaoParaListagem, index) => 
                            (
                              <tr key={index}>
                                <td className=''>{inscricao.nome}</td>
                                <td className='u-texto-alinhado-ao-centro'>{mascaras.aplicarMascaraDeCpf(inscricao.cpf)}</td>
                                <td className='u-texto-alinhado-ao-centro'>{inscricao.numero}</td>
                                <td className='u-texto-alinhado-ao-centro'>{inscricao.status}</td>
                                <td className='u-texto-alinhado-ao-centro'>
                                  <div className='acoes'>
                                    <BotaoComTextoEIcone tamanho='pequeno' cor='terciaria' habilitado={true} tipo='button'
                                      posicaoDoIcone='direita' visual='sem-preenchimento'
                                      icone='far fa-chevron-down' onClick={(event: React.MouseEvent<HTMLElement>) => abrirMenuDeContexto(index, event)}>
                                      Ações
                                    </BotaoComTextoEIcone>
                                    {menuDeContextoEmExibicao === index && (
                                      <ul className='inscricao-acoes__lista'>
                                        <li className='inscricao-acoes__item'>
                                          <button className={'inscricao-acoes__botao'} disabled={inscricao.possuiConjuge && validarPermissaoParaSeparacao(inscricao.permiteAlteracaoPelaAgencia) ? false : true} 
                                            onClick={() => separarInscricao(inscricao.inscricaoId, inscricao.permiteAlteracaoPelaAgencia)}>
                                            <i className='inscricao-acoes__icone far fa-people-arrows'></i> Separar inscrição
                                          </button>
                                        </li>
                                        <li className='inscricao-acoes__item'>
                                          <button className='inscricao-acoes__botao' disabled={validarPermissaoDetalhesDaFamilia(inscricao.estaPreSelecionada)}
                                            onClick={() => abrirConsultaDePreSelecionadoNoModuloSelecao(inscricao.numero)}>
                                            <i className='inscricao-acoes__icone far fa-info-circle'></i> Detalhes da família
                                          </button>
                                        </li>
                                        <li className='inscricao-acoes__item'>
                                          <button className='inscricao-acoes__botao' onClick={() => acessarInscricao(inscricao.inscricaoId)}>
                                            <i className='inscricao-acoes__icone far fa-home'></i> Acessar Inscrição
                                          </button>
                                        </li>
                                        <li className='inscricao-acoes__item'>
                                          <button className='inscricao-acoes__botao' disabled={true}>
                                            <i className='inscricao-acoes__icone far fa-history'></i> Histórico
                                          </button>
                                        </li>
                                      </ul>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                        { inscricoes.modelo.length == 0 && (
                          <tr>
                            <td colSpan={6}>Nenhuma inscrição foi encontrada</td>
                          </tr>
                        )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <SeletorDePagina
                key={`pagination-${resetarPaginacao}`}
                totalDePaginas={inscricoes.totalDePaginas}
                quantidadeMaximaDeBotoesVisiveis={5}
                desativado={false}
                alterarPagina={irPara}
                alinhamento={'direita'}
                cor='terciaria' />
              <span className='is-cor-cinza-20 sumario-paginacao'>{inscricoes.totalDeRegistros} resultado(s)</span>
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default ListagemDeInscricoes;