import * as actionTypes from './actionTypes';
import IImovelProprio from '../interfaces/IImovelProprio';

export const sucessoAoCarregarImovel = (imoveis: IImovelProprio) => ({
  type: actionTypes.CARREGAR_IMOVEIS_SUCESSO,
  state: imoveis
});

export const carregarImoveis = (imoveis: IImovelProprio[]) => ({
  type: actionTypes.CARREGAR_IMOVEIS,
  state: imoveis
});

export const excluirImovel = (id: number) => ({
  type: actionTypes.EXCLUIR_IMOVEIS_SUCESSO,
  state: id
});