import * as actionTypes from './actionTypes';
import { apiDaInscricao } from '../servicos/axios';
import { ACESSO_ADMINISTRATIVO } from '../helpers/tiposDeAcesso';

export const sucessoAoVerificarConsistenciaDaInscricao = inconsistencias => ({
  type: actionTypes.VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO,
  inconsistencias
});

export const sucessoAoVerificarSePodeGerarComprovanteDeInscricao = podeGerarComprovanteDeInscricao => ({
  type: actionTypes.VERIFICAR_SE_PODE_GERAR_COMPROVANTE_DE_INSCRICAO_SUCESSO,
  podeGerarComprovanteDeInscricao
});

export const buscarInformacoesDoMenu = (idDaInscricao) =>
  async (dispatch) => {
    let informacoesDoMenu;
    if (ACESSO_ADMINISTRATIVO == localStorage.getItem('tipo_acesso'))
      informacoesDoMenu = await apiDaInscricao().get(`/menudeinscricao/${idDaInscricao}/inconsistencias`);
    else
      informacoesDoMenu = await apiDaInscricao().get(`/menu/${idDaInscricao}/informacoes`);

    const { data: { consistenciaDaInscricao: { inconsistencias }, podeGerarComprovanteDeInscricao } } = informacoesDoMenu;
    dispatch(sucessoAoVerificarConsistenciaDaInscricao(inconsistencias));
    dispatch(sucessoAoVerificarSePodeGerarComprovanteDeInscricao(podeGerarComprovanteDeInscricao));
  };