import IPretendente from '../interfaces/IPretendente';
import { apiDaInscricao, axiosComLoading } from './axios';

const obterInformacoesNoServicoDeConsultaDeCpf = async (cpf: string) => {
  const resposta = await axiosComLoading.get(`${process.env.REACT_APP_URL_API}/pretendentes/${cpf}/informacoesDoPretendente`,
    { headers: { Authorization: '06E3C24A-F71F-4F22-A1F6-7EBE56E6BE35' } }
  );
  return resposta.data;
};

const validarJuncao = async (idInscricao: number, cpf: string)  => {
  const resposta = await apiDaInscricao().get(`/conjuge/${idInscricao}/validarJuncao/${cpf}`);
  return resposta.data;
};

const verificarSePermiteCadastro = async (idInscricao: number, cpf: string)  => {
  const resposta = await apiDaInscricao().get(`/conjuge/${idInscricao}/verificarSePermiteCadastro?cpf=${cpf}`);
  return resposta.data; 
};

const obterPorCpf = async (cpf: string) => {
  const resposta = await apiDaInscricao().get(`/pretendentes/${cpf}/obter`);
  return resposta.data;
};

const juntarInscricoes = async (idInscricaoOrigem: number, idInscricaoDestino: number, pretendente: IPretendente) => {
  await apiDaInscricao().post('/JuncaoDeInscricao/juntar/', {idInscricaoOrigem, idInscricaoDestino, conjuge : pretendente});
};

export { obterInformacoesNoServicoDeConsultaDeCpf, validarJuncao, verificarSePermiteCadastro, obterPorCpf, juntarInscricoes };