import { apiDaInscricao } from './axios';
import IProgramasSociais from '../interfaces/IProgramasSociais';
import { salvarProgramasSociaisSucesso } from '../actions/programasSociais.actions';
import { Dispatch } from 'redux';

export const salvarProgramasSociais = async (
  programasSociais: IProgramasSociais, idDaInscricao: Number, dispatch: Dispatch) => {

  await apiDaInscricao().put(`/informacaoDaFamilia/${idDaInscricao}/programasSociais`, programasSociais.programasSelecionados);
  dispatch(salvarProgramasSociaisSucesso(programasSociais));
};