import IDependente from '../interfaces/IDependente';
import { apiDaInscricao, apiDaInscricaoSemLoading } from './axios';

export const obterDependentes = async (idDaInscricao: number) => {
  const response = await apiDaInscricao().get(`/dependente/${idDaInscricao}/obter`);
  return response.data;
};

export const salvarDependente = async (dependente: IDependente) => {
  const body = { ...dependente };
  await apiDaInscricaoSemLoading().post(`/dependente/${dependente.idInscricao}/salvar`, body);
};

export const editarDependente = async (dependente: IDependente) => {
  const body = { ...dependente };
  await apiDaInscricaoSemLoading().put(`/dependente/${dependente.idInscricao}/editar`, body);
};

export const excluirDependente = async (idDoDependente: number, idDaInscricao: number) => {
  await apiDaInscricao().delete(`/dependente/${idDaInscricao}/deletar/${idDoDependente}`);
};