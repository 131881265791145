import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/index.reducer';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

const configureStore = (history, initialState) => {
  const middleware = [thunk, routerMiddleware(history)];

  return createStore((state, action) => rootReducer(state, action, history),
    initialState, applyMiddleware(...middleware));
};

export default configureStore;
