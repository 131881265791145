interface IGrauDeParentesco {
    value: string,
    label: string
}

const grausDeParentesco: IGrauDeParentesco[] = [
  { value: 'Avo', label: 'Avô/avó' },
  { value: 'Enteado', label: 'Enteado(a)' },
  { value: 'Filho', label: 'Filho(a)' },
  { value: 'Irmao', label: 'Irmão/irmã' },
  { value: 'Neto', label: 'Neto(a)' },
  { value: 'Pais', label: 'Pai/mãe' },
  { value: 'Sobrinho', label: 'Sobrinho(a)' },
  { value: 'Sogro', label: 'Sogro(a)' },
  { value: 'Tio', label: 'Tio(a)' }
];

export default grausDeParentesco;