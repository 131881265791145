import { CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO } from './actionTypes';
import IGrauDeInstrucao from '../interfaces/IGrauDeInstrucao';
import { AnyAction, Dispatch } from 'redux';
import { apiDaInscricao } from '../servicos/axios';

export const carregarGrauDeInstrucaoSucesso = (grauDeInstrucoes: IGrauDeInstrucao[]) => {
  return {
    type: CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO,
    state: grauDeInstrucoes
  };
};

export const carregarGrauDeInstrucoes = () =>
  async (dispatch: Dispatch<AnyAction>) => {
    const { data: grauDeInstrucoes } = await apiDaInscricao().get('/grauDeInstrucao/ObterTodos');
    dispatch(carregarGrauDeInstrucaoSucesso(grauDeInstrucoes));
  };