import React from 'react';
import '../../estilos/estilos.scss';

interface IIndicadorPassoProps {
  iconeDoPasso: string,
  titulo: string,
  descricao: string
}

const IndicadorPasso = ({ iconeDoPasso, titulo, descricao }: IIndicadorPassoProps) =>
  (<div className="cabecalho-do-formulario">
    <div className="cabecalho-do-formulario__passo">
      <i className={`fal ${iconeDoPasso}`} aria-hidden="true"></i>
    </div>
    <div className="cabecalho-do-formulario__titulo">
      {titulo}
    </div>
    <div className="cabecalho-do-formulario__descricao">
      {descricao}
    </div>
  </div>);

export default IndicadorPasso;