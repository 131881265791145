import { push } from 'connected-react-router';
import {
  CARREGAR_ID_INSCRICAO,
  CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO,
  EFETUAR_LOGIN_SUCESSO
} from './actionTypes';
import { carregarTitular } from './dadosTitular.actions';
import { carregarConjuge } from './dadosConjuge.actions';
import { carregarDependentes } from './dependente.actions';
import { carregarMoradia } from './moradia.actions';
import { ehNovaInscricao } from './ehNovaInscricao.actions';
import { carregarImoveis } from './imoveis.actions';
import { carregarInformacoesDaFamilia } from './informacoesDaFamilia.actions';
import { carregarProgramasSociais } from './programasSociais.actions';
import { autenticar } from '../servicos/autenticacao';
import { consultarExistenciaDeInscricao } from '../servicos/inscricao';
import { obterInformacoesNoServicoDeConsultaDeCpf } from '../servicos/pretendente';
import { obteveInformacoesDoTitular } from './dadosTitular.actions';
import { verificarSeEhAmbienteDeProducao } from '../helpers/verificadorDeAmbientes';

export const efetuarLoginSucesso = dadosLogin =>
  ({ type: EFETUAR_LOGIN_SUCESSO, dadosLogin });

export const iniciarCadastroDeNovaInscricao = dadosDoCadastro =>
  async dispatch => {
    const cpf = dadosDoCadastro.cpf.replace(/\.|-/g, '');
    const resultadoDaConsulta = await consultarExistenciaDeInscricao(cpf, dispatch);
    
    if (resultadoDaConsulta.possuiInscricao) {
      // eslint-disable-next-line no-throw-literal
      throw {
        response: {
          data: resultadoDaConsulta.mensagem
        }
      };
    }
    
    const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();  
    let informacoesDoCpf = {};
    if(ehAmbienteDeProducao){
      informacoesDoCpf = await obterInformacoesNoServicoDeConsultaDeCpf(cpf);
      dispatch(obteveInformacoesDoTitular(informacoesDoCpf));
    }

    const inscricao = await autenticar({...dadosDoCadastro, nome: informacoesDoCpf.nome, dataDeNascimento: informacoesDoCpf.dataDeNascimento});

    dispatch(efetuarLoginSucesso({
      cidade: dadosDoCadastro.cidade,
      cpf
    }));

    dispatch(carregarInscricao(inscricao, cpf, dadosDoCadastro));
  };

export const carregarIdInscricao = id =>
  ({ type: CARREGAR_ID_INSCRICAO, id });

export const carregarDadosGeraisDaInscricao = inscricao =>
  ({ type: CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO, inscricao });

export const carregarInscricao = (inscricao, cpfDoTitular, dadosDoCadastro) =>
  async dispatch => {
    if (!inscricao) {
      dispatch(ehNovaInscricao());
      dispatch(push('/aviso-documentos', {cpfDoTitular, dadosDoCadastro}));
      return;
    }
    dispatch(carregarDadosGeraisDaInscricao(inscricao));
    dispatch(carregarTitular(inscricao.titular));

    if (inscricao.conjuge)
      dispatch(carregarConjuge(inscricao.conjuge));

    dispatch(carregarMoradia(inscricao.moradia));

    let informacoesDaFamilia = {
      ...inscricao.informacoesDaFamilia,
      demandasEspecificasDaInscricao: inscricao.demandasEspecificas
    };

    if (informacoesDaFamilia)
      dispatch(carregarInformacoesDaFamilia(informacoesDaFamilia));
        
    let informacoesDeProgramasSociais = {
      possuiBeneficioDeProgramaSocial: inscricao.programasSociais.length > 0 ? true : false,
      programasSelecionados: inscricao.programasSociais
    };
    
    if(informacoesDeProgramasSociais)
      dispatch(carregarProgramasSociais(informacoesDeProgramasSociais));
   
    if (inscricao.imoveisProprios.length)
      dispatch(carregarImoveis(inscricao.imoveisProprios));

    dispatch(carregarDependentes(inscricao.dependentes));
  };