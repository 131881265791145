import React from 'react';
import { Link } from 'react-router-dom';

interface itemMenuProps {
  habilitado: boolean,
  icone: string,
  link: {
    href: string,
    texto: string,
    onClick?: () => void
  },
  descricao?: string,
  itemDestaque: boolean,
  descricaoMaiuscula: boolean
}

const ItemMenu = ({ habilitado, icone, link, descricao, itemDestaque, descricaoMaiuscula }: itemMenuProps) => {
  return (<Link
    className={`menu-de-inscricao__item 
    ${!habilitado ? 'menu-de-inscricao__item_desativado' : ''} 
    ${itemDestaque ? 'menu-de-inscricao__item_destaque' : ''}`}
    to={link.href}
    onClick={link.onClick}>
    <div className="menu-de-inscricao__conteudo">
      <div className='menu-de-inscricao__numero-da-categoria'>
        <i className={`fal ${icone}`} aria-hidden="true" />
        <span className={'menu-de-inscricao__icone '}>
          <span />
        </span>
      </div>
      <div className="menu-de-inscricao__texto-do-conteudo">
        <h3 className='menu-de-inscricao__titulo-do-conteudo u-texto-negrito' data-test-id={link.texto}>{link.texto}</h3>
        <p className={`menu-de-inscricao__descricao-do-conteudo ${descricaoMaiuscula ? 'u-texto-maiusculo' : ''}`}>{descricao || ''}</p>
      </div>
    </div>
    <div className="menu-de-inscricao__icone-direita">
      {habilitado &&
        <i className='fal fa-chevron-right fa_cor-secundaria' aria-hidden="true"></i>}
    </div>
  </Link>);
};

export default ItemMenu;