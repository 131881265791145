import React, { useEffect, useState } from 'react';
import '../../estilos/estilos.scss';
import { Header } from '../../componentes';
import mascaras from '../../helpers/mascaras';
import initialState from '../../reducers/initialState.reducer';
import { useDispatch, useSelector } from 'react-redux';
import IInscricao from '../../interfaces/IInscricao';
import IPretendente from '../../interfaces/IPretendente';
import IMoradia from '../../interfaces/IMoradia';
import { push } from 'connected-react-router';
import { obterDadosGeraisDaInscricao } from '../../servicos/MenuDeInscricao';
import IDadosGeraisDaInscricao from '../../interfaces/IDadosGeraisDaInscricao';
import IInformacaoDeCriacaoEAtualizacao from '../../interfaces/IInformacaoDeCriacaoEAtualizacao';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';

const Comprovante = () => {

  const inscricao = useSelector((state: typeof initialState) => state.inscricao) as IInscricao;
  const dadosDoPretendente = useSelector((state: typeof initialState) => state.dadosTitular) as IPretendente;
  const dadosDoConjuge = useSelector((state: typeof initialState) => state.dadosConjuge) as IPretendente;
  const naoTemDadosDoConjuge = Object.keys(dadosDoConjuge).length === 0;
  const moradia =  useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const [dadosGeraisDaInscricao, setDadosGeraisDaInscricao] = useState<IDadosGeraisDaInscricao>();
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const cidades = useSelector((state: typeof initialState) => state.cidades);

  const dispatch = useDispatch();

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  
  const logo_cabecalho_direita = process.env.REACT_APP_URL_LOGO_CABECALHO_COMPROVANTE_DIREITA;
  const logo_cabecalho_esquerda = process.env.REACT_APP_URL_LOGO_CABECALHO_COMPROVANTE_ESQUERDA;

  const localDeAtendimento = (dadosGerais: IInformacaoDeCriacaoEAtualizacao) => {
    if (dadosGerais.tipoDeAcesso.toLowerCase() =='publico')
      return 'Internet';
    else
      return dadosGerais.localDeAtendimento;
  };

  const atendentePor = (dadosGerais: IInformacaoDeCriacaoEAtualizacao) => {
    if (dadosGerais.tipoDeAcesso.toLowerCase() =='publico')
      return 'Autoatendimento';
    else
      return dadosGerais.usuario;
  };

  const dadosGeraisInformacoesDeCriacao = dadosGeraisDaInscricao?.dadosGeraisDeInformacoesDeCriacao;
  const dadosGeraisInformacoesDeAtualizacao = dadosGeraisDaInscricao?.dadosGeraisDeInformacoesDeAtualizacao;

  const dadoCriadoEm = dadosGeraisInformacoesDeCriacao ? `${dadosGeraisInformacoesDeCriacao?.data} / ${localDeAtendimento(dadosGeraisInformacoesDeCriacao)}`: '';
  const dadoAtualizadoEm = dadosGeraisInformacoesDeAtualizacao ? `${dadosGeraisInformacoesDeAtualizacao?.data} / ${localDeAtendimento(dadosGeraisInformacoesDeAtualizacao)}`: '';
  const atendidoPor = dadosGeraisInformacoesDeAtualizacao ? atendentePor(dadosGeraisInformacoesDeAtualizacao) : dadosGeraisInformacoesDeCriacao && atendentePor(dadosGeraisInformacoesDeCriacao);

  useEffect(()  =>  {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
    
    obterDadosGeraisDaInscricao(dadosDoPretendente.cpf, ehAcessoAdministrativo).then(resposta => {
      setDadosGeraisDaInscricao(resposta.data);
    });
  }, []);

  const imprimir = () => {
    window.print();
  };
  const nomeCidade = () => {
    //@ts-ignore
    return moradia?.endereco?.cidade?.nome ? moradia.endereco.cidade.nome : cidades.filter((c: any) => c.id == moradia.endereco.cidade.id)[0]?.nome || '';
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) && 
      <div className="pagina-comprovante">
        {!ehAcessoAdministrativo && (
        // @ts-ignore
          <Header subTitulo="Comprovante de inscrição"
            comBotaoVoltar={true} urlBotaoVoltar={'/menu-inscricao'} />
        )}
        {ehAcessoAdministrativo && (
          <BarraDeNavegacao
            titulo="Comprovante de inscrição"
            comBotaoVoltar={true}
            acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
            comBotaoADireita={true}
            textoBotaoADireita="Menu de inscrição"
            iconeBotaoADireita={false}
            acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
          />
        )}
        <div className="container__botao-imprimir">
          <button onClick={imprimir} className="botao botao_cor-terciaria botao_medio botao_com-icone-para-esquerda">
            <i className="fal fa-print" />Imprimir ou salvar comprovante
          </button>
        </div>

        <div className="pagina-comprovante__container">
          <header className="comprovante__topo">
            <div className="comprovante-topo__logos">
              <img src={logo_cabecalho_direita} alt='logomarca da direta'/>
              <img src={logo_cabecalho_esquerda} alt='logomarca da esquerda'/>
            </div>
            <div className="comprovante-topo__cabecalho">
              <h1 className="comprovante-cabecalho__titulo">Comprovante de inscrição</h1>
              <div>
                <span className="comprovante-inscricao__enunciado">Nº da inscrição: </span>
                <span className="comprovante-inscricao__numero"> {inscricao.numero}</span>
              </div>
            </div>
          </header>
          <section className="comprovante__corpo">
            <section className="comprovante__secao">
              <div className="comprovante__linha comprovante-secao__municipio">
                <div><span className="titulo">Município: </span>{nomeCidade()}</div>
                <div><span className="titulo">UF: </span>{moradia.endereco.estado?.uf}</div>
              </div>
            </section>
            <section>
              <div className="comprovante__linha ">
                <div className="comprovante__secao comprovante-linha__coluna comprovante-secao__pretendente">
                  <div className="titulo">Titular</div>
                  <div><span className="item">CPF:  {mascaras.aplicarMascaraDeCpf(dadosDoPretendente.cpf)}</span></div>
                  <div><span className="item">Nome: </span> {dadosDoPretendente.nome}</div>
                  <div><span className="item">Data de nascimento: </span> {dadosDoPretendente.dataDeNascimento?.toString()}</div>
                  <div><span className="item">NIS: </span> {mascaras.aplicarMascaraDeNis(dadosDoPretendente.nis)}</div>
                  <div><span className="item">Telefone Celular: </span> {mascaras.aplicarMascaraDeTelefone(dadosDoPretendente.contato?.telefoneCelular)}</div>
                  <div><span className="item">Telefone Recado: </span> {mascaras.aplicarMascaraDeTelefone(dadosDoPretendente.contato?.telefoneParaRecado)}</div>
                  <div><span className="item">E-mail: </span> {dadosDoPretendente.contato?.email}</div>
                </div>
                {!naoTemDadosDoConjuge &&
                <div className="comprovante__secao comprovante-linha__coluna comprovante-secao__pretendente secao__conjuge">
                  <div className="titulo">Cônjuge</div>
                  <div><span className="item">CPF: {mascaras.aplicarMascaraDeCpf(dadosDoConjuge.cpf)}</span></div>
                  <div><span className="item">Nome: </span> {dadosDoConjuge.nome}</div>
                  <div><span className="item">Data de nascimento: </span> {dadosDoConjuge.dataDeNascimento?.toString()}</div>
                  <div><span className="item">NIS: </span> {mascaras.aplicarMascaraDeNis(dadosDoConjuge.nis)}</div>
                  <div><span className="item">Telefone Celular: </span> {mascaras.aplicarMascaraDeTelefone(dadosDoConjuge.contato?.telefoneCelular)}</div>
                  <div><span className="item">Telefone Recado: </span> {mascaras.aplicarMascaraDeTelefone(dadosDoConjuge.contato?.telefoneParaRecado)}</div>
                  <div><span className="item">E-mail: </span> {dadosDoConjuge.contato?.email}</div>
                </div> 
                } 
              </div>
            </section>
            <section className="comprovante__secao">
              <div className="comprovante-linha__coluna comprovante-secao__endereco">
                <div className="titulo">Endereço</div>
                {moradia.endereco.logradouro && <> 
                  <div>{moradia.endereco.logradouro}, {moradia.endereco.numero} {moradia.endereco.complemento}, {moradia.endereco.bairro}, {nomeCidade()}-{moradia.endereco.estado?.uf}, CEP: {moradia.endereco.cep}</div>
                  <div><span className="item">Complemento: </span>{moradia.endereco.complemento}</div>
                </>}
              </div>
            </section>

            <section className="comprovante__secao comprovante__informacoes">
              <p className='paragrafo'>
                O(s) <strong>pretendentes(s)</strong> cadastrado no sistema eletrônico desenvolvido pela Agência de Habilitação Popular do Estado de Mato Grosso do Sul - <strong>AGEHAB</strong> fica ciente que:
              </p>

              <ol className="comprovante-informacoes__lista-ordenada">
                <li>A Inscrição é <strong>totalmente gratuita</strong>;</li>
                <li>A ordem cronológica de inscrição (tempo de inscrição) não é critério de seleção;</li>
                <li>O comprovante de inscrição compartilhada apenas confirma o cadastramento, não gerando direitos;</li>
                <li>O imóvel pretendido deverá ser destinado para a sua <strong>moradia</strong> e de seus familiares;</li>
                <li>Não poderá ser proprietário de imóvel residencial;</li>
                <li>Deverá manter seus dados atualizados para participar dos eventuais processos de seleção;</li>
                <li>As informações prestadas deverão ser comprovadas no processo de seleção;</li>
                <li>Os programas habitacionais ofertados poderão ter outras exigências complementares;</li>
                <li>Ficará responsável por todas as informações prestadas, e que dados falsos o eliminará automaticamente dos programas habitacionais do Governo do Estado de MS.</li>
              </ol>
            </section>
                        
            <section className="comprovante__secao  comprovante-secao__atendimento">
              <div className="titulo">Atendimento</div>
              <div className="item"><span>Inscrito em: </span> <strong>{dadoCriadoEm}</strong> </div>
              <div className="item"><span>Atualizado em: </span> <strong>{dadoAtualizadoEm}</strong> </div>
              <div className="item"><span>Atendente: </span> {atendidoPor} </div>
            </section>
          </section>
        </div>
      </div>
      }
    </>
  );
};
export default Comprovante;