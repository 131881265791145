import { EH_NOVA_INSCRICAO, LIMPAR_EH_NOVA_INSCRICAO } from '../actions/actionTypes';
import initialState from './initialState.reducer';

export default (state = initialState.ehNovaInscricao, action) => {
  switch (action.type) {
  case EH_NOVA_INSCRICAO:
    return true;
  case LIMPAR_EH_NOVA_INSCRICAO:
    return false;
  default:
    return state;
  }
};
