import React, { ReactNode } from 'react';
import '../../estilos/estilos.scss';

interface ContainerRodapeProps {
    children: ReactNode,
    comBotoesDistribuidos?: boolean
}

const ContainerRodape = ({ children, comBotoesDistribuidos }: ContainerRodapeProps) => (
  <footer className={`container__rodape ${comBotoesDistribuidos ? 'container__rodape_com-botoes-distribuidos' : ''}`}>
    {children}
  </footer>
);

export default ContainerRodape;