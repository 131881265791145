import React from 'react';
import './styles.scss';

const MenuDeTrocaDeModulos = () => {
  const redirecionarParaModuloSelecionado = (moduloSelecionado: string) => {
    const urlBase = process.env.REACT_APP_URL_BASE_DOS_MODULOS;

    window.location.href = `${urlBase}/${moduloSelecionado}`;
  };

  return (
    <>
      <li className='dropdown dropdown_direita'>
        <a className='cabecalho-sistema__item-de-menu selecao-de-modulos__item-de-menu' aria-haspopup='true'>
          Inscrição <i className='far fa-angle-down u-margem-esquerda-pequena'></i>
        </a>

        <div className='selecao-de-modulos__caixa'>
          <div className='selecao-de-modulos__lista'>
            <div className='selecao-de-modulos__cabecalho'>
              <h5 className='titulo_mini'>Navegar entre módulos:</h5>
            </div>
            <ul className='selecao-de-modulos__lista-de-itens'>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Administracao')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-dollar-sign' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Administração</span>
                  <p>Gestão de contratos e créditos imobiliários</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Contemplados')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-user' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Contemplados</span>
                  <p>Identifica as pessoas que já foram beneficiadas em programas	habitacionais</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Inscricaoweb')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-users' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Inscrição</span>
                  <p>Possibilita a realização e atualização do cadastro das famílias interessadas</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Selecao')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-redo-alt' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Seleção</span>
                  <p>Classifica as famílias de acordo com os critérios e prioridades definidos em lei</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Seguranca')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-shield-check' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Segurança</span>
                  <p>Cadastro de usuários, senhas e permissões de acesso</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('Tramitacao')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-copy' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Tramitação</span>
                  <p>Possibilita o trâmite de processos internos entre setores</p>
                </div>
              </li>
              <li className='selecao-de-modulos__item' onClick={() => redirecionarParaModuloSelecionado('ControleDeSaldo')}>
                <span className='selecao-de-modulos__icone-lista'>
                  <i className='fal fa-chart-line' aria-hidden='true'></i>
                </span>
                <div className='selecao-de-modulos__nome-lista'>
                  <span>Controle de saldo</span>
                  <p>Gestão de saldos disponíveis para programa habitacionais</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </>
  );
};

export default MenuDeTrocaDeModulos;
