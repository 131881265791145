import * as actionTypes from '../actions/actionTypes';
import IActionType from '../interfaces/IActionType';
import IDependente from '../interfaces/IDependente';
import initialState from './initialState.reducer';

export default (state = initialState.dependentes, action: IActionType<IDependente[]>) => {
  switch (action.type) {
  case actionTypes.CARREGAR_DEPENDENTES_SUCESSO:
  case actionTypes.CARREGAR_DEPENDENTES:
    return [...action.state];
  case actionTypes.EXCLUIR_DEPENDENTE_SUCESSO:
    return [
      //@ts-ignore
      ...state.filter((dependente: IDependente) => dependente.id !== action.state)
    ];
  default:
    return state;
  }
};