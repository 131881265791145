import React from 'react';
import CampoRadioButton from '../../../CampoRadioButton';

interface IPerguntasDoHabiteSeguroProps {

    atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica?: boolean,
    aceitaParticiparDoHabiteSeguro?: boolean,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: () => void,
    disabled: boolean
}

const PerguntasDoHabiteSeguro = ({ atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica, aceitaParticiparDoHabiteSeguro,
  onChange, onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica, disabled }: IPerguntasDoHabiteSeguroProps) => {

  return (
    <div>
      <div className="grade__linha">
        <div className="grade__coluna">
          <fieldset className="formulario__grupo-de-inputs">
            <legend className="formulario__label">Atua há mais de 3 anos em profissão de segurança pública?</legend>
            <CampoRadioButton
              mesmaLinha
              name="atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica"
              value="true"
              label="Sim"
              checked={atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica != undefined ? atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica.toString() : ''}
              onChange={onChange}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica"
              value="false"
              label="Não"
              checked={atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica != undefined ? atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica.toString() : ''}
              onChange={onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica}
              disabled={disabled} />
          </fieldset>
        </div>
      </div>

      {atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica &&
      <div className="grade__linha">
        <div className="grade__coluna">
          <fieldset className="formulario__grupo-de-inpus">
            <legend className="formulario__label">
              Programa Habite Seguro
              <p className="formulario__descricao">
                Autorizo o repasse das informações descritas nessa inscrição para a Secretaria de Segurança, a fim de me enquadrar no Programa Habite Seguro
              </p>
            </legend>
            <CampoRadioButton
              mesmaLinha
              name="aceitaParticiparDoHabiteSeguro"
              value="true"
              label="Sim, Autorizo"
              checked={aceitaParticiparDoHabiteSeguro != undefined ? aceitaParticiparDoHabiteSeguro.toString(): ''}
              onChange={onChange}
              disabled={disabled} />
            <CampoRadioButton
              mesmaLinha
              name="aceitaParticiparDoHabiteSeguro"
              value="false"
              label="Não Autorizo"
              checked={aceitaParticiparDoHabiteSeguro != undefined ? aceitaParticiparDoHabiteSeguro.toString(): ''}
              onChange={onChange}
              disabled={disabled} />
          </fieldset>
        </div>
      </div>
      }
    </div>
  );
};

export default PerguntasDoHabiteSeguro;