import { carregarProfissoes } from './profissao.actions';
import { carregarDistritos } from './distrito.actions';
import { carregarCidades } from './cidade.actions';
import { carregarGrauDeInstrucoes } from './grauDeInstrucao.actions';

import { carregarRegimeDeCasamentoSucesso } from './regimeDeCasamento.actions';
import { carregarRegimesDeCasamento } from '../servicos/regimeDeCasamento';

import { carregarEtniaSucesso } from './etnia.actions';
import { carregarEtnias } from '../servicos/etnia';

import { obterQuestoesDoFormulario } from '../servicos/questoesDoFormulario';
import { carregarQuestoesDoFormulario } from './questoesDoFormulario.actions';
import { obterDemandasEspecificas } from '../servicos/demandaEspecifica';
import { carregarDemandasEspecificas } from './demandasEspecificas.actions';
import { carregarTiposDeDeficiencia } from './tiposDeDeficiencia.actions';
import { obterTiposDeDeficiencia } from '../servicos/tiposDeDeficiencia';

export const cargaInicial = () =>
  async dispatch => {
    dispatch(carregarCidades());
    dispatch(carregarProfissoes());
    dispatch(carregarDistritos());
    dispatch(carregarGrauDeInstrucoes());
    dispatch(carregarRegimeDeCasamentoSucesso(await carregarRegimesDeCasamento()));
    dispatch(carregarEtniaSucesso(await carregarEtnias()));
    dispatch(carregarQuestoesDoFormulario(await obterQuestoesDoFormulario()));
    dispatch(carregarDemandasEspecificas(await obterDemandasEspecificas()));
    dispatch(carregarTiposDeDeficiencia(await obterTiposDeDeficiencia()));
  };