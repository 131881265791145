import { ADICIONAR_CIDADES } from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import ICidade from '../interfaces/ICidade';

export default (state = initialState.cidadesDoBrasil, action: IActionType<ICidade[]>) => {
  switch (action.type) {
  case ADICIONAR_CIDADES:{
    return [...state, ...action.state];
  }
  default:
    return state;
  }
};