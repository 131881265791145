import React from 'react';
//@ts-ignore
import Select from 'react-select';
import PerguntasDoHabiteSeguro from '../PerguntasDoHabiteSeguro';
import MensagemDeErro from '../../../MensagemDeErro';
import { mapearProfissoesParaSelect } from '../../../../selectors';
import IPretendente from '../../../../interfaces/IPretendente';
import IProfissao from '../../../../interfaces/IProfissao';
import { IPretendenteValidacaoErroCampos } from '../../../../interfaces/IPretendenteValidacao';
import CampoDeValorEmReais from '../../../CampoDeValorEmReais';
import IGrauDeInstrucao from '../../../../interfaces/IGrauDeInstrucao';
import { mapearGrauDeInstrucaoParaSelect } from '../../../../selectors/grauDeInstrucoes';

const situacoesNoMercadoDeTrabalho = [
  { label: 'Aposentado(a)', value: 'Aposentado' },
  { label: 'Empregado(a) no setor privado', value: 'EmpregadoNoSetorPrivado' },
  { label: 'Autônomo(a)', value: 'Autonomo' },
  { label: 'Pensionista', value: 'Pensionista' },
  { label: 'Servidor(a) público Federal', value: 'ServidorPublicoFederal' },
  { label: 'Servidor(a) público Estadual', value: 'ServidorPublicoEstadual' },
  { label: 'Servidor(a) público Municipal', value: 'ServidorPublicoMunicipal' },
  { label: 'Desempregado(a)', value: 'Desempregado' },
  { label: 'Benefício de Prestação Continuada -BPC- LOAS', value: 'BeneficioDePrestacaoContinuadaLoas' }
];

interface IInputEventsProps {
  onChange: (event: IEventOnChange) => void,
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface IEventOnChange {
  target: { name: string, value: string }
}

interface ICampoMercadoDeTrabalhoProps {
  dadosPretendente: IPretendente,
  profissoes: IProfissao[],
  erros?: IPretendenteValidacaoErroCampos,
  inputEvents: IInputEventsProps,
  grauDeInstrucoes: IGrauDeInstrucao[],
  ehFormularioDeConjuge: boolean,
  onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: () => void,
  onChangeProfissao: (profissaoSelecionada?: IProfissao) => void,
  onChangeGrauDeInstrucao: (grauDeInstrucao?: IGrauDeInstrucao) => void,
  disabled: boolean
}


const CampoMercadoDeTrabalho = ({ dadosPretendente, profissoes, grauDeInstrucoes, ehFormularioDeConjuge, 
  erros, inputEvents, onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica, onChangeProfissao, 
  onChangeGrauDeInstrucao, disabled }: ICampoMercadoDeTrabalhoProps) => {

  const obterProfissaoPorId = (profissaoSelecionada?: IProfissao) => {
    if (profissaoSelecionada)
      return profissoes.find(profissao => profissao.id == profissaoSelecionada.id);

    return null;
  };

  const tratarSelecaoDaProfissao = (profissaoSelecionada: { value: number, label: string, ehSegurancaPublica: boolean }) => {
    if (profissaoSelecionada) {
      const profissao: IProfissao = { id: profissaoSelecionada.value, nome: profissaoSelecionada.label, ehSegurancaPublica: profissaoSelecionada.ehSegurancaPublica };
      onChangeProfissao(profissao);
    } else
      onChangeProfissao(profissaoSelecionada);
  };

  const tratarGrauDeInstrucao = (grauDeInstrucaoSelecionado: { value: number, label: string }) => {
    if (grauDeInstrucaoSelecionado) {
      const grauDeInstrucao: IGrauDeInstrucao = { id: grauDeInstrucaoSelecionado.value, nome: grauDeInstrucaoSelecionado.label  };
      onChangeGrauDeInstrucao(grauDeInstrucao);
    } else
      onChangeGrauDeInstrucao(grauDeInstrucaoSelecionado);
  };

  const handleOnChange = (selected: { label: string, value: string }) => {
    const target: IEventOnChange = {
      target: {
        name: 'situacaoNoMercadoDeTrabalho',
        value: !!selected ? selected.value : ''
      }
    };
    inputEvents.onChange(target);
  };

  const situacaoNoMercadoDeTrabalho = dadosPretendente.situacaoNoMercadoDeTrabalho;
  const mostrarValorDaRenda = situacaoNoMercadoDeTrabalho && situacaoNoMercadoDeTrabalho !== 'Desempregado';
  const profissaoSelecionada = obterProfissaoPorId(dadosPretendente.profissao);
  const mostrarPerguntasDoHabiteSeguro = profissaoSelecionada && profissaoSelecionada.ehSegurancaPublica;

  return (
    <>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erros?.grauDeInstrucao && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Grau de instrução
            </label>
            {erros?.grauDeInstrucao && <MensagemDeErro campo='grauDeInstrucao' />}
            <Select
              name="grauDeInstrucao"
              id="grauDeInstrucao"
              placeholder="Selecione uma opção"
              value={dadosPretendente.grauDeInstrucao?.id}
              options={mapearGrauDeInstrucaoParaSelect(grauDeInstrucoes)}
              onChange={tratarGrauDeInstrucao}
              disabled={disabled} />
          </div>
        </div>
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erros?.situacaoNoMercadoDeTrabalho && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Ocupação no mercado de trabalho
            </label>
            {erros?.situacaoNoMercadoDeTrabalho &&
              <MensagemDeErro />}
            <Select
              placeholder="Selecione uma opção"
              value={situacaoNoMercadoDeTrabalho}
              options={situacoesNoMercadoDeTrabalho}
              onChange={handleOnChange}
              disabled={disabled} />
          </div>
        </div>
      </div>

      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <div className="grade__coluna grade__coluna_6">
          <div className={`formulario__input-react-select ${erros?.profissao && 'formulario__input-react-select_erro'}`}>
            <label className="formulario__label">
              Profissão
            </label>
            {erros?.profissao &&
              <MensagemDeErro />}
            <Select
              placeholder="Selecione uma profissão"
              value={dadosPretendente.profissao?.id}
              options={mapearProfissoesParaSelect(profissoes)}
              onChange={tratarSelecaoDaProfissao}
              disabled={disabled} />
          </div>
        </div>
        {mostrarValorDaRenda &&
          <div className="grade__coluna_6">
            <div className={`formulario__input-react-select ${erros?.valorDaRenda && 'formulario__input-react-select_erro'}`}>
              <CampoDeValorEmReais
                name="valorDaRenda"
                label={ ehFormularioDeConjuge ? 'Renda bruta do cônjuge' : 'Renda bruta do titular'}
                value={dadosPretendente.valorDaRenda || 0}
                erro={erros?.valorDaRenda}
                disabled={disabled}
                {...inputEvents} />
            </div>
          </div>}
      </div>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        {mostrarPerguntasDoHabiteSeguro &&
          <PerguntasDoHabiteSeguro
            atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica={dadosPretendente.atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica}
            aceitaParticiparDoHabiteSeguro={dadosPretendente.aceitaParticiparDoHabiteSeguro}
            onChange={inputEvents.onChange}
            onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica={onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica}
            disabled={disabled} />}
      </div>
    </>
  );
};

export default CampoMercadoDeTrabalho;