import IPretendente from '../interfaces/IPretendente';
import { apiDaInscricao } from './axios';

export const salvarConjuge = async (dadosConjuge: IPretendente, cpf: string, idDaInscricao: number, ehAcessoAdministrativo: boolean) => {
  const conjuge = dadosConjuge;
  const body = { conjuge };

  await apiDaInscricao().post(`/conjuge/${idDaInscricao}/adicionar`, body);

  if (ehAcessoAdministrativo)
    return await apiDaInscricao().get(`/menudeinscricao/${idDaInscricao}`);
  else
    return await apiDaInscricao().get(`/inscricao/${cpf}`);
};

export const editarConjuge = async (dadosConjuge: IPretendente, idDaInscricao: number,ehAcessoAdministrativo: boolean) => {
  const conjuge = dadosConjuge;
  await apiDaInscricao().put(`/conjuge/${idDaInscricao}/editar`, { conjuge });   
};