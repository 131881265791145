import React from 'react';
import MensagemDeErro from '../MensagemDeErro';
//@ts-ignore
import Select from 'react-select';

interface ICampoSexoProps {
    sexo: string,
    onChangeSexo: (event: React.ChangeEvent<HTMLInputElement>) => void,
    error: boolean,
    disabled: boolean
}

interface IEventOnChange {
    target: { name: string, value?: number }
}

const CampoSexo = ({ sexo, onChangeSexo, error, disabled = false }: ICampoSexoProps) => {

  const listaOpcoes = () => {
    const listaAno = [
      { value: 'masculino', label: 'Masculino' },
      { value: 'feminino', label: 'Feminino' }
    ];
    return listaAno;
  };

  const handleOnChange = (selected: { value: number }) => {
    const event: IEventOnChange = {
      target: {
        name: 'sexo',
        value: !!selected ? selected.value : undefined
      }
    };
    //@ts-ignore
    onChangeSexo(event);
  };

  return (
    <div className="grade__coluna grade__coluna_3">
      <fieldset className="formulario__grupo-de-inputs" role="radiogroup" aria-labelledby="genero">
        <legend className="formulario__label">Sexo</legend>
        {error && <MensagemDeErro campo="sexo" />}
        <div className={`formulario__input-react-select ${error && 'formulario__input-react-select_erro'}`}>
          <Select
            id='sexo'
            name='sexo'
            disabled={disabled}
            placeholder={'Selecione'}
            value={sexo}
            options={listaOpcoes()}
            onChange={handleOnChange}
          />
        </div>
      </fieldset>
    </div>
  );
};

export default CampoSexo;