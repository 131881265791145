import React, { SyntheticEvent } from 'react';
//@ts-ignore
import CurrencyInput from 'react-currency-input';
import MensagemDeErro from '../MensagemDeErro';

interface CampoDeValorEmReaisProps {
    name: string,
    colunas?: string,
    label: string,
    link?: string,
    descricaoDoLink?: string,
    descricao?: string,
    children?: React.ReactNode,
    erro?: string,
    disabled: boolean,
    required?: boolean,
    decimalScale?: number,
    value: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

const CampoDeValorEmReais = ({ name, colunas = '', label, link, descricaoDoLink,
  descricao, children, erro, required = true, disabled,
  decimalScale = 2, value,
  onChange, onBlur, onFocus }: CampoDeValorEmReaisProps) => {

  const handleChange = (event: SyntheticEvent, maskedvalue: string, floatvalue: number) => {
    const evento = {
      target: {
        name: name,
        value: floatvalue
      }
    };
    //@ts-ignore
    onChange(evento);
  };

  return (
    <div className={`grade__coluna ${colunas ? `grade__coluna_${colunas}` : 'u-largura-total'}`}>
      <label className="formulario__label" htmlFor={name}>
        {label}
      </label>

      {link &&
      <a target="_blank" rel="noopener noreferrer" className="link link_cor-info link_medio" href={link}>{descricaoDoLink}</a>
      }
      {(descricao || children) &&
      <div className="formulario__descricao">
        {descricao || children}
      </div>}
      {erro &&
      <MensagemDeErro campo={name} mensagem={erro ? erro : 'Campo obrigatório'} />
      }
      <div>
        <CurrencyInput
          id={name}
          name={name}
          value={value}
          onChangeEvent={handleChange}
          decimalSeparator=','
          thousandSeparator='.'
          precision={decimalScale}
          allowNegative={false}
          prefix="R$ "
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          className={`formulario__input ${erro ? 'formulario__input_erro' : ''}`}
          aria-required={required}
          aria-invalid={!!erro}
        />
      </div>
    </div>
  );
};

export default CampoDeValorEmReais;