export const CARREGAR_ID_INSCRICAO = 'CARREGAR_ID_INSCRICAO';
export const CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO = 'CARREGAR_DADOS_GERAIS_DA_INSCRICAO_SUCESSO';

export const EFETUAR_LOGIN_SUCESSO = 'EFETUAR_LOGIN_SUCESSO';
export const LOG_OUT = 'LOG_OUT';

export const SALVAR_TITULAR_SUCESSO = 'SALVAR_TITULAR_SUCESSO';
export const CARREGAR_TITULAR = 'CARREGAR_TITULAR';
export const OBTEVE_INFORMACOES_DO_TITULAR_NO_SERVICO_DE_CONSULTA_DE_CPF = 'OBTEVE_INFORMACOES_DO_TITULAR_NO_CONSULTA_DE_CPF';
export const SALVAR_CONJUGE_SUCESSO = 'SALVAR_CONJUGE_SUCESSO';
export const CARREGAR_CONJUGE = 'CARREGAR_CONJUGE';

export const CARREGAR_PROFISSOES_SUCESSO = 'CARREGAR_PROFISSOES_SUCESSO';

export const CARREGAR_DISTRITOS_SUCESSO = 'CARREGAR_DISTRITOS_SUCESSO';

export const CARREGAR_CIDADES_SUCESSO = 'CARREGAR_CIDADES_SUCESSO';

export const SALVAR_MORADIA_SUCESSO = 'SALVAR_MORADIA_SUCESSO';
export const CARREGAR_MORADIA = 'CARREGAR_MORADIA';

export const CARREGAR_DEPENDENTES_SUCESSO = 'CARREGAR_DEPENDENTES_SUCESSO';
export const CARREGAR_DEPENDENTES = 'CARREGAR_DEPENDENTES';
export const EXCLUIR_DEPENDENTE_SUCESSO = 'EXCLUIR_DEPENDENTE_SUCESSO';

export const CARREGAR_IMOVEIS_SUCESSO = 'CARREGAR_IMOVEIS_SUCESSO';
export const CARREGAR_IMOVEIS = 'CARREGAR_IMOVEIS';
export const EXCLUIR_IMOVEIS_SUCESSO = 'EXCLUIR_IMOVEIS_SUCESSO';

export const CARREGAR_EMPREENDIMENTOS_SUCESSO = 'CARREGAR_EMPREENDIMENTOS_SUCESSO';

export const VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO = 'VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO';
export const VERIFICAR_SE_PODE_GERAR_COMPROVANTE_DE_INSCRICAO_SUCESSO = 'VERIFICAR_SE_PODE_GERAR_COMPROVANTE_DE_INSCRICAO_SUCESSO';

export const SALVAR_INFORMACOES_DA_FAMILIA_SUCESSO = 'SALVAR_INFORMACOES_DA_FAMILIA_SUCESSO';
export const CARREGAR_INFORMACOES_DA_FAMILIA = 'CARREGAR_INFORMACOES_DA_FAMILIA';

export const SALVAR_PROGRAMAS_SOCIAIS_SUCESSO = 'SALVAR_PROGRAMAS_SOCIAIS_SUCESSO';
export const CARREGAR_PROGRAMAS_SOCIAIS = 'CARREGAR_PROGRAMAS_SOCIAIS';

export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const END_AJAX_CALL = 'END_AJAX_CALL';

export const EH_NOVA_INSCRICAO = 'EH_NOVA_INSCRICAO';
export const LIMPAR_EH_NOVA_INSCRICAO = 'LIMPAR_EH_NOVA_INSCRICAO';

export const SELECIONOU_LOCAL_DE_ATENDIMENTO = 'SELECIONOU_LOCAL_DE_ATENDIMENTO';

export const LIMPOU_LOCAL_DE_ATENDIMENTO = 'LIMPOU_LOCAL_DE_ATENDIMENTO';

export const PODE_ALTERAR_LOCAL_DE_ATENDIMENTO = 'PODE_ALTERAR_LOCAL_DE_ATENDIMENTO';

export const CARREGAR_USUARIO_LOGADO_SUCESSO = 'CARREGAR_USUARIO_LOGADO_SUCESSO';

export const SALVAR_EH_ACESSO_ADMINISTRATIVO = 'SALVAR_EH_ACESSO_ADMINISTRATIVO';

export const CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO = 'CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO';

export const CARREGAR_REGIME_DE_CASAMENTO_SUCESSO = 'CARREGAR_REGIME_DE_CASAMENTO_SUCESSO';

export const CARREGAR_ETNIA_SUCESSO = 'CARREGAR_ETNIA_SUCESSO';

export const CARREGAR_QUESTOES_DO_FORMULARIO = 'CARREGAR_QUESTOES_DO_FORMULARIO';

export const ALTERAR_EH_FORMULARIO_SOMENTE_LEITURA = 'ALTERAR_EH_FORMULARIO_SOMENTE_LEITURA';

export const DEVE_CARREGAR_INSCRICAO_NA_MEMORIA= 'DEVE_CARREGAR_INSCRICAO_NA_MEMORIA';

export const CARREGAR_DEMANDAS_ESPECIFICAS = 'CARREGAR_DEMANDAS_ESPECIFICAS';

export const ADICIONAR_CIDADES = 'ADICIONAR_CIDADES';

export const CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO = 'CARREGAR_TIPO_DE_DEFICIENCIA_SUCESSO';