import { CARREGAR_CIDADES_SUCESSO } from './actionTypes';
import { apiDaInscricao } from '../servicos/axios';

export const carregarCidadesSucesso = cidades => {
  return {
    type: CARREGAR_CIDADES_SUCESSO,
    cidades
  };
};

export const carregarCidades = () =>
  async dispatch => {
    const { data: cidades } = await apiDaInscricao().get('/cidades/ListarCidadesPorEstado');

    dispatch(carregarCidadesSucesso(cidades));
  };