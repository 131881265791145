import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';

export default function login(state = initialState.login, action) {
  switch (action.type) {
  case actionTypes.EFETUAR_LOGIN_SUCESSO:
    return {
      ...action.dadosLogin
    };
  default:
    return state;
  }
}