import { CARREGAR_DISTRITOS_SUCESSO } from './actionTypes';
import { apiDaInscricao } from '../servicos/axios';

export const carregarDistritosSucesso = distritos => {
  return {
    type: CARREGAR_DISTRITOS_SUCESSO,
    distritos
  };
};

export const carregarDistritos = () =>
  async dispatch => {
    const { data: distritos } = await apiDaInscricao().get('/distrito/ObterTodos');

    dispatch(carregarDistritosSucesso(distritos));
  };