interface IOpcoesDaAreasDeRisco {
  name: string,
  label: string
}

const opcoesDaAreasDeRisco: IOpcoesDaAreasDeRisco[] = [
  { name: 'Alagamentos', label: 'Alagamento, inundações' },
  { name: 'ProximoAAeroportosPortosFerroviasOuRodovias', label: 'Áreas de segurança de portos, aeroportos, rodovias, ferrovias' },
  { name: 'BarrancosOuEncostas', label: 'Barrancos ou encostas sujeitas a desmoronamento ' },
  { name: 'Erosao', label: 'Erosões ou solapamentos' },
  { name: 'ProximoALixoes', label: 'Lixões, áreas contaminadas ou poluídas' },
  { name: 'DebaixoDeRedeEletricaDeAltaTensao', label: 'Sob rede elétrica de alta tensão' }
];

export default opcoesDaAreasDeRisco;