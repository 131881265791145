import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IImovelProprio from '../interfaces/IImovelProprio';

export default (state = initialState.imoveis, action: IActionType<IImovelProprio[]>) => {
  switch (action.type) {
  case actionTypes.CARREGAR_IMOVEIS_SUCESSO:
  case actionTypes.CARREGAR_IMOVEIS:
    return [...action.state];
  case actionTypes.EXCLUIR_IMOVEIS_SUCESSO:
    return [
      //@ts-ignore
      ...state.filter((imoveis: IImovelProprio) => imoveis.id !== action.state)
    ];
  default:
    return state;
  }
};