import React, { useEffect, useState } from 'react';
import Container from '../../componentes/Container';
import { Header, IndicadorPasso } from '../../componentes';
import { push } from 'connected-react-router';
//@ts-ignore
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import FormularioPretendente, { validacao } from '../../componentes/FormularioPretendente';
import '../../estilos/estilos.scss';
import acessoFeitoNoResumoDaInscricao from '../../helpers/verificadorDeAcessoPeloResumo';
import { apiDaInscricaoSemLoading } from '../../servicos/axios';
import { useDispatch, useSelector } from 'react-redux';
import IPretendente from '../../interfaces/IPretendente';
import ICid from '../../interfaces/ICid';
import IProfissao from '../../interfaces/IProfissao';
import initialState from '../../reducers/initialState.reducer';
import { IDocumento } from '../../interfaces/IDocumento';
import IContato from '../../interfaces/IContato';
import IDeficiencia from '../../interfaces/IDeficiencia';
import { IPretendenteValidacaoErroCampos } from '../../interfaces/IPretendenteValidacao';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import IRegimeDeCasamento from '../../interfaces/IRegimeDeCasamento';
import IGrupoEtnico from '../../interfaces/IGrupoEtnico';
import IEtnia from '../../interfaces/IEtnia';
import { atualizarAccessToken, guardarNumeroDaInscricao } from '../../servicos/autenticacao';
import { carregarIdInscricao } from '../../actions/inscricao.actions';
import { salvarTitularSucesso } from '../../actions/dadosTitular.actions';
import { salvarTitular, editarTitular, salvarTitularAcessoAdministrativo } from '../../servicos/titular';
import { editarConjuge } from '../../servicos/conjuge';
import IGrauDeInstrucao from '../../interfaces/IGrauDeInstrucao';
import { salvarConjugeSucesso } from '../../actions/dadosConjuge.actions';
import IMoradia from '../../interfaces/IMoradia';
import { manterSomenteNumeros } from '../../helpers/mascaras';
import { consultarExistenciaDeInscricao } from '../../servicos/inscricao';
import { verificarSeEhAmbienteDeProducao } from '../../helpers/verificadorDeAmbientes';
import { obterInformacoesNoServicoDeConsultaDeCpf } from '../../servicos/pretendente';
import ICidade from '../../interfaces/ICidade';
import { mapearCidadesParaSelect } from '../../selectors';
import ICidadeParaSelect from '../../interfaces/ICidadeParaSelect';
import { salvarMoradiaSucesso } from '../../actions/moradia.actions';
import IEmancipacao from '../../interfaces/IEmancipacao';
import { ehIdadeQuePermiteEmancipacao } from '../../helpers/verificadorDeData';
import IInformacoesDoServicoDeConsultaDeCpf from '../../interfaces/IInformacoesDoServicoDeConsultaDeCpf';
import ITipoDeDeficiencia from '../../interfaces/ITipoDeDeficiencia';

const DadosTitular = () => {
  const iniciarPretendente = () => {
    if (pretendente.id == undefined) {
      //@ts-ignore
      pretendente.cpf = login.cpf;
      pretendente.sexo = '';
      pretendente.estadoCivil = '';
      pretendente.uniaoEstavel = false;
      pretendente.ehEstrangeiro = false;
      pretendente.deficiencias = [];
      pretendente.contato = {} as IContato;
    }
    return pretendente;
  };
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const ehAcessoAdministrativo = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo) as boolean;
  const etnias = useSelector((state: typeof initialState) => state.etnias) as IEtnia[];
  const regimesDeCasamento = useSelector((state: typeof initialState) => state.regimesDeCasamento) as IRegimeDeCasamento[];
  const pretendente = useSelector((state: typeof initialState) => state.dadosTitular) as IPretendente;
  const login = useSelector((state: typeof initialState) => state.login);
  const [dadosPretendente, setDadosPretendente] = useState<IPretendente>(iniciarPretendente);
  const conjuge = useSelector((state: typeof initialState) => state.dadosConjuge) as IPretendente;
  const cidades = useSelector((state: typeof initialState) => state.cidades) as ICidade[];
  const cidadesParaSelect = useSelector((state: typeof initialState) => mapearCidadesParaSelect(state.cidades) as ICidadeParaSelect[]);
  const profissoes = useSelector((state: typeof initialState) => state.profissoes) as IProfissao[];
  const grauDeInstrucoes = useSelector((state: typeof initialState) => state.grauDeInstrucoes) as IGrauDeInstrucao[];
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const [tentandoSalvar, setTentandoSalvar] = useState<boolean>(false);
  const [possuiDeficiencia, setPossuiDeficiencia] = useState<boolean | undefined>();
  const [possuiDoencaCronica, setPossuiDoencaCronica] = useState<boolean | undefined>();
  const [checkboxDoNomeSocialEstaMarcado, setCheckboxDoNomeSocialEstaMarcado] = useState<boolean>(dadosPretendente.nomeSocial === undefined ? false : dadosPretendente.nomeSocial.trim() === '' ? false : true);
  const [erros, setErros] = useState<IPretendenteValidacaoErroCampos | undefined>();
  const dispatch = useDispatch();
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const [cidadeDeResidencia, setCidadeDeResidencia] = useState<ICidade>();
  const exibirCampoCidadeDeResidencia = ehAcessoAdministrativo && ehNovaInscricao && dadosPretendente.id === undefined;
  const habilitarCampoCpf = ehAcessoAdministrativo && dadosPretendente.id === undefined;
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;
  const [habilitarFormulario, setHabilitarFormulario] = useState<boolean>(!!dadosPretendente.id || !ehAcessoAdministrativo);
  const estaEmancipado = !!pretendente?.emancipacao?.tipo;
  const ehIdadeQuePermiteEmancipar = ehIdadeQuePermiteEmancipacao(dadosPretendente.dataDeNascimento);
  const exibirCamposDeEmancipacao = ehAcessoAdministrativo && ((estaEmancipado && !ehNovaInscricao) || (ehNovaInscricao && ehIdadeQuePermiteEmancipar));
  const tiposDeDeficiencia = useSelector((state: typeof initialState) => state.tiposDeDeficiencia) as ITipoDeDeficiencia[];
  const marcarPossuiDeficiencia = possuiDeficiencia != null ? possuiDeficiencia : !!dadosPretendente.deficiencias && dadosPretendente.deficiencias.length > 0;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;

  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

    if (ehFormularioSomenteLeitura) {
      setHabilitarFormulario(false);
    }
  }, [dispatch, ehFormularioSomenteLeitura, ehNovaInscricao, inscricaoEstaVazioNaMemoria]);

  const indicadorPasso = <IndicadorPasso
    iconeDoPasso='fa-user'
    titulo='Titular'
    descricao='Preencha todos os campos abaixo. Todos os dados deverão ser comprovados posteriormente.' />;

  const camposEstadoCivilDesabilitados = () => {
    return pretendente.id && conjuge.id && (pretendente.estadoCivil.toUpperCase() === 'CASADO'
      || pretendente.uniaoEstavel) ? ['estadoCivilCasado', 'estadoCivil'] : [];
  };

  const camposDesabilitados = [
    'cpf',
    ...camposEstadoCivilDesabilitados()
  ];

  const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const erro: IPretendenteValidacaoErroCampos = validacao.validarCampo(dadosPretendente,
      name, value, ehAcessoAdministrativo, possuiDeficiencia, possuiDoencaCronica,
      checkboxDoNomeSocialEstaMarcado, cidadeDeResidencia,
      exibirCampoCidadeDeResidencia, exibirCamposDeEmancipacao, );
    if (erro)
      //@ts-ignore
      setErros({ ...erros, [name]: erro });
  };

  const removerErro = (...campos: string[]): void => {
    if (campos == undefined || erros == undefined) return;

    //@ts-ignore
    campos.forEach((campo: string) => erros[campo] = undefined);
    setErros(erros);
  };

  const onFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    removerErro(event.target.name);
  };

  const atualizarDocumento = (documentos: IDocumento) => {
    setDadosPretendente({ ...dadosPretendente, documentos });
  };

  const atualizarContato = (contato: IContato) => {
    if (!contato.telefoneParaRecado)
      contato.nomeDoResponsavel = '';
    setDadosPretendente({ ...dadosPretendente, contato });
  };

  const atualizar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor: string | boolean = mapearValor(value);

    if (name == 'situacaoNoMercadoDeTrabalho' && valor == 'Desempregado')
      setDadosPretendente({ ...dadosPretendente, [name]: valor, valorDaRenda: 0 });
    else
      setDadosPretendente({ ...dadosPretendente, [name]: valor });
    removerErro(name);
  };

  const atualizarNacionalidade = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (value === 'true')
      limparRgEGrupoEtnicoAtualizandoCampo(name, value);
    else
      limparCieAtualizandoCampo(name, value);

    removerErro(name);
  };

  const limparRgEGrupoEtnicoAtualizandoCampo = (name: string, value: string) => {
    const valor: string | boolean = mapearValor(value);
    setDadosPretendente({
      ...dadosPretendente,
      [name]: valor,
      pais: undefined,
      documentos: {
        ...dadosPretendente.documentos,
        rg: undefined
      },
      moraNaAldeia: undefined,
      grupoEtnico: undefined,
      etnia: undefined
    });
  };

  const limparCieAtualizandoCampo = (name: string, value: string) => {
    const valor: string | boolean = mapearValor(value);
    setDadosPretendente({
      ...dadosPretendente,
      [name]: valor,
      pais: undefined,
      documentos: {
        ...dadosPretendente.documentos
        , cedulaDeIdentidadeDoEstrangeiro: undefined
      }
    });
  };

  const validarFormulario = () => {
    setDadosPretendente({ ...dadosPretendente });
    const errosValidacao = validacao.validarFormulario(dadosPretendente, ehAcessoAdministrativo, 
      marcarPossuiDeficiencia,
      possuiDoencaCronica, checkboxDoNomeSocialEstaMarcado, exibirCamposDeEmancipacao,
      cidadeDeResidencia, exibirCampoCidadeDeResidencia);

    setErros(errosValidacao);

    return !Object.keys(errosValidacao).length;
  };

  const titularCasadoOuUniaoEstavel = (titular: IPretendente) => titular.estadoCivil === 'casado' || titular.uniaoEstavel;

  const salvar = async () => {
    let dadosDaInscricao;
    if (!ehAcessoAdministrativo) {
      //@ts-ignore
      const resposta = await salvarTitular(dadosPretendente, login.cidade, login.cpf, ehAcessoAdministrativo, ehNovaInscricao);

      atualizarAccessToken(resposta?.data?.access_token);
      dadosDaInscricao = resposta?.data?.result;
    } else {
      const resposta = await salvarTitularAcessoAdministrativo(dadosPretendente, cidadeDeResidencia?.id || 0);
      const moradia = {
        endereco: {
          cidade: cidadeDeResidencia
        }
      } as IMoradia;
      dispatch(salvarMoradiaSucesso(moradia));
      dadosDaInscricao = resposta?.data;
    }

    guardarNumeroDaInscricao({ id: dadosDaInscricao.idInscricao, numero: dadosDaInscricao.numeroDaInscricao });
    dispatch(carregarIdInscricao(dadosDaInscricao.idInscricao));
    dispatch(salvarTitularSucesso({ ...dadosPretendente, id: dadosDaInscricao.idTitular }));

    if (ehNovaInscricao && titularCasadoOuUniaoEstavel(dadosPretendente)) {
      dispatch(push('/dados-conjuge'));
      return;
    }
    dispatch(push('/dependentes'));

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dados do titular cadastrados com sucesso.'
    });
  };

  const editar = async () => {

    await editarTitular(inscricao.id, dadosPretendente);
    dispatch(salvarTitularSucesso(dadosPretendente));

    const deveAlterarInformacoesNoConjuge = deveAlterarInformacoesDeEstadoCivilNoConjuge(conjuge, dadosPretendente);

    if (deveAlterarInformacoesNoConjuge) {
      await alterarInformacoesDeEstadoCivilDoConjuge(conjuge, dadosPretendente);
      dispatch(salvarConjugeSucesso(conjuge));
    }

    if (acessoFeitoNoResumoDaInscricao() || !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));
    else {
      titularCasadoOuUniaoEstavel(dadosPretendente)
        ? dispatch(push('/dados-conjuge'))
        : dispatch(push('/dependentes'));
    }

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Dados do titular atualizados com sucesso.'
    });
  };

  const deveAlterarInformacoesDeEstadoCivilNoConjuge = (conjuge: IPretendente, dadosPretendente: IPretendente) => {

    const inscricaoPossuiConjuge = !!conjuge.cpf;

    const pretendenteMudouDeUniaoEstavelParaCasado = estadoCivilEhCasado(dadosPretendente.estadoCivil) &&
      !estadoCivilEhCasado(conjuge.estadoCivil) && conjuge.uniaoEstavel;

    const pretendenteAlterouInformacoesDeCasamento = conjuge.dataDoCasamento != dadosPretendente.dataDoCasamento ||
      conjuge.regimeDeCasamento != dadosPretendente.regimeDeCasamento;

    return inscricaoPossuiConjuge && (pretendenteMudouDeUniaoEstavelParaCasado || pretendenteAlterouInformacoesDeCasamento);
  };

  const posicionarTela = () => {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 115;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      setTentandoSalvar(false);
    }
  };

  const controlarValorDoCampoNomeSocial = () => {
    setCheckboxDoNomeSocialEstaMarcado(!checkboxDoNomeSocialEstaMarcado);
    setDadosPretendente({ ...dadosPretendente, nomeSocial: undefined });
  };

  const salvarPretendente = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    setTentandoSalvar(true);
    if (!validarFormulario()) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      posicionarTela();
      return;
    }
    dadosPretendente.id ? await editar() : await salvar();
  };

  const alterarInformacoesDeEstadoCivilDoConjuge = async (conjuge: IPretendente, dadosPretendente: IPretendente) => {
    conjuge.estadoCivil = dadosPretendente.estadoCivil;
    conjuge.dataDoCasamento = dadosPretendente.dataDoCasamento;
    conjuge.regimeDeCasamento = dadosPretendente.regimeDeCasamento;
    conjuge.uniaoEstavel = dadosPretendente.uniaoEstavel;

    await editarConjuge(conjuge, inscricao.id, ehAcessoAdministrativo);
  };

  const atualizarSexo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const ehDoSexoMasculino = value?.toLowerCase() === 'masculino';

    let ehMulherVitimaDeViolencia = ehDoSexoMasculino ? undefined : dadosPretendente.ehMulherVitimaDeViolenciaDomestica;
    let mulherChefeDeFamilia = ehDoSexoMasculino ? false : dadosPretendente.mulherChefeDeFamilia;

    setDadosPretendente({
      ...dadosPretendente,
      [name]: value,
      ehMulherVitimaDeViolenciaDomestica: ehMulherVitimaDeViolencia,
      mulherChefeDeFamilia
    });

    if (ehDoSexoMasculino) {
      removerErro('erroEhMulherVitimaDeViolenciaDomestica', 'erroMulherChefeDeFamilia');
    }
    removerErro('sexo');
  };

  const limparDeficiencias = () => {
    setDadosPretendente({
      ...dadosPretendente,
      deficiencias: []
    });
  };

  const onChangeDeficiencia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor = value == 'true';

    setPossuiDeficiencia(valor);
    if (!valor) {
      limparDeficiencias();
      removerErro(name);
    }
  };

  const onChangeDoencaCronica = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const valor = value == 'true';

    setPossuiDoencaCronica(valor);
    if (!valor) {
      limparDoencaCronica();
      removerErro(name);
    }
  };

  const limparDoencaCronica = () => {
    setDadosPretendente({
      ...dadosPretendente,
      cidDaDoencaCronica: undefined
    });
  };

  const atualizarProfissao = (profissaoSelecionada?: IProfissao) => {
    setDadosPretendente({
      ...dadosPretendente,
      profissao: !profissaoSelecionada ? undefined : profissaoSelecionada,
      atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: undefined,
      aceitaParticiparDoHabiteSeguro: undefined
    });
    removerErro('profissao');
  };

  const atualizarGrauDeInstrucao = (grauDeInstrucaoSelecionado?: IGrauDeInstrucao) => {
    setDadosPretendente({
      ...dadosPretendente,
      grauDeInstrucao: !grauDeInstrucaoSelecionado ? undefined : grauDeInstrucaoSelecionado
    });
    removerErro('grauDeInstrucao');
  };

  const limparParticipacaoNoProgramaHabiteSeguro = () => {
    setDadosPretendente({
      ...dadosPretendente,
      atuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica: false,
      aceitaParticiparDoHabiteSeguro: undefined
    });
  };

  const buscarCid = async (cid: string) => {
    const numeroMinimoDeCaracteresParaRealizarBusca = 3;
    if (!cid || cid.length < numeroMinimoDeCaracteresParaRealizarBusca)
      return { options: [] };

    const { data } = await apiDaInscricaoSemLoading().get(`/cid/buscar?cid=${cid}`);
    const retorno = {
      options: data.map((cid: any) => {
        return { value: cid.id, label: `${cid.codigo} - ${cid.descricao}` };
      })
    };

    return retorno;
  };

  const adicionarDeficiencia = (deficiencia:IDeficiencia) => {
    const deficiencias = dadosPretendente.deficiencias || [];
    const deficienciasComCid = deficiencias.filter(d => d.cid);
    if (deficiencias.some(d => d.cid?.id === deficiencia.cid.id)){
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Já existe uma deficiência com esse CID.'
      });
      return;
    }

    setDadosPretendente({
      ...dadosPretendente,
      deficiencias: [
        ...deficienciasComCid,
        deficiencia
      ]
    });
    removerErro('deficiencias');
  };

  const removerDeficiencia = (cid: ICid) => {
    let deficiencias = dadosPretendente.deficiencias || [];
    deficiencias = deficiencias.filter(d => d.cid !== cid);

    setDadosPretendente({
      ...dadosPretendente,
      deficiencias
    });
  };

  const atualizarDoencaCronica = (doencaCronica: number, nomeDaDoencaCronica: string) => {

    setDadosPretendente({
      ...dadosPretendente,
      cidDaDoencaCronica: {
        id: doencaCronica,
        codigo: nomeDaDoencaCronica.split(' - ')[0],
        descricao: nomeDaDoencaCronica
      }
    });
    removerErro('doencaCronica');
  };

  const removerDoencaCronica = () => {
    setDadosPretendente({
      ...dadosPretendente,
      cidDaDoencaCronica: undefined
    });
    removerErro('doencaCronica');
  };

  const mapearValor = (valor: string) => {
    return valor === 'true' ? true : (valor === 'false' ? false : valor);
  };

  const atualizarPais = (sigla?: string, nome?: string) => {
    setDadosPretendente({
      ...dadosPretendente,
      pais: (sigla && nome) ? { sigla, nome } : undefined
    });
    removerErro('pais');
  };

  const nomeCidade = () => {
    if (exibirCampoCidadeDeResidencia)
      return cidadeDeResidencia?.nome || '';

    //@ts-ignore
    return moradia?.endereco?.cidade?.nome ? moradia.endereco.cidade.nome : login.cidade ? cidades.filter((c: any) => c.id == login.cidade)[0]?.nome || '' : '';
  };

  const obterGruposEtnicos = async (etnia?: IEtnia) => {
    if (!etnia?.ehIndigena)
      setDadosPretendente({ ...dadosPretendente, etnia: etnia, grupoEtnico: undefined, moraNaAldeia: undefined });
    else
      setDadosPretendente({ ...dadosPretendente, etnia: etnia });

    removerErro('etnia');
  };

  const onChangeGrupoEtnico = (grupoEtnico?: IGrupoEtnico) => {
    setDadosPretendente({ ...dadosPretendente, grupoEtnico: grupoEtnico });
    removerErro('grupoEtnico');
  };

  const onChangeRegimeDeCasamento = (regimeDeCasamento?: IRegimeDeCasamento) => {
    setDadosPretendente({ ...dadosPretendente, regimeDeCasamento: regimeDeCasamento });
    removerErro('regimeDeCasamento');
  };

  const onChangeEstadoCivil = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value == 'separadojudicialmente')
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, dataDoCasamento: undefined, regimeDeCasamento: undefined, uniaoEstavel: false });
    else if (value != 'casado')
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, dataDoCasamento: undefined, regimeDeCasamento: undefined });
    else
      setDadosPretendente({ ...dadosPretendente, estadoCivil: value, uniaoEstavel: false });

    removerErro('estadoCivil');
  };

  const estadoCivilEhCasado = (estadoCivil: string) => {
    return estadoCivil != undefined && estadoCivil.toUpperCase() == 'CASADO';
  };

  const consultarInformacoesDoPretentende = async (cpf: string) => {
    const ehAmbienteDeProducao = verificarSeEhAmbienteDeProducao();

    const informacoes = ehAmbienteDeProducao ? await obterInformacoesNoServicoDeConsultaDeCpf(cpf) : {} as IInformacoesDoServicoDeConsultaDeCpf;
    setDadosPretendente({ ...dadosPretendente, cpf , nome : informacoes?.nome || '', dataDeNascimento: informacoes?.dataDeNascimento });
  };

  const atualizarCpf = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setDadosPretendente({ ...dadosPretendente, cpf: value });

    let cpfSemMascara = manterSomenteNumeros(value);
    let cpfTem11Digitos = cpfSemMascara.length == 11;
    if (cpfTem11Digitos) {
      await validarCadastroDeCpf(cpfSemMascara);
    }
  };

  const validarCadastroDeCpf = async (cpf: string) => {
    const resultadoDaConsulta = await consultarExistenciaDeInscricao(cpf);
    const habilitarFomulario = resultadoDaConsulta?.possuiInscricao ? false : true;
    setHabilitarFormulario(habilitarFomulario);

    if (resultadoDaConsulta?.possuiInscricao)
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: resultadoDaConsulta.mensagem
      });

    if (habilitarFomulario)
      consultarInformacoesDoPretentende(cpf);
  };

  const voltar = () => {
    if (ehNovaInscricao)
      dispatch(push('/listagem-de-inscricoes'));
    else
      dispatch(push('/menu-inscricao'));
  };

  const atualizarCidadeDeResidencia = (idDaCidade?: number | null) => {
    const cidade = idDaCidade ? cidades.find(cidade => cidade.id == idDaCidade) : undefined;
    setCidadeDeResidencia(cidade);
    removerErro('cidadeDeResidencia');
  };

  const onChangeEmancipacao = (emancipacao: IEmancipacao, camposParaRemoverErro?: string[]) => {
    setDadosPretendente({ ...dadosPretendente, emancipacao });
    
    if(camposParaRemoverErro)
      removerErro(...camposParaRemoverErro);
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
        <div className="pagina-pretendente">
          {!ehAcessoAdministrativo && (
            <Header subTitulo='Dados do titular' comBotaoVoltar={dadosPretendente.id != undefined} urlBotaoVoltar={'/menu-inscricao'} />
          )}
          {ehAcessoAdministrativo && (
            <BarraDeNavegacao
              titulo="Dados do titular"
              comBotaoVoltar={true}
              acaoBotaoVoltar={voltar}
              comBotaoADireita={!ehNovaInscricao}
              textoBotaoADireita="Menu de inscrição"
              iconeBotaoADireita={false}
              acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
            />
          )}

          <div className="pagina-pretendente__container">
            <Container comFundo={false} semPadding={true} tamanhoDoContainer={''} >
              <FormularioPretendente
                onChangeCpf={atualizarCpf}
                dadosPretendente={dadosPretendente}
                camposDesabilitados={camposDesabilitados}
                possuiDoencaCronica={possuiDoencaCronica != null ? possuiDoencaCronica : !!dadosPretendente.cidDaDoencaCronica}
                possuiDeficiencia={marcarPossuiDeficiencia}
                erros={erros}
                onChange={atualizar}
                onChangeDocumento={atualizarDocumento}
                onChangeContato={atualizarContato}
                onChangeNacionalidade={atualizarNacionalidade}
                onBlur={onBlur}
                onChangeNomeSocial={controlarValorDoCampoNomeSocial}
                checkboxDoNomeSocialEstaMarcado={checkboxDoNomeSocialEstaMarcado}
                onFocus={onFocus}
                onChangeDeficiencia={onChangeDeficiencia}
                onChangeDoencaCronica={onChangeDoencaCronica}
                onChangeSexo={atualizarSexo}
                eventosDeficiencia={{ adicionarDeficiencia, removerDeficiencia, buscarCid }}
                eventosDoencaCronica={{ atualizarDoencaCronica, buscarCid, removerDoencaCronica }}
                profissoes={profissoes}
                grauDeInstrucoes={grauDeInstrucoes}
                nomeDaCidade={nomeCidade()}
                onClick={salvarPretendente}
                indicadorPasso={indicadorPasso}
                onChangeNaoAtuaHaMaisDe3AnosEmProfissaoDeSegurancaPublica={limparParticipacaoNoProgramaHabiteSeguro}
                onChangeProfissao={atualizarProfissao}
                onChangeGrauDeInstrucao={atualizarGrauDeInstrucao}
                onChangePais={atualizarPais}
                ehFormularioDeConjuge={false}
                onChangeEtnia={obterGruposEtnicos}
                onChangeGrupoEtnico={onChangeGrupoEtnico}
                regimesDeCasamento={regimesDeCasamento}
                etnias={etnias}
                onChangeRegimeDeCasamento={onChangeRegimeDeCasamento}
                onChangeEstadoCivil={onChangeEstadoCivil}
                exibirCampoCidadeDeResidencia={exibirCampoCidadeDeResidencia}
                cidades={cidadesParaSelect}
                onChangeCidadeDeResidencia={atualizarCidadeDeResidencia}
                habilitarCampoCpf={habilitarCampoCpf}
                formularioPermiteEdicao={habilitarFormulario}
                ehAcessoAdministrativo={ehAcessoAdministrativo}
                exibirCamposDeEmancipacao={exibirCamposDeEmancipacao}
                onChangeEmancipacao={onChangeEmancipacao}
                tiposDeDeficiencia={tiposDeDeficiencia}
              />
            </Container>
          </div>
        </div>}
    </>
  );
};

export default DadosTitular;