import initialState from './initialState.reducer';
import { CARREGAR_USUARIO_LOGADO_SUCESSO } from '../actions/actionTypes';

export default (state = initialState.usuario, acao) => {
  switch (acao.type) {
  case CARREGAR_USUARIO_LOGADO_SUCESSO:
    return { ...acao.usuario };
  default:
    return state;
  }
};