import React from 'react';
import MensagemDeErro from '../MensagemDeErro';

interface CampoDeTextoProps {
  name: string,
  value: string,
  placeholder?: string,
  colunas?: string,
  label?: string,
  link?: string,
  descricaoDoLink?: string,
  descricao?: string,
  children?: React.ReactNode,
  erro?: string,
  disabled?: boolean,
  required?: boolean,
  tipo?: string,
  capsLockAtivo?: boolean,
  autoFocus?: boolean,
  maxLength?: number,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void,
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void,
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const CampoDeTexto = ({ name, colunas = '', label = '', link, descricaoDoLink, placeholder,
  descricao = '', children, erro, required = true, disabled = false, maxLength,
  value, tipo = 'text', capsLockAtivo = false, autoFocus = false, 
  onChange, onBlur, onFocus, onKeyDown,onKeyPress }: CampoDeTextoProps) => {
  return (
    <div className={`grade__coluna ${colunas ? `grade__coluna_${colunas}` : 'u-largura-total'}`}>
      <label className="formulario__label" htmlFor={name}>
        {label}
      </label>

      {link &&
      <a target="_blank" rel="noopener noreferrer" className="link link_cor-info link_medio" href={link}>{descricaoDoLink}</a>
      }
      {(descricao || children) &&
      <div className="formulario__descricao">
        {descricao || children}
      </div>}
      {erro &&
      <MensagemDeErro campo={name} mensagem={erro ? erro : 'Campo obrigatório'} />
      }
      <div>
        <input
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          type={tipo}
          maxLength={maxLength}
          autoFocus={autoFocus}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onKeyPress={onKeyPress}
          disabled={disabled}
          className={`formulario__input ${erro ? 'formulario__input_erro' : ''}`}
          aria-required={required}
          aria-invalid={!!erro}
        />
        {tipo == 'password' && capsLockAtivo &&
        <p role="tooltip" id="mensagem-atencao" className="formulario__mensagem formulario__mensagem_atencao">
          <i className="far fa-exclamation-triangle formulario__mensagem-icone"></i>
          <span className="u-esconder-acessivel">Atenção: </span>
          Teclado de letras maiúsculas está ativado
        </p>
        }
      </div>
    </div>
  );
};

export default CampoDeTexto;