import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//@ts-ignore
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import Container from '../../../componentes/Container';
import ContainerConteudo from '../../../componentes/ContainerConteudo';
import ContainerRodape from '../../../componentes/ContainerRodape';
import Header from '../../../componentes/Header/index';
import CampoStatusDaQuitacaoDoImovel from '../../../componentes/CampoStatusDaQuitacaoDoImovel/index';
import TipoDoImovel from '../../../componentes/TipoDoImovel/index';
import SelecaoDeEstado from '../../../componentes/SelecaoDeEstado/index';
import '../../../estilos/estilos.scss';
import FormularioDeEndereco from '../../../componentes/FormularioDeEndereco/index';
import { sucessoAoCarregarImovel } from '../../../actions/imoveis.actions';
import CompletarEnderecoPorSituacaoDeMoradiaPropria from './completarEnderecoPorSituacaoDeMoradiaPropria';
import initialState from '../../../reducers/initialState.reducer';
import IImovelProprio from '../../../interfaces/IImovelProprio';
import ICidade from '../../../interfaces/ICidade';
import IEstado from '../../../interfaces/IEstado';
import IEndereco from '../../../interfaces/IEndereco';
import BarraDeNavegacao from '../../../componentes/BarraDeNavegacao';
import { goBack, push } from 'connected-react-router';
import IMoradia from '../../../interfaces/IMoradia';
import { IFormularioDeEnderecoValidacaoErroCampos } from '../../../interfaces/IFormularioDeEnderecoValidacao';
import validacaoFormularioDeEndereco from '../../../componentes/FormularioDeEndereco/validacao';
import situacoesDeMoradiaPropria from './situacoesDeMoradiaPropria';
import { mapearCidadesParaSelect } from '../../../selectors/cidades';
import ICidadeParaSelect from '../../../interfaces/ICidadeParaSelect';
import { RouteComponentProps } from 'react-router-dom';
import { salvar, editar, obterPorId, obterImoveis } from '../../../servicos/imovelProprio';

const CadastroDeImovel = ({ match }: RouteComponentProps<{ id?: string }>) => {

  const iniciarImovelProprio = () => {

    const cidade = {} as ICidade;
    const estado = { uf: 'MS' } as IEstado;
    const endereco = { estado, cidade, bairro: '', cep: '', complemento: '', logradouro: '', numero: '', distrito: {} } as IEndereco;

    const imovelProprio = {
      endereco: endereco,
      idInscricao: idDaInscricao,
    } as IImovelProprio;

    return imovelProprio;
  };
  const deveExibirOpcaoDeCompletarEndereco = () => {
    let naoEstaEmModoDeEdicao = !imovelTemId;
    let moradiaEhPropria = situacoesDeMoradiaPropria.includes(moradia.situacao);
    return naoEstaEmModoDeEdicao && moradiaEhPropria;
  };

  const imovelTemId = match.params?.id;
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const moradia = useSelector((state: typeof initialState) => state.moradia) as IMoradia;
  const ehAcessoAdministrativo: boolean = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo);
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const [dadosDoImovel, setDadosDoImovel] = useState<IImovelProprio>(iniciarImovelProprio);
  const [completarEnderecoPorSituacaoDeMoradiaPropria, setCompletarEnderecoPorSituacaoDeMoradiaPropria] = useState<boolean>(false);
  const [ehDoEstadoMatoGrossoDoSul, setEhDoEstadoMatoGrossoDoSul] = useState<boolean>(true);
  const [errosFormularioDeEndereco, setErrosFormularioDeEndereco] = useState<IFormularioDeEnderecoValidacaoErroCampos | undefined>();
  const verificarCepPadrao = () => !!dadosDoImovel.endereco?.cidade?.cepPadrao || !!dadosDoImovel.endereco.distrito?.cepPadrao;
  const [ehCepPadrao, setEhCepPadrao] = useState<boolean>(verificarCepPadrao);
  const [moraEmDistrito, setMoraEmDistrito] = useState<boolean>(dadosDoImovel.endereco?.distrito != null && Object.keys(dadosDoImovel.endereco?.distrito).length > 0 ? true : false);
  const [tentandoSalvar, setTentandoSalvar] = useState<boolean>(false);
  const [erroEstaQuitado, setErroEstaQuitado] = useState<boolean>(false);
  const [erroTipoDoImovel, setErroTipoDoImovel] = useState<boolean>(false);
  const [erroSelecaoDeEstado, setErroSelecaoDeEstado] = useState<boolean>(false);
  const deveExibirOpcaoDeCompletarEnderecoDeMoradiaPropria = deveExibirOpcaoDeCompletarEndereco();
  const cidades = useSelector((state: typeof initialState) => mapearCidadesParaSelect(state.cidades) as ICidadeParaSelect[]);
  const dispatch = useDispatch();
  const [imovelCarregadoDaApi, setImovelCarregadoDaApi] = useState<boolean>(false);
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;
  
  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(() => {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

    imovelTemId ? obterImovel(parseInt(imovelTemId)) : setImovelCarregadoDaApi(true);

  }, []);

  useEffect(() => {
    posicionarTela();
  }, [tentandoSalvar]);

  const posicionarTela = () => {
    if (tentandoSalvar) {
      let campoComErro: HTMLElement | null = document.querySelector('.formulario__mensagem_erro');

      if (campoComErro) {
        const alturaDoCabecalhoDaPagina = 450;
        campoComErro.scrollIntoView({ behavior: 'smooth' });
        window.scrollBy(0, -alturaDoCabecalhoDaPagina);
      }
      setTentandoSalvar(false);
    }
  };

  const obterImovel = async (idDoImovel: number) => {
    return await obterPorId(idDaInscricao, idDoImovel)
      .then((imovel: IImovelProprio) => {
        imovel.idInscricao = idDaInscricao;
        setDadosDoImovel({...imovel});
        setMoraEmDistrito(imovel.endereco?.distrito != null && Object.keys(imovel.endereco?.distrito).length > 0 ? true : false);
        setEhCepPadrao(!!imovel.endereco?.cidade?.cepPadrao || !!imovel.endereco.distrito?.cepPadrao);
        setEhDoEstadoMatoGrossoDoSul(imovel.endereco?.estado.uf === 'MS');
        setImovelCarregadoDaApi(true);
      });
  };

  const atualizarImovel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name == 'tipo') {
      setDadosDoImovel({ ...dadosDoImovel, [name]: value });
      setErroTipoDoImovel(false);
    }
    else {
      setDadosDoImovel({ ...dadosDoImovel, [name]: value == 'true' });
      setErroEstaQuitado(false);
    }
  };

  const completarEnderecoComInformacoesDaMoradia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setCompletarEnderecoPorSituacaoDeMoradiaPropria(value === 'true');

    if (value === 'true') {
      atualizarComDadosDoEndereco(moradia.endereco);
    } else {
      const cidade = {} as ICidade;
      const estado = { uf: 'MS' } as IEstado;
      const endereco = { estado, cidade, bairro: '', logradouro: '', cep: '', complemento: '', numero: '' } as IEndereco;
      atualizarComDadosDoEndereco(endereco);
    }
  };

  const atualizarComDadosDoEndereco = (endereco: IEndereco) => {
    setDadosDoImovel(estadoAnterior => ({...estadoAnterior, endereco : {...endereco }}));
  };

  const atualizarEstadoDoEndereco = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const endereco = dadosDoImovel.endereco;
    endereco.estado.uf = value;
    setDadosDoImovel({ ...dadosDoImovel, endereco: endereco });
    setEhDoEstadoMatoGrossoDoSul(value === 'MS');
  };

  const atualizarMoraEmDistrito = (moraEmDistrito: boolean) => {
    setMoraEmDistrito(moraEmDistrito);
  };

  const atualizarCepPadrao = (ehCepPadrao: boolean) => {
    setEhCepPadrao(ehCepPadrao);
  };

  const onBlurFormularioDeEndereco = (evento: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = evento.target;
    const endereco = dadosDoImovel.endereco;
    const erro = validacaoFormularioDeEndereco.validarCampo(name, endereco.cep, endereco.logradouro, endereco.numero, endereco.bairro,
      endereco.cidade?.id, moraEmDistrito, endereco.distrito?.id);
    if (erro)
      setErrosFormularioDeEndereco({ ...errosFormularioDeEndereco, [name]: erro });
  };

  const validarFormulario = () => {
    let errosFormularioDeEndereco = {};
    let formularioInvalido = false;
    if (dadosDoImovel.endereco.estado.uf == 'MS') {
      const endereco = dadosDoImovel.endereco;
      errosFormularioDeEndereco = validacaoFormularioDeEndereco.validarFormulario(endereco.cep, endereco.logradouro, endereco.numero,
        endereco.bairro, endereco.cidade?.id, moraEmDistrito, endereco.distrito?.id);
      if (errosFormularioDeEndereco)
        setErrosFormularioDeEndereco(errosFormularioDeEndereco);
    }

    if (dadosDoImovel.estaQuitado == undefined) {
      setErroEstaQuitado(true);
      formularioInvalido = true;
    }

    if (dadosDoImovel.tipo == undefined) {
      setErroTipoDoImovel(true);
      formularioInvalido = true;
    }

    if (!dadosDoImovel.endereco.estado.uf.length) {
      setErroSelecaoDeEstado(true);
      formularioInvalido = true;
    }

    return (formularioInvalido || Object.keys(errosFormularioDeEndereco).length > 0);
  };

  const gravarImovel = async () => {

    setTentandoSalvar(true);
    const haErroNoFormulario = validarFormulario();
    if (haErroNoFormulario) {
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'erro',
        icone: 'far fa-times-circle',
        titulo: 'Identificamos um erro:',
        texto: 'Formulário inválido. Por favor corrija os campos obrigatórios.'
      });
      return;
    }

    if (imovelTemId)
      await editarDadosDoImovel();
    else
      await salvarDadosDoImovel();
  };

  const salvarDadosDoImovel = async () => {

    await salvar(idDaInscricao, dadosDoImovel);
    const imoveis = await obterImoveis(idDaInscricao);
    dispatch(sucessoAoCarregarImovel(imoveis));
    dispatch(goBack());


    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Imóvel cadastrado com sucesso.'
    });
  };

  const editarDadosDoImovel = async () => {

    await editar(idDaInscricao, dadosDoImovel);
    const imoveis = await obterImoveis(idDaInscricao);
    dispatch(sucessoAoCarregarImovel(imoveis));
    dispatch(goBack());

    GerenciadorDeMensagem.criarMensagem({
      tipo: 'sucesso',
      icone: 'far fa-check-circle',
      titulo: 'Tudo certo!',
      texto: 'Imóvel alterado com sucesso.'
    });
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) &&
      <div className="pagina-fundo">
        {!ehAcessoAdministrativo && (
        //@ts-ignore
          <Header subTitulo={'Cadastro - Imóveis'} comBotaoVoltar={!ehNovaInscricao} urlBotaoVoltar={'/menu-inscricao'} />
        )}
        {ehAcessoAdministrativo && (
          <BarraDeNavegacao
            titulo='Cadastro - Imóveis'
            comBotaoVoltar={true}
            acaoBotaoVoltar={() => dispatch(push('/imoveis'))}
            comBotaoADireita={true}
            textoBotaoADireita="Menu de inscrição"
            iconeBotaoADireita={false}
            acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
          />
        )}

        <div className="pagina-container">
          <Container comFundo={true} semPadding={true} tamanhoDoContainer={''} >
            <ContainerConteudo>
              <CampoStatusDaQuitacaoDoImovel
                estaQuitado={dadosDoImovel.estaQuitado}
                onChange={atualizarImovel}
                erro={erroEstaQuitado} />
              <hr />
              <TipoDoImovel
                tipo={dadosDoImovel.tipo}
                onChange={atualizarImovel}
                erro={erroTipoDoImovel} />
              <hr />
              {deveExibirOpcaoDeCompletarEnderecoDeMoradiaPropria &&
              <CompletarEnderecoPorSituacaoDeMoradiaPropria
                onChange={completarEnderecoComInformacoesDaMoradia}
                completarEnderecoPorSituacaoDeMoradiaPropria={completarEnderecoPorSituacaoDeMoradiaPropria} />
              }
              <SelecaoDeEstado
                uf={dadosDoImovel.endereco.estado.uf}
                label='Em que estado fica o imóvel?'
                clearable={false}
                onChange={atualizarEstadoDoEndereco}
                erro={erroSelecaoDeEstado} />
              {ehDoEstadoMatoGrossoDoSul && imovelCarregadoDaApi &&
              <FormularioDeEndereco
                titulo="Endereço atual"
                dadosDoEndereco={dadosDoImovel.endereco}
                erros={errosFormularioDeEndereco}
                bloquearMudancaDeCidade={false}
                moraEmDistrito={moraEmDistrito}
                ehCepPadrao={ehCepPadrao}
                atualizarComDadosDoEndereco={atualizarComDadosDoEndereco}
                atualizarErros={setErrosFormularioDeEndereco}
                atualizarMoraEmDistrito={atualizarMoraEmDistrito}
                atualizarCepPadrao={atualizarCepPadrao}
                onBlur={onBlurFormularioDeEndereco}
                cidades={cidades} 
                disabled={ehFormularioSomenteLeitura}/>
              }
            </ContainerConteudo>
            <ContainerRodape>
              <div className="grade__coluna">
                <button
                  data-test-id='botao-salvar-imovel'
                  onClick={gravarImovel}
                  className="botao botao_medio botao_cor-secundaria botao_largura-total">
                  Salvar
                </button>
              </div>
            </ContainerRodape>
          </Container>
        </div>
      </div>}
    </>
  );
};
export default CadastroDeImovel;