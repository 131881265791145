import { apiDaInscricao, apiDaInscricaoSemLoading } from './axios';
import IImovelProprio from '../interfaces/IImovelProprio';

export const salvar = async (idDaInscricao: number, imovel: IImovelProprio) => {
  await apiDaInscricao().post(`/ImovelProprio/${idDaInscricao}/Salvar`, imovel);
};

export const editar = async (idDaInscricao: number, imovel: IImovelProprio) => {
  await apiDaInscricao().put(`/ImovelProprio/${idDaInscricao}/Editar`, imovel);
};

export const obterPorId = async (idDaInscricao: number, idDoImovel: number) => {
  const response = await apiDaInscricaoSemLoading().get(`/imovelProprio/${idDaInscricao}/ObterPorImovel?idDoImovel=${idDoImovel}`);
  return response.data;
};

export const obterImoveis = async (idDaInscricao: number) => {
  const { data: imoveis } = await apiDaInscricao().get(`/imovelProprio/${idDaInscricao}/obter`);
  return imoveis;
};

export const excluir = async (idDaInscricao: number, idDoImovel: number) => {
  await apiDaInscricaoSemLoading().delete(`/imovelProprio/${idDaInscricao}/deletar/${idDoImovel}`);
};