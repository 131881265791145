import React from 'react';
import CampoEstado from '../../../CampoEstado';
import MensagemDeErro from '../../../../../MensagemDeErro';
import { IDocumento } from '../../../../../../interfaces/IDocumento';
import ICarteiraDeTrabalho from '../../../../../../interfaces/ICarteiraDeTrabalho';
import { IPretendenteValidacaoErroCampos } from '../../../../../../interfaces/IPretendenteValidacao';
import CampoDeTextoComMascara from '../../../../../CampoDeTextoComMascara';

interface IInputEventsProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void,
    onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void
}

interface ICamposCtpsProps {
    documentos: IDocumento,
    erros?: IPretendenteValidacaoErroCampos,
    inputEvents: IInputEventsProps,
    disabled: boolean,
    onChangeDocumento: (documentos: IDocumento) => void
}

const CampoCtps = ({ documentos, erros, inputEvents, disabled, onChangeDocumento }: ICamposCtpsProps) => {

  const handleOnChangeDocumento = (documentos: IDocumento, event: React.ChangeEvent<HTMLInputElement>, nomePropriedade: string) => {
    if (documentos.carteiraDeTrabalho) {
      const carteira: ICarteiraDeTrabalho = documentos.carteiraDeTrabalho;
      onChangeDocumento({ ...documentos, carteiraDeTrabalho: { ...carteira, [nomePropriedade]: event.target.value } });
    }
  };

  const handleOnChangeEstado = (selected: { label: string, value: string }) => {
    if (selected && documentos.carteiraDeTrabalho) {
      const carteira: ICarteiraDeTrabalho = documentos.carteiraDeTrabalho;
      onChangeDocumento({ ...documentos, carteiraDeTrabalho: { ...carteira, estado: { ...documentos.carteiraDeTrabalho.estado, uf: selected.value } } });
    }
  };

  return (
    <div>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <CampoDeTextoComMascara
          label="Número"
          name="numeroCtps"
          mascara="999999999999999"
          value={documentos?.carteiraDeTrabalho?.numero || ''}
          tipo="text"
          erro={erros?.numeroCtps}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'numero'); }} />

        <CampoDeTextoComMascara
          label="Série"
          name="serieCtps"
          mascara="99999"
          value={documentos?.carteiraDeTrabalho?.serie || ''}
          tipo="text"
          erro={erros?.serieCtps}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'serie'); }} />

      </div>
      <div className="grade__linha grade__linha_alinhar-vertical-a-base">
        <CampoDeTextoComMascara
          label="Data de expedição"
          name="dataDeExpedicaoDaCtps"
          mascara="99/99/9999"
          value={documentos?.carteiraDeTrabalho?.dataDeExpedicao?.toString() || ''}
          tipo="text"
          erro={erros?.dataDeExpedicaoDaCtps}
          disabled={disabled}
          {...inputEvents}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleOnChangeDocumento(documentos, event, 'dataDeExpedicao'); }}
        />
        <div className="grade__coluna u-largura-total">
          <label className="formulario__label">
            Estado
          </label>
          {erros?.ufCtps && <MensagemDeErro />}
          <CampoEstado
            value={documentos?.carteiraDeTrabalho?.estado?.uf || ''}
            onChange={handleOnChangeEstado}
            disabled={disabled}
            erro={erros?.ufCtps} />
        </div>
      </div>
    </div>);
};

export default CampoCtps;