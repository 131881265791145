import { CARREGAR_DEMANDAS_ESPECIFICAS } from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IDemandaEspecifica from '../interfaces/IDemandaEspecifica';

export default (state = initialState.demandasEspecificas, action: IActionType<IDemandaEspecifica[]>) => {
  switch (action.type) {
  case CARREGAR_DEMANDAS_ESPECIFICAS:
    return [...action.state];
  default:
    return state;
  }
};