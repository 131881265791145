import React, { Component } from 'react';
import Select from 'react-select';
import servicoEndereco from './servicoEndereco';
import PropTypes from 'prop-types';
import MensagemDeErro from '../MensagemDeErro';

export default class AutocompleteEndereco extends Component {
  constructor(props) {
    super(props);
    this.state = { enderecos: [] };
  }

  buscarEndereco = async endereco => {
    const enderecos = await servicoEndereco.buscarEndereco(endereco);
    if (!enderecos.length)
      return {
        options: [{
          value: endereco,
          label: endereco
        }]
      };

    this.setState({ enderecos: [...enderecos] });

    return {
      options: enderecos.map(e => {
        return {
          value: e.logradouro,
          label: e.logradouro_completo
        };
      })
    };
  };
  
  enderecoSelecionado = value =>{
    return this.state.enderecos.filter(p => p.logradouro === value)[0];
  };

  render = () => {
    const { label, value, placeholder, onChange, erro } = this.props;

    return (
      <div className="field">
        <label className="label">
          {label}
        </label>
        <Select.AsyncCreatable
          placeholder={placeholder || `Digite o ${label}`}
          searchPromptText={`Digite o ${label} para buscar`}
          loadingPlaceholder="Carregando..."
          promptTextCreator={label => label}
          value={value}
          loadOptions={this.buscarEndereco}
          autoload={false}
          clearable={false}
          autoBlur={true}
          onChange={({ value }) => onChange(value, this.enderecoSelecionado(value))} />
        {erro && <MensagemDeErro />}
      </div>);
  };
} 

AutocompleteEndereco.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  erro: PropTypes.string
};
