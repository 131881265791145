import { VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO, VERIFICAR_SE_PODE_GERAR_COMPROVANTE_DE_INSCRICAO_SUCESSO } from '../actions/actionTypes';
import initialState from './initialState.reducer';

export default (state = initialState.menu, action) => {
  switch (action.type) {
  case VERIFICAR_CONSISTENCIA_DA_INSCRICAO_SUCESSO:
    return { ...state, inconsistencias: action.inconsistencias };
  case VERIFICAR_SE_PODE_GERAR_COMPROVANTE_DE_INSCRICAO_SUCESSO:
    return { ...state, podeGerarComprovanteDeInscricao: action.podeGerarComprovanteDeInscricao };
  default:
    return state;
  }
};