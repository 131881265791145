import React from 'react';
import CampoDeTextoComMascara from '../../CampoDeTextoComMascara';

const LINK_DE_BUSCA = 'http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCep.cfm';
const DESCRICAO_DO_LINK_DE_BUSCA = 'Não sei meu CEP';
const FORMATO_DE_TEXTO = '99999-999';
const NOME_DO_CAMPO = 'cep';
const DESCRICAO_DO_CAMPO = 'CEP';

interface ICampoCepProps {
  onChange: (evento: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (evenot: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (evento: React.ChangeEvent<HTMLInputElement>) => void,
  valor: string;
  desabilitado: boolean;
  erro?: string
}

const CampoCep = ({onChange, onBlur, onFocus, valor, desabilitado, erro}: ICampoCepProps) => {

  return (
    <CampoDeTextoComMascara
      colunas="3"
      label={DESCRICAO_DO_CAMPO}
      name={NOME_DO_CAMPO}
      value={valor}
      mascara={FORMATO_DE_TEXTO}
      link={LINK_DE_BUSCA}
      descricaoDoLink={DESCRICAO_DO_LINK_DE_BUSCA}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      erro={erro}
      pattern="\d*"
      disabled={desabilitado}
    />
  );
};

export default CampoCep;