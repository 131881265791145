import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import initialState from '../../reducers/initialState.reducer';
import { Link } from 'react-router-dom';
import { push } from 'connected-react-router';
import Container from '../../componentes/Container';
import ContainerCabecalho from '../../componentes/ContainerCabecalho';
import ContainerConteudo from '../../componentes/ContainerConteudo';
import ContainerRodape from '../../componentes/ContainerRodape';
//@ts-ignore
import Dialogo from '@bit/digix.digixui.dialogo';
//@ts-ignore
import { GerenciadorDeMensagem } from '@bit/digix.digixui.mensagens-flutuantes';
import { Header, IndicadorPasso } from '../../componentes';
import CampoDeFamiliaMonoparental from '../../componentes/CampoDeFamiliaMonoparental';
import ItemDependente from './componentes/ItemDependente';
import '../../estilos/estilos.scss';
import { excluirSucesso } from '../../actions/dependente.actions';
import IDependente from '../../interfaces/IDependente';
import BarraDeNavegacao from '../../componentes/BarraDeNavegacao';
import { excluirDependente } from '../../servicos/dependente';

interface IDependenteSelecionadoParaExcluir {
  idDoDependente: number,
  nomeDoDependente: string
}

const Dependentes = () => {
  const inscricao = useSelector((state: typeof initialState) => state.inscricao);
  const idDaInscricao = useSelector((state: typeof initialState) => state.inscricao.id) as number;
  const dependentes = useSelector((state: typeof initialState) => state.dependentes) as IDependente[];
  const ehAcessoAdministrativo: boolean = useSelector((state: typeof initialState) => state.ehAcessoAdministrativo);
  const [dependenteSelecionadoParaExclusao, setDependenteSelecionadoParaExclusao] = useState<IDependenteSelecionadoParaExcluir | undefined>();
  const [deveExibirDialogoDeExclusao, setDeveExibirDialogoDeExclusao] = useState<boolean>(false);
  const ehNovaInscricao = useSelector((state: typeof initialState) => state.ehNovaInscricao) as boolean;
  const dispatch = useDispatch();
  const ehFormularioSomenteLeitura = useSelector((state: typeof initialState) => state.ehFormularioSomenteLeitura) as boolean;

  const inscricaoEstaVazioNaMemoria = Object.keys(inscricao).length == 1;
  useEffect(()  =>  {
    if (inscricaoEstaVazioNaMemoria && !ehNovaInscricao)
      dispatch(push('/menu-inscricao'));

  }, [dispatch, ehFormularioSomenteLeitura, ehNovaInscricao, inscricaoEstaVazioNaMemoria]);

  const exibirDialogoDeExclusao = (idDoDependente: number, nomeDoDependente: string) => {
    setDependenteSelecionadoParaExclusao({ idDoDependente, nomeDoDependente } as IDependenteSelecionadoParaExcluir);
    setDeveExibirDialogoDeExclusao(true);
  };

  const fecharDialogoDeExclusao = () => {
    setDependenteSelecionadoParaExclusao(undefined);
    setDeveExibirDialogoDeExclusao(false);
  };

  const excluir = async () => {
    if (dependenteSelecionadoParaExclusao?.idDoDependente) {
      await excluirDependente(dependenteSelecionadoParaExclusao?.idDoDependente, idDaInscricao);
      dispatch(excluirSucesso(dependenteSelecionadoParaExclusao.idDoDependente));
      GerenciadorDeMensagem.criarMensagem({
        tipo: 'sucesso',
        icone: 'far fa-check-circle',
        titulo: 'Tudo certo!',
        texto: 'Dependente excluído com sucesso.'
      });

      fecharDialogoDeExclusao();
    }
  };

  const avancar = () => {
    dispatch(push('/endereco'));
  };

  return (
    <>
      {(!inscricaoEstaVazioNaMemoria || ehNovaInscricao) && 
      <div className="pagina-fundo">
        {!ehAcessoAdministrativo && (
        //@ts-ignore
          <Header subTitulo='Dados dos dependentes' comBotaoVoltar={!ehNovaInscricao} urlBotaoVoltar={'/menu-inscricao'} />
        )}
        {ehAcessoAdministrativo && (
          <BarraDeNavegacao
            titulo='Dados dos dependentes'
            comBotaoVoltar={true}
            acaoBotaoVoltar={() => dispatch(push('/menu-inscricao'))}
            comBotaoADireita={true}
            textoBotaoADireita="Menu de inscrição"
            iconeBotaoADireita={false}
            acaoBotaoADireita={() => dispatch(push('/menu-inscricao'))}
          />
        )}
        <div className="pagina-container">
          <Container tamanhoDoContainer="pequeno">
            <ContainerCabecalho>
              <IndicadorPasso
                iconeDoPasso='fa-users'
                titulo='Dependentes'
                descricao='Dependentes são todos aqueles que vivem na casa do(a) pretendente. Exemplo: filhos menores de 18 anos.' />
            </ContainerCabecalho>
            <ContainerConteudo>
              <h2 className="titulo titulo_medio u-margem-superior-media">
                <strong>Quantidade de dependentes: <span data-test-id="quantidade-dependentes">{dependentes.length}</span></strong>
              </h2>
              {(Object.keys(dependentes).length > 0) ?
                dependentes.map((dependente: IDependente) =>
                  <ItemDependente
                    key={dependente.id}
                    dependente={dependente}
                    removerDependenteDaListagem={exibirDialogoDeExclusao}
                    disabled={ehFormularioSomenteLeitura } />
                ) :
                <p className="texto_medio u-margem-inferior-media">Não há nenhum Dependente cadastrado no momento.</p>
              }
              {!ehFormularioSomenteLeitura  && 
              <Link to='/novo-dependente' className="botao botao_contorno botao_cor-secundaria botao_largura-total lista-de-cadastro__botao"
                data-test-id="adicionar-dependente">
                <i className="fal fa-user-plus" aria-hidden="true" />Adicionar dependente
              </Link>
              }
              <CampoDeFamiliaMonoparental dependentes={dependentes}  disabled={ehFormularioSomenteLeitura }/>
            </ContainerConteudo>
            <ContainerRodape>
              <button
                disabled={ehFormularioSomenteLeitura }
                className="botao botao_medio botao_cor-secundaria botao_largura-total"
                onClick={avancar}>
                Avançar
              </button>
            </ContainerRodape>
          </Container>
        </div>
        <Dialogo
          tamanho='mini'
          deveSerExibido={deveExibirDialogoDeExclusao}
          fecharDialogo={fecharDialogoDeExclusao}
          acaoPrincipal={excluir}
          titulo='Atenção'
          corpo={() => {
            return (<p>Tem certeza que deseja excluir o dependente: {dependenteSelecionadoParaExclusao?.nomeDoDependente}?</p>);
          }}
          rodape={() => {
            return (
              <>
                <button className="botao botao_cor-secundaria" type="button" onClick={excluir}>Sim, excluir</button>
                <button className="botao botao_cor-secundaria botao_contorno" type="button" onClick={fecharDialogoDeExclusao}>Não</button>
              </>
            );
          }} />
      </div>}
    </>
  );
};
export default Dependentes;