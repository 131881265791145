import React from 'react';
import '../../estilos/estilos.scss';
import MensagemDeErro from '../MensagemDeErro';

interface ICampoCheckboxProps {
  name: string
  label?: string
  checked?: boolean,
  onChange: (event: IEventOnChange) => void,
  erro?: string,
  disabled?: boolean
}

interface IEventOnChange {
  target: { name: string, value: string }
}

const CampoCheckbox = ({ label, name, checked, onChange, erro, disabled }: ICampoCheckboxProps) => {

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target: IEventOnChange = {
      target: {
        name: event.target.name,
        value: (!checked).toString()
      }
    };
    onChange(target);
  };

  return (
    <>
      {erro && <MensagemDeErro mensagem={erro} />}
      <div className="formulario__checkbox-container">        
        <input
          type="checkbox"
          name={name}
          id={name}
          checked={!!checked}
          disabled={disabled}
          onChange={handleOnChange}
          aria-checked={!!checked}
          className={erro ? 'formulario__input_erro' : ''} />
        <label className="formulario__label" htmlFor={name}>{label}</label>
      </div>
    </>
  );
};

export default CampoCheckbox;