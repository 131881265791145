import { CPF } from 'cpf_cnpj';

const CAMPO_OBRIGATORIO = 'Campo obrigatório';

const obrigatorio = valor => {
  if (!valor)
    return CAMPO_OBRIGATORIO;
};

const cpf = cpf => {
  if (!CPF.isValid(cpf))
    return 'CPF inválido';
};

const validacoes = {
  cpf: [obrigatorio, cpf],
  senha: [obrigatorio],
  cidade: [obrigatorio]
};

const aplicarFuncoesDeValidacao = (contexto, campo, valor) => {
  const funcoes = validacoes[campo];
  const retornosComErro = funcoes.map(f => f.call(contexto, valor)).filter(r => !!r);
  return retornosComErro[0];
};

const validarCampo = (dadosLogin, campo, valor) =>
  aplicarFuncoesDeValidacao(dadosLogin, campo, valor);

const validarFormulario = dadosLogin => {
  const erros = {};

  Object.keys(validacoes).map(campo => {
    const erro = aplicarFuncoesDeValidacao(dadosLogin, campo, dadosLogin[campo]);
    if (erro)
      erros[campo] = erro;
  });

  return erros;
};

export default { validarCampo, validarFormulario };