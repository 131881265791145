import React from 'react';
import '../../estilos/estilos.scss';

interface CampoRadioButtonProps {
  name: string,
  value: string,
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  checked: string,
  label: string,
  descricao?: string,
  disabled?: boolean
  mesmaLinha?: boolean
}

const CampoRadioButton = ({ name, value, onChange, checked, label, descricao, disabled, mesmaLinha }: CampoRadioButtonProps) => (
  <div className={`formulario__radio-container ${mesmaLinha ? 'formulario__radio-container_em-linha' : ''} ${disabled ? 'formulario__radio-container_desabilitado' : ''}`}>
    <input
      type="radio"
      id={`${name}.${value}`}
      name={name}
      value={value}
      onChange={onChange}
      checked={checked == value}
      aria-checked={checked == value}
      disabled={disabled} />
    <label className="formulario__label" htmlFor={`${name}.${value}`}>
      {label}
      {descricao &&
        <div className="formulario__descricao">{descricao}</div>
      }
    </label>
  </div>
);

export default CampoRadioButton;