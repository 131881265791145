import { SALVAR_MORADIA_SUCESSO, CARREGAR_MORADIA } from './actionTypes';
import IMoradia from '../interfaces/IMoradia';

export const salvarMoradiaSucesso = (moradia: IMoradia) =>
  ({
    type: SALVAR_MORADIA_SUCESSO,
    state: moradia
  });

export const carregarMoradia = (moradia: IMoradia) =>
  ({
    type: CARREGAR_MORADIA,
    state: moradia
  });
