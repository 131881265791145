import React, { Component } from 'react';
import aria from './listbox-combobox.js';
import IconeDeAcaoDoSelect from '../../imagens/icone-select.png';
import MensagemDeErro from '../MensagemDeErro';

export default class CampoDeSelecao extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.tratarEventoDeClickNoCombobox = this.tratarEventoDeClickNoCombobox.bind(this);
    this.inserirLabelDeAbrirSelect = this.inserirLabelDeAbrirSelect.bind(this);
    this.inserirLabelDeFecharSelect = this.inserirLabelDeFecharSelect.bind(this);
    this.adicionarMetodoParaTratarEventoDeClickNoCombobox =
      this.adicionarMetodoParaTratarEventoDeClickNoCombobox.bind(this);
  }

  componentDidMount() {
    this.combobox = this.criarComboBox();
    this.adicionarMetodoParaTratarEventoDeClickNoCombobox();
    this.buscarItemSelecionadoPorId();
  }

  componentDidUpdate(){
    this.buscarItemSelecionadoPorId();
  }

  componentWillUnmount() {
    this.combobox.removeEvents();
    document.getElementById('combobox-arrow')
      .removeEventListener('click', this.tratarEventoDeClickNoCombobox);
  }

  buscarItemSelecionadoPorId() {
    let { idSelecionado } = this.state;
    if (idSelecionado != this.props.idSelecionado) {
      let item = this.props.funcaoDeBuscaPorId(this.props.idSelecionado);
      let label = item ? item.label : '';
      this.combobox.inserirTextoNoInput(label);
      this.setState({
        idSelecionado: this.props.idSelecionado
      });
    }
  }

  criarComboBox() {
    var { funcaoDeBuscaPorTexto, onChange } = this.props;
    const deveAutoSelecionarItem = true;

    return new aria.ListboxCombobox(
      document.getElementById('combobox'),
      document.getElementById('input-select'),
      document.getElementById('listbox'),
      funcaoDeBuscaPorTexto,
      onChange,
      deveAutoSelecionarItem,
      this.inserirLabelDeFecharSelect,
      this.inserirLabelDeAbrirSelect
    );
  }

  inserirLabelDeAbrirSelect() {
    document.getElementById('combobox-arrow')
      .setAttribute('aria-label', this.props.labelParaAbrirSelect);
  }

  inserirLabelDeFecharSelect() {
    document.getElementById('combobox-arrow')
      .setAttribute('aria-label', this.props.labelParaFecharSelect);
  }

  adicionarMetodoParaTratarEventoDeClickNoCombobox() {
    document.getElementById('combobox-arrow')
      .addEventListener('click', this.tratarEventoDeClickNoCombobox);
  }

  tratarEventoDeClickNoCombobox() {
    if (this.combobox.shown) {
      document.getElementById('input-select').focus();
      this.combobox.hideListbox();
    }
    else {
      document.getElementById('input-select').focus();
      this.combobox.updateResults(true);
    }
  }

  render() {
    return (
      <div>
        <label id="label" className="formulario__label">
          {this.props.label}
        </label>
        {this.props.erro &&
          <MensagemDeErro />
        }
        <div className="combobox-wrapper">
          <div role="combobox" aria-controls='' aria-expanded="false" aria-owns="listbox" aria-haspopup="listbox" id="combobox">
            <input name={this.props.name} type="text" placeholder={this.props.placeholder} aria-controls="listbox" autoComplete="off" onBlur={this.props.onBlur}
              aria-labelledby="label" className={`formulario__input ${this.props.erro && 'formulario__input_erro'}`} id="input-select" disabled={this.props.disabled} />
            <div className={`combobox-dropdown ${this.props.disabled && 'disabled'} `} id="combobox-arrow" tabIndex="-1" role="button" aria-label={this.props.labelParaAbrirSelect}>
              <img src={IconeDeAcaoDoSelect} alt="" />
            </div>
          </div>
          <ul aria-labelledby="label" role="listbox" id="listbox" className="listbox hidden" />
        </div>
      </div>
    );
  }
}