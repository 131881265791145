import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IProgramasSociais from '../interfaces/IProgramasSociais';
import IActionType from '../interfaces/IActionType';

export default function informacoesDaFamilia(
  state = initialState.programasSociais,
  action: IActionType<IProgramasSociais>
) {
  switch (action.type) {
  case actionTypes.SALVAR_PROGRAMAS_SOCIAIS_SUCESSO:
    return {
      ...action.state,
    };
  case actionTypes.CARREGAR_PROGRAMAS_SOCIAIS:
    return {
      ...action.state,
    };
  default:
    return state;
  }
}