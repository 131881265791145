import { CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO}  from '../actions/actionTypes';
import initialState from './initialState.reducer';
import IActionType from '../interfaces/IActionType';
import IGrauDeInstrucao from '../interfaces/IGrauDeInstrucao';

export default (state = initialState.grauDeInstrucoes, action: IActionType<IGrauDeInstrucao[]>) => {
  switch (action.type) {
  case CARREGAR_GRAU_DE_INSTRUCAO_SUCESSO:
    return [...action.state];
  default:
    return state;
  }
};