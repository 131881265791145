import moment from 'moment';

const dataAtual = () => moment();
const dataMinima = () => converterData(new Date(1900, 0, 1));

export const dataValida = (dataParaValidar?: Date) => {
  let dataValidada = converterData(dataParaValidar);

  if (!dataValidada.isValid() || dataValidada < dataMinima())
    return 'Data inválida';

  if (dataValidada > dataAtual())
    return 'Não é permitido informar data futura';
};

export const maiorDeIdade = (valor?: Date) => {
  let dataInvalida = dataValida(valor);
  if (dataInvalida) return dataInvalida;

  let dataDeNascimento = converterData(valor);
  let dezoitoAnosAtras = dataAtual().subtract(18, 'years');
  if (dataDeNascimento > dezoitoAnosAtras)
    return 'É necessário ter no mínimo 18 anos';
};

export const idadeParaEmancipar = (valor?: Date) => {  
  let dataInvalida = dataValida(valor);
  if (dataInvalida) return dataInvalida;

  let dataDeNascimento = converterData(valor);
  let dezeseisAnosAtras = dataAtual().subtract(16, 'years');
  if (dataDeNascimento > dezeseisAnosAtras)
    return 'É necessário ter no mínimo 18 anos ou 16 para emancipar';
};

export const ehIdadeQuePermiteEmancipacao = (valor?: Date) : boolean => {
  let dataInvalida = dataValida(valor);
  if (dataInvalida) return false;

  let dataDeNascimento = converterData(valor);
  let dezeseisAnosAtras = dataAtual().subtract(16, 'years');
  let dezoitoAnosAtras = dataAtual().subtract(18, 'years');
  
  return dataDeNascimento > dezoitoAnosAtras && dataDeNascimento < dezeseisAnosAtras;
};

export const ehMenorDeIdade = (data?: Date) => {
  let dataAtual = new Date();
  let anoAtual = dataAtual.getFullYear();
  let mesAtual = dataAtual.getMonth() + 1;
  let diaAtual = dataAtual.getDate();
  if (data == null)
    return false;
  let dataDeNascimentoEmPartes = data.toString().split('/');
  let dataDeNascimentoDoDependente = new Date(
    //@ts-ignore
    dataDeNascimentoEmPartes[2],
    //@ts-ignore
    dataDeNascimentoEmPartes[1] - 1,
    //@ts-ignore
    dataDeNascimentoEmPartes[0]
  );
  let anoAniversario = dataDeNascimentoDoDependente.getFullYear();
  let mesAniversario = dataDeNascimentoDoDependente.getMonth() + 1;
  let diaAniversario = dataDeNascimentoDoDependente.getDate();
  let idade = anoAtual - anoAniversario;
  if (
    mesAtual < mesAniversario ||
    (mesAtual == mesAniversario && diaAtual < diaAniversario)
  ) {
    idade--;
  }
  return idade < 18;
};

export const converterData = (dataParaConverter?: Date) => moment(dataParaConverter, 'DD/MM/YYYY', true);