import IActionType from '../interfaces/IActionType';
import initialState from './initialState.reducer';
import * as actionTypes from '../actions/actionTypes';

export default (state = initialState.deveCarregarInscricaoNaMemoria, action: IActionType<boolean>) => {
  switch (action.type) {
  case actionTypes.DEVE_CARREGAR_INSCRICAO_NA_MEMORIA:
    return action.state;
  default:
    return state;
  }
};