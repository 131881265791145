import React from 'react';
import logoGoverno from './logo-governo-seilog.svg';
import '../../estilos/estilos.scss';
import PropTypes from 'prop-types';

const Logos = ({ comFonteBranca = false }) =>
  (<div className={`logo-governo ${comFonteBranca ? 'logo-governo_branco' : ''} u-margem-inferior-grande`}>
    <img src={logoGoverno} alt='logo governo' ></img>
  </div>);

Logos.propTypes = {
  comFonteBranca:PropTypes.bool
};

export default Logos;